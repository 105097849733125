import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Tpn } from '../models/tpn.model';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001/api/';

console.log(BASE_URL)

export const TpnApi = createApi({
    reducerPath: "tpnApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Tpn'],
    endpoints: (builder) => ({
        Tpns: builder.query<Tpn[], void>({
            query: () => '/tpn',
            providesTags: ['Tpn']
        }),
        Tpn: builder.query<Tpn, string>({
            query: (_id) => `/tpn/${_id}`,
            providesTags: ['Tpn']
        }),
        TpnByDate: builder.query<Tpn, any>({
            query: ({ startDate, endDate }) => `/tpn/byDate/${startDate}/${endDate}`,
            providesTags: ['Tpn'],
        }),
        addTpn: builder.mutation<{}, Tpn>({
            query: Tpn => ({
                url: '/tpn',
                method: 'POST',
                body: Tpn
            }),
            invalidatesTags: ['Tpn']
        }),
        updateTpn: builder.mutation<void, Tpn>({
            query: ({ _id, ...rest }) => ({
                url: `/tpn/${_id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['Tpn']
        }),
        deleteTpn: builder.mutation<void, string>({
            query: (id) => ({
                url: `/tpn/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Tpn']
        })
    })
})

export const {
    useTpnsQuery,
    useTpnQuery,
    useTpnByDateQuery,
    useAddTpnMutation,
    useUpdateTpnMutation,
    useDeleteTpnMutation
} = TpnApi;

export default TpnApi;