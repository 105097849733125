import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Sale } from "../models/sale.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

console.log(BASE_URL);

export const SaleApi = createApi({
  reducerPath: "SaleApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Sale"],
  endpoints: (builder) => ({
    Sales: builder.query<Sale[], void>({
      query: () => `/sale/`,
      providesTags: ["Sale"],
    }),
    SalesPointSpent: builder.query<Sale[], void>({
      query: () => `/sale/todayPoint`,
      providesTags: ["Sale"],
    }),
    SalesWeekly: builder.query<Sale[], void>({
      query: () => `/sale/week-sale`,
      providesTags: ["Sale"],
    }),
    Sale: builder.query<Sale, string>({
      query: (_id) => `/sale/${_id}`,
      providesTags: ["Sale"],
    }),
    SaleByInvoice: builder.query<Sale, string>({
      query: (invoiceId) => `/sale/invoice/${invoiceId}`,
      providesTags: ["Sale"],
    }),
    SaleByDate: builder.query<Sale[], any>({
      query: ({ startDate, endDate }) => `/sale/byDate/${startDate}/${endDate}`,
      providesTags: ["Sale"],
    }),
    SaleByDateInvoice: builder.query<Sale[], any>({
      query: ({ startDate, endDate, q }) =>
        `/sale/byDateInvoice/${startDate}/${endDate}?q=${q}`,
      providesTags: ["Sale"],
    }),
    SaleByDateAfterSale: builder.query<Sale[], any>({
      query: ({ startDate, endDate, supplier }) =>
        `/sale/aftersale/${startDate}/${endDate}/${supplier}`,
      providesTags: ["Sale"],
    }),
    SaleTotal: builder.query<Sale[], any>({
      query: ({ startDate, endDate }) => `/sale/total/${startDate}/${endDate}`,
      providesTags: ["Sale"],
    }),
    SaleFootfall: builder.query<Sale[], any>({
      query: ({ startDate, endDate }) =>
        `/sale/footfall/${startDate}/${endDate}`,
      providesTags: ["Sale"],
    }),
    SaleExportByDate: builder.query<Sale[], any>({
      query: ({ startDate, endDate }) => `/sale/export/${startDate}/${endDate}`,
      providesTags: ["Sale"],
    }),
    SaleExportByDateAndCat: builder.query<Sale[], any>({
      query: ({ startDate, endDate, cat }) =>
        `/sale/byCategory/${startDate}/${endDate}/${cat}`,
      providesTags: ["Sale"],
    }),
    SaleExportByDatePopular: builder.query<Sale[], any>({
      query: ({ startDate, endDate }) =>
        `/sale/popular-product/${startDate}/${endDate}`,
      providesTags: ["Sale"],
    }),
    SaleExportByDateAndSupplier: builder.query<Sale[], any>({
      query: ({ startDate, endDate, supplier }) =>
        `/sale/bySupplier/${startDate}/${endDate}/${supplier}`,
      providesTags: ["Sale"],
    }),
    DelSaleExportByDate: builder.query<Sale[], any>({
      query: ({ startDate, endDate }) =>
        `/sale/exportDel/${startDate}/${endDate}`,
      providesTags: ["Sale"],
    }),

    SaleArticelExportByDate: builder.query<Sale[], any>({
      query: ({ startDate, endDate, q }) =>
        `/sale/exportArticale/${startDate}/${endDate}?q=${q}`,
      providesTags: ["Sale"],
    }),
    SaleCategoryByDate: builder.query<Sale[], any>({
      query: ({ startDate, endDate }) =>
        `/sale/category/${startDate}/${endDate}`,
      providesTags: ["Sale"],
    }),

    addSale: builder.mutation<{}, Sale>({
      query: (Sale) => ({
        url: "/sale",
        method: "POST",
        body: Sale,
      }),
      invalidatesTags: ["Sale"],
    }),
    updateSale: builder.mutation<void, Sale>({
      query: ({ _id, ...rest }) => ({
        url: `/sale/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Sale"],
    }),
    // deleteSale: builder.mutation<void, string>({
    //     query: (id) => ({
    //         url: `/sale/${ id }`,
    //         method: 'DELETE',
    //     }),
    //     invalidatesTags: ['Sale']
    // }),
    deleteTempSale: builder.mutation<void, Sale>({
      query: ({ _id, ...rest }) => ({
        url: `/sale/${_id}`, //  not found
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Sale"],
    }),
  }),
});

export const {
  useSalesQuery,
  useSalesPointSpentQuery,
  useSalesWeeklyQuery,
  useSaleQuery,
  useSaleByDateQuery,
  useSaleByDateInvoiceQuery,
  useSaleByDateAfterSaleQuery,
  useSaleTotalQuery,
  useSaleFootfallQuery,
  useSaleByInvoiceQuery,
  useSaleExportByDateQuery,
  useSaleExportByDateAndCatQuery,
  useSaleExportByDatePopularQuery,
  useSaleExportByDateAndSupplierQuery,
  useDelSaleExportByDateQuery,
  useSaleArticelExportByDateQuery,
  useSaleCategoryByDateQuery,
  useAddSaleMutation,
  useDeleteTempSaleMutation,
  useUpdateSaleMutation,
  // useDeleteSaleMutation
} = SaleApi;

export default SaleApi;
