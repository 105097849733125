import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useWarehousesQuery } from "../../../services/warehouseApi";

const WareHouseDWPurchase = ({ wh, handleOnChange, name }) => {
  const { data, error, isLoading, isFetching, isSuccess } =
    useWarehousesQuery();
  let [warehouseOption, setWarehouseOption] = useState([]);
  const [defaultOption, setDefaultOption] = useState("");

  // console.log("warehouse", data)
  useEffect(() => {
    let warehouseOptions = [];
    if (isSuccess) {
      if (data.length > 0) {
        data.map((warehouse) => {
          warehouseOptions = [
            ...warehouseOptions,
            {
              option: warehouse._id,
              label: `${warehouse.name} - [ ${warehouse.address} ]`,
            },
          ];
        });
        setWarehouseOption(warehouseOptions);
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (warehouseOption.length > 0) {
      setDefaultOption(warehouseOption[0]);
    }
  }, [warehouseOption]);

  // console.log(defaultOption)
  // const defaultOption = warehouseOption.length > 0 ? warehouseOption[0].option : null;
  // console.log(warehouseOption[0]?.option);

  return (
    <Fragment>
      {/* <Select
        className="basic-single"
        classNamePrefix="select"
        // defaultValue={data[0].warehouse}
        // value={data[0].warehouse}
        defaultValue={{ option: '645c9297ed6d5d94af257be9', label: 'tcm - [ Uttara, Dhaka-1230 ]' }}
        // defaultValue={defaultOption}
        // defaultValue={warehouseOption[0]?.option}
        value={warehouseOption[warehouseOption.indexOf(wh)]?.option}
        // value={warehouseOption[warehouseOption.indexOf(wh)].option}
        isDisabled={false}
        isLoading={true}
        isClearable={false}
        isSearchable={true}
        name={name}
        onChange={handleOnChange}
        options={warehouseOption}
      /> */}
      {warehouseOption.length > 0 ? (
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={warehouseOption[0]}
          value={warehouseOption[warehouseOption.indexOf(wh)]?.option}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isSearchable={true}
          name={name}
          onChange={handleOnChange}
          options={warehouseOption}
        />
      ) : (
        <p>Loading...</p>
      )}
    </Fragment>
  );
};

export default WareHouseDWPurchase;
