import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Generic } from "../models/generic.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

console.log(BASE_URL);

export const GenericApi = createApi({
  reducerPath: "GenericApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Generic"],
  endpoints: (builder) => ({
    Generics: builder.query<Generic[], void>({
      query: () => "/generic",
      providesTags: ["Generic"],
    }),
    GenericsExport: builder.query<Generic[], void>({
      query: () => "/generic/export",
      providesTags: ["Generic"],
    }),
    GenericsNew: builder.query<Generic[], void>({
      query: () => "/generic/new",
      providesTags: ["Generic"],
    }),
    genericCount: builder.query<Generic[], void>({
      query: () => "/generic/count",
      providesTags: ["Generic"],
    }),
    genericPagination: builder.query<Generic, any>({
      query: ({ page, size, q }) => `/generic/all/${page}/${size}?q=${q}`,
      // query: ({page, size, q}) => `/Customer`,
      providesTags: ["Generic"],
    }),
    GenericsSearch: builder.query<Generic[], string>({
      query: (q) => `/generic/search/${q}`,
      providesTags: ["Generic"],
    }),
    Generic: builder.query<Generic, string>({
      query: (_id) => `/generic/${_id}`,
      providesTags: ["Generic"],
    }),
    addGeneric: builder.mutation<{}, Generic>({
      query: (Generic) => ({
        url: "/generic",
        method: "POST",
        body: Generic,
      }),
      invalidatesTags: ["Generic"],
    }),
    updateGeneric: builder.mutation<void, Generic>({
      query: ({ _id, ...rest }) => ({
        url: `/generic/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Generic"],
    }),
    deleteGeneric: builder.mutation<void, string>({
      query: (id) => ({
        url: `/generic/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Generic"],
    }),
  }),
});

export const {
  useGenericsQuery,
  useGenericsExportQuery,
  useGenericsNewQuery,
  useGenericCountQuery,
  useGenericPaginationQuery,
  useGenericsSearchQuery,
  useGenericQuery,
  useAddGenericMutation,
  useUpdateGenericMutation,
  useDeleteGenericMutation,
} = GenericApi;

export default GenericApi;
