import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Icons from "heroicons-react";
import Header from "../Common/Header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import SideBar from "../Common/SideBar/SideBar";
import { useForm } from "react-hook-form";
import { notify } from "../Utility/Notify";
import {
  useCustomersQuery,
  useCustomerQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} from "../../services/customerApi";
import SelectMC from "../Common/CustomSelect/selectMC";
import SelectCategoryGroup from "../Common/CustomSelect/selectCategoryGroup";
import LoadingModal from "../Common/Modal/LoadingModal";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

const UpdateCustomer = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const customerUniqeId = uuidv4();
  const { data, error, isLoading, isFetching, isSuccess } = useCustomerQuery(
    `${id}`
  );

  const { register, handleSubmit, reset } = useForm({});
  const [loader, setLoader] = useState(true)
  const handleLoaderClose = () => setLoader(false);
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

  useEffect(() => {
    if (data) {
      reset({
        _id: data._id,
        name: data.name,
        username: data.username,
        // password: data.password,
        membership: data.membership,
        // address: data.address,
        holdingNo: data.address[0].holdingNo,
        sector: data.address[0].sector,
        road: data.address[0].street,
        town: data.address[0].town,
        city: data.address[0].city,
        division: data.address[0].division,
        country: data.address[0].country,
        zipCode: data.address[0].zipCode,
        phone: data.phone,
        email: data.email,
        type: data.type,
        status: data.status,
        // code: data.code,
      });
    }
  }, [data]);

  // console.log(data);

  // handel user update
  const [UpdateCustomer] = useUpdateCustomerMutation();

  const updateHandler = async (customer) => {
    console.log("customer", customer)
    let newCustomer = {}
    let email = customer?.email;
    let trimEmail = email.trim()
    if (trimEmail?.length > 0) {

      newCustomer = {
        name: customer?.name,
        password: customer?.password,
        email: customer?.email,
        phone: customer?.phone,
        username: customer?.username,
        membership: customer?.membership,
        address: {
          type: 'Home',
          id: customerUniqeId,
          holdingNo: customer?.holdingNo,
          sector: customer?.sector,
          street: customer?.road,
          town: customer?.town,
          city: customer?.city,
          division: customer?.division,
          country: customer?.country,
          zipCode: customer?.zipCode
        },
        point: customer?.point ? customer?.point : 0,
        type: customer?.type,
        status: customer?.status,

      }
      console.log("newCustomer", newCustomer)
      // setLoader(true)
    }
    else {
      newCustomer = {
        name: customer?.name,
        password: customer?.password,
        phone: customer?.phone,
        username: customer?.username,
        membership: customer?.membership,
        address: {
          type: 'Home',
          id: customerUniqeId,
          holdingNo: customer?.holdingNo,
          sector: customer?.sector,
          street: customer?.road,
          town: customer?.town,
          city: customer?.city,
          division: customer?.division,
          country: customer?.country,
          zipCode: customer?.zipCode
        },
        point: customer?.point ? customer?.point : 0,
        type: customer?.type,
        status: customer?.status,

      }
      console.log("newCustomer", newCustomer)
      // setLoader(true)
    }

    // const response = await UpdateCustomer({ _id: id, newCustomer });
    // console.log(response)
    // if (response) {
    //   console.log(response);
    //   notify("Customer Update Successful!", "success");
    //   setLoader(false)
    //   navigate("/customer");
    // }
    await UpdateCustomer({ _id: id, newCustomer })
      .then(res => {
        console.log(res)
        notify("Customer Update Successful!", "success");
        setLoader(false)
        navigate("/customer");
      })
  };
  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data]);

  console.log(data);
  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title={`Update User: ${data?.name}`}></Header>
            <div className="row  mt-5 pt-3 pb-5">
              <div className="col-md-6 offset-md-3">
                <form onSubmit={handleSubmit(updateHandler)}>
                  <div className="row mb-3">
                    <div className="form-group col-12  mb-3">
                      <label htmlFor="inputCustomer"> Name</label>
                      <input
                        {...register("name", { required: true })}
                        type="text"
                        className="form-control"
                        id="inputCustomer"
                        aria-describedby="emailHelp"
                        placeholder="Name"
                      />
                      <small
                        id="emailHelp"
                        className="form-text text-muted"
                      >
                        We'll never share your email with anyone else.
                      </small>
                    </div>
                    <div className="form-group col-12  mb-3">
                      <label htmlFor="inputMC">Username</label>
                      <input
                        {...register("username")}
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="username"
                      />
                    </div>
                    <div className="form-group col-12  mb-3">
                      <label htmlFor="inputMC">Phone</label>
                      <input
                        {...register("phone")}
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Phone"
                      />
                    </div>
                    <div className="form-group col-12  mb-3">
                      <label htmlFor="inputMC">Email</label>
                      <input
                        {...register("email")}
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder=" email"
                      />
                    </div>
                    <div className="form-group col-12  mb-3">
                      <label htmlFor="inputMC">Member Ship</label>

                      <select
                        {...register("membership")}
                        className="form-select"
                        id="status"
                      >
                        <option value="gold" selected>
                          Gold
                        </option>
                        <option value="diamond">Diamond</option>
                        <option value="premium">Premium</option>
                      </select>
                    </div>

                    {/* <div className="form-group col-12  mb-3">
                            <label htmlFor="MCId">Address</label>
                            <textarea
                              {...register("address")}
                              className="form-control"
                              id="address"
                              placeholder="Address"
                            />
                          </div> */}
                    <div className="form-group col-6  mb-3">
                      <label htmlFor="MCId">Holding Number</label>
                      <input
                        {...register("holdingNo")}
                        type="text"
                        className="form-control"
                        id="holdingNo"
                        placeholder="Holding Number"
                      />
                    </div>
                    <div className="form-group col-6  mb-3">
                      <label htmlFor="MCId">Road</label>
                      <input
                        {...register("road")}
                        type="text"
                        className="form-control"
                        id="road"
                        placeholder="Road"
                      />
                    </div>
                    <div className="form-group col-6  mb-3">
                      <label htmlFor="MCId">Sector</label>
                      <select {...register("sector")}
                        className="form-control"
                      >
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option selected value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                      </select>
                    </div>
                    <div className="form-group col-6  mb-3">
                      <label htmlFor="MCId">Town</label>
                      <select {...register("town")}
                        className="form-control"
                      >
                        <option selected value="Uttara">Uttara</option>
                      </select>
                    </div>
                    <div className="form-group col-6  mb-3">
                      <label htmlFor="MCId">City</label>
                      <select {...register("city")}
                        className="form-control"
                      >
                        <option selected value="Dhaka">Dhaka</option>
                      </select>
                    </div>
                    <div className="form-group col-6  mb-3">
                      <label htmlFor="MCId">Division</label>
                      <select {...register("division")}
                        className="form-control"
                      >
                        <option selected value="Dhaka">Dhaka</option>
                      </select>
                    </div>
                    <div className="form-group col-6  mb-3">
                      <label htmlFor="MCId">Zip Code</label>
                      <select {...register("zipCode")}
                        className="form-control"
                      >
                        <option selected value="1230">1230</option>
                      </select>
                    </div>
                    <div className="form-group col-6  mb-3">
                      <label htmlFor="MCId">Country</label>
                      <select {...register("country")}
                        className="form-control"
                      >
                        <option selected value="BD">Bangladesh</option>
                      </select>
                    </div>
                    <div className="form-group col-12  mb-3">
                      <label htmlFor="inputMC">Type</label>
                      <select
                        {...register("type")}
                        className="form-select"
                        id="status"
                      >
                        <option value="regular" selected>
                          Regular
                        </option>
                        <option value="premium">Premium</option>
                        <option value="vip">VIP</option>
                      </select>
                    </div>
                    {/* <input
                              {...register("type")}
                              type="text"
                              className="form-control"
                              id="password"
                              placeholder="type"
                            />
                          </div> */}

                    <div className="form-group col-12  mb-3">
                      <label htmlFor="inputMC">status</label>
                      <select
                        {...register("status")}
                        className="form-control"
                        id="status"
                        placeholder="status"
                      >
                        <option value="active">active</option>
                        <option value="inactive">inactive</option>
                      </select>
                    </div>
                  </div>
                  <Link
                    to="/customer"
                    className="btn btn-outline-dark me-2 float-center"
                  >
                    <Icons.X size={20}></Icons.X> Cancel
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-dark col-8 col-md-8"
                  >
                    {data?._id ? (
                      <>
                        <Icons.SaveOutline></Icons.SaveOutline>
                      </>
                    ) : (
                      <>
                        <Icons.Plus> </Icons.Plus>
                      </>
                    )}
                    Customer
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCustomer;
