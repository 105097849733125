import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Common/Header/Header';
import SideBar from '../Common/SideBar/SideBar';
import DatePicker from "react-datepicker";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import { CSVLink, CSVDownload } from "react-csv";
import { Button, Table } from 'react-bootstrap';
import { useSaleCategoryByDateQuery, useSaleFootfallQuery, useSaleTotalQuery } from '../../services/saleApi';
import AllCategoriesModal from '../Common/Modal/AllCategoriesModal';
import LoadingModal from '../Common/Modal/LoadingModal';

const AllCategorySale = () => {
    const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
    const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
    const [articleSale, setArticleSale] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [loader, setLoader] = useState(true)
    const handleLoaderClose = () => setLoader(false);

    const { data, error, isLoading, isFetching, isSuccess, refetch } =
        useSaleCategoryByDateQuery({
            startDate,
            endDate,
        });
    const { data: footfall, error: ferror, isLoading: fisloading, isFetching: fIsfetching, isSuccess: fIsSuccess, refetch: fRefetch } =
        useSaleFootfallQuery({
            startDate,
            endDate,
        });
    const { data: total, error: terror, isLoading: tIsloading, isFetching: tIsfetching, isSuccess: tIsSuccess, refetch: tRefetch } =
        useSaleTotalQuery({
            startDate,
            endDate,
        });

    useEffect(() => {
        refetch();
        fRefetch();
        tRefetch();
    }, [startDate, endDate]);

    useEffect(() => {
        if (isLoading) {
            setLoader(true)
        } else {
            setLoader(false)
        }
    }, [isLoading]);
    useEffect(() => {
        let articleSales = [];
        // console.log("article", data);

        // (product = [...product, sale.products])
        // console.log(sale?.products);
        let product = [];
        data?.map((sale) =>

            // console.log(product);
            articleSales = [
                ...articleSales,
                {
                    code: sale?._id?.code,
                    name: sale?._id?.name,
                    totalQuantity: sale?.totalQuantity,
                    totalValue: sale?.totalValue,

                },
            ]

        );
        console.log("product", articleSales);
        setArticleSale(articleSales);
    }, [isSuccess, isFetching]);

    const articleHeaders = [
        { label: "Code", key: "code" },
        { label: "Name", key: "name" },
        { label: "totalQuantity", key: "totalQuantity" },
        { label: "totalValue", key: "totalValue" },

    ];

    // let exportData = [];
    useEffect(() => {
        if (articleSale.length > 0) {
            let expD = articleSale;
            setExportData(expD);
        }
    }, [articleSale]);
    const handleAllCategoryPrint = () => {
        setShow(true)
    }
    return (
        <div>
            <LoadingModal
                title={"Please Wait"}
                onShow={loader}
                handleClose={handleLoaderClose}
            ></LoadingModal>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PHARMACY-POS-All-Categories</title>
            </Helmet>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2">
                        <SideBar></SideBar>
                    </div>
                    <div className="col-md-10">
                        <Header title="ALl Categories Sales"></Header>
                        <div className="row">
                            <div className="col-md-12">
                                {/* Sort date range */}
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                                            <DatePicker
                                                selected={new Date(startDate)}
                                                className="form-control me-2"
                                                onChange={(date) =>
                                                    setStartDate(format(new Date(date), "MM-dd-yyyy"))
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                                            <DatePicker
                                                selected={new Date(endDate)}
                                                className="form-control"
                                                onChange={(date) =>
                                                    setEndDate(format(new Date(date), "MM-dd-yyyy"))
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <Button
                                            className="btn btn-dark float-end my-2 mr-2"
                                            onClick={() => handleAllCategoryPrint()}
                                        >
                                            ALL Category Report
                                        </Button>
                                    </div>


                                    <div className="col-md-3">
                                        <CSVLink
                                            filename={`All Category Sales Report- ${startDate} to ${endDate}.csv`}
                                            className="btn btn-dark float-end my-2 mr-2"
                                            data={exportData}
                                            asyncOnClick={true}
                                            headers={articleHeaders}
                                        >
                                            {exportData === []
                                                ? "Loading csv..."
                                                : "Export All Category Sales Report"}
                                        </CSVLink>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* <Link to="/category-sales" className="btn btn-dark float-end my-2  mx-2">Category Sales </Link> */}
                        <Table hover className="mt-4">
                            <thead>
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Total Qty</th>
                                    <th scope="col">Total </th>
                                </tr>
                            </thead>
                            <tbody>
                                {console.log(exportData)}
                                {exportData ? (
                                    exportData.map((cat) => (
                                        <tr key={cat.code}>
                                            {/* <th >{i++}</th> */}
                                            <th scope="row">{cat.code}</th>
                                            <td>{cat.name}</td>
                                            <td>{cat?.totalQuantity}</td>
                                            <td>{cat?.totalValue}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4}>Loading...</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        { }
                    </div>
                </div>
            </div>
            <AllCategoriesModal show={show} handleClose={handleClose} cat={data} startDate={startDate} endDate={endDate} footfall={footfall} total={total}></AllCategoriesModal>
        </div >
    );
};

export default AllCategorySale;