import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
// import { BsArchive, BsCheckSquare, BsSquare } from "react-icons/bs";

import { Button, Form, Modal } from "react-bootstrap";
import logo from "../../../logo.png";
import logo1 from "../../../FINAL_LOGO.jpg";
import PosCart from "./Parts/PosCart";
import PosFinalizes from "./Parts/PosFinalizes";
import PosFooter from "./Parts/PosFooter";
import PosHeader from "./Parts/PosHeader";
import "./POS.css";
import sendMessage from "../../Utility/smsSystem";
import {
  addToDb,
  getStoredCart,
  removeFromDb,
  customQuantity,
  deleteShoppingCart,
  removeQuantity,
  addQuantity,
  customNoOfBox,
  customItemDiscount,
  customSoldInBox,
  deleteLastProduct,
} from "../../Utility/cartDB";

import { posFinalizer } from "../../Utility/PosCalculations";
import { Toaster } from "react-hot-toast";
// import useSales from "../../Hooks/useSales";
import { Navigate, useNavigate } from "react-router-dom";
import { signInUser } from "../../Utility/Auth";
import { notify } from "../../Utility/Notify";
import { useForm } from "react-hook-form";
import * as Icons from "heroicons-react";
// import useInventory from "../../Hooks/useInventory";
import { v4 as uuidv4 } from "uuid";
import SelectCustomer from "../../Common/CustomSelect/selectCustomer";
import PosSearchProduct from "../../Common/CustomSelect/PosSearchProduct";
import {
  selcetBiller,
  selcetCustomer,
  selcetProductsCart,
  saleFinalize,
  saleNewPoint,
  saleReset,
  selectInvoiceId,
  salesPromoPrice,
  // resetReturn,
} from "../../../features/posSlice";

import { itemPrice } from "../../Utility/PosCalculations";

// import { useValidUserMutation } from "../../../services/userApi";
// import { dispatch } from "react-hot-toast/dist/core/store";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddCustomerMutation,
  // useUpdateCustomerMutation,
  useUpdatePointCustomerMutation,
} from "../../../services/customerApi";
import { useAddSaleMutation } from "../../../services/saleApi";
import useKeypress from "react-use-keypress";
// import Barcode from "react-barcode";
import { format } from "date-fns";
import {
  // itemVat,
  itemVatTotal,
  // toDecimal,
} from "../../Utility/PosCalculations";
// import {
//   getNewPoint,
//   totalPoint,
//   remaningPoint,
// } from "../../Utility/customerPointCalculations";
// import axios from "axios";
import ReturnModal from "../../Common/Modal/ReturnModal";
import { type } from "@testing-library/user-event/dist/type";
import VoidReturnModal from "../../Common/Modal/VoidReturnModal";
import { saleVoidReset } from "../../../features/voidSlice";
import GenericSearchModal from "../../Common/Modal/GenericSearchModal";
import { BsCheckSquare, BsSquare } from "react-icons/bs";

const POS = () => {
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  let i = 1;

  /**
   * ===============================
   * NEW CODE FOR POS SCREEN
   * ===============================
   */

  /**
   * Constants
   * get pos data from redux stroge
   *
   */

  const posSaleData = useSelector((state) => state.posReducer);
  const [discountChecked, setDiscountChecked] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [isbill, setBill] = useState(false);
  const [customerPoint, setCustomerPoint] = useState(0);
  const [holdSale, setHoldSale] = useState(false);

  const [show, setShow] = useState(false);
  const [returnShow, setReturnShow] = useState(false);
  const [voidReturnShow, setVoidReturnShow] = useState(false);
  const [showPermission, setShowPermission] = useState({
    show: false,
    type: "",
    data: "",
  });
  const [manager, setManager] = useState();
  const [pass, setPass] = useState();
  const customerUniqeId = uuidv4();
  // console.log(posSaleData);

  const [genericSearchShow, setGenericSearchShow] = useState(false);
  const handleGenericSearchClose = () => setGenericSearchShow(false);

  const handleVoidReturnClose = () => setVoidReturnShow(false);
  // const { updateInventoryOut } = useInventory();

  // UPDATE STATE
  const updateCartState = () => {
    // GET POS CART (New Products)[LocalStorage]
    let cartProduct = getStoredCart();

    // DISPATCH POS CART PRODUCT
    dispatch(selcetProductsCart(cartProduct));

    // GET RETURN CART (Return Product)[REDUX Storege]
    let ReturnProduct = posSaleData.returnProducts;

    // POS FINALIZER POS CART
    const posCartCal = posFinalizer(cartProduct, "cartProduct");

    // {
    //   totalItem,
    //   total,
    //   vatAmount,
    //   grossTotal,
    //   grossTotalRound,
    //   newPoint,
    // }

    // POS FINALIZER RETURN CART
    const returnCartCal = posFinalizer(ReturnProduct, "ReturnCartProduct");
    // console.log("cart Cal:", posCartCal);
    // console.log("return Cal:", returnCartCal);

    // DISPATCH TO REDUX Storege
    dispatch(
      saleFinalize({
        totalItem: posCartCal.totalItem,
        total: posCartCal.total?.toFixed(2), // - posSaleData.returnCal.total,
        vatAmount: posCartCal.vatAmount?.toFixed(2), // - posSaleData.returnCal.vatAmount,
        grossTotal: parseFloat(
          posCartCal.grossTotal - returnCartCal.total
        )?.toFixed(2),
        grossTotalRound: Math.round(
          posCartCal.grossTotal - returnCartCal.total
        ),
        newPoint:
          posSaleData.point.old +
          Number(posCartCal.newPoint) -
          Number(returnCartCal.newPoint),
        // Return Calculatoin
        reTotal: returnCartCal.total,
        reTotalItem: parseInt(returnCartCal.totalItem),
        reGrossTotal: returnCartCal.grossTotal?.toFixed(2),
        reGrossTotalRound: Math.round(returnCartCal.grossTotal),
        rePoint: returnCartCal.newPoint,
        reVat: returnCartCal.vatAmount,
        promo_discount: posCartCal.promoPrice,
      })
    );
    dispatch(salesPromoPrice(posCartCal.promoPrice));

    // let returnProduct = getStoredCart();
    // console.log(Number(posCartCal.newPoint) - Number(returnCartCal.newPoint));

    // console.log(posFinalizer(cartProduct, "cartProduct"));
    return true;
  };
  /**
   * Set Biller info
   * ===============================
   */

  const user = signInUser();
  useEffect(() => {
    updateCartState();
    dispatch(selcetBiller(user.id));
  }, []);

  /**
   * ADD TO CART
   * add product cart Local storage
   */
  const carts = useSelector((state) => state.posReducer.products);
  useLayoutEffect(() => {
    if (firstProductRef.current) {
      firstProductRef.current.focus();
    }
  }, [carts?.length]);

  const addToList = async (data) => {
    if (data) {
      // console.log("add to list:", data, data.priceList);
      await supplierProductsRef.current.blur();

      // ADD TO DB
      if (data?.length > 0) {
        const add = addToDb(data[0]);
        console.log("add to list:", add, data);
        if (add) {
          const update = updateCartState();
          console.log("update:", update);
          if (update === true) {
            firstProductRef?.current?.focus();
          }
          return true;
        }
      } else {
        const add = addToDb(data);
        // console.log("add to list:", add, data);
        if (add) {
          const update = updateCartState();
          // console.log("update:", update);
          if (update === true) {
            firstProductRef?.current?.focus();
          }
          return true;
        }
      }
    }
  };

  /**
   * Empty Local Cart
   */
  const emptyCart = () => {
    console.log("empty cart");
    if (deleteShoppingCart()) {
      updateCartState();
      saleReset();
      notify("Refresh POS SALE", "success");
    }
    // setShowPermission({
    //   ...showPermission,
    //   type: "refresh",
    //   show: false,
    //   data: "",
    // });
    //   if (window.confirm("Refresh Cart! Start New Sale?") === true) {
    //     const deleted = deleteShoppingCart();
    //     if (deleted) {
    //       updateCartState();
    //       notify("Refresh POS SALE", "success");
    //     }
    //   } else {
    //     notify("Refresh Operation Cancelled by POSER", "error");
    //   }
  };
  const genericSearch = () => {
    setGenericSearchShow(true);
  };

  /**
   * removeFromCart
   * Remove Item Local Cart
   */
  const removeFromCart = (id) => {
    if (removeFromDb(id)) {
      updateCartState();
      setShowPermission({ show: false, type: "", data: "" });
      notify("Authorization Successful!", "success");
    } else {
      notify("Remove Item Unsuccessful!", "error");
    }
    // setShowPermission({
    //   ...showPermission,
    //   type: "removeFormCart",
    //   show: false,
    //   data: id,
    // });
    // console.log(id);
    // setPId(id);
    // updateCartState();
  };

  const handleReturn = () => {
    // console.log("return call");
    handleReturnShow();
    // setShowPermission({
    //   ...showPermission,
    //   type: "handelreturn",
    //   show: false,
    //   data: "",
    // });
  };

  // Handle Discount Permission
  const discountCheckbox = () => {
    setDiscountChecked(!discountChecked);
    // setShowPermission({
    //   ...showPermission,
    //   type: "discountCheck",
    //   show: false,
    //   data: "",
    // });
  };
  // console.log("hi")
  // Manager VALID PERMISSION
  // const [validUser] = useValidUserMutation();
  // const managerPermission = async (e, type, id) => {
  //   e.preventDefault();
  //   // const isValid = await validUser({ username: manager, password: pass });
  //   // console.log(isValid);
  //   // if (isValid?.data?.status === true) {

  //   // }
  //   console.log(e, type, id)
  //   switch (type) {
  //     case "removeFormCart":
  //       if (removeFromDb(id)) {
  //         updateCartState();
  //         setShowPermission({ show: false, type: "", data: "" });
  //         notify("Authorization Successful!", "success");
  //       } else {
  //         notify("Remove Item Unsuccessful!", "error");
  //       }
  //       break;
  //     case "handelreturn":
  //       console.log("handel return");
  //       setShowPermission({ show: false, type: "", data: "" });
  //       handleReturnShow();
  //       break;

  //     case "refresh":
  //       if (deleteShoppingCart()) {
  //         updateCartState();
  //         notify("Refresh POS SALE", "success");
  //       }
  //       setShowPermission({ show: false, type: "", data: "" });
  //       break;
  //     case "discountCheck":
  //       setDiscountChecked(!discountChecked);
  //       setShowPermission({ show: false, type: "", data: "" });
  //       break;

  //     default:
  //       console.log(type);
  //       break;
  //   }
  //   setManager("");
  //   setPass("");
  // };

  // DELETE FOR PERMISSION
  // const onSubmitPermission = async (e) => {
  //   e.preventDefault();
  //   const isValid = await validUser({ username: manager, password: pass });
  //   // console.log(isValid);
  //   // console.log(manager, pass, pId);
  //   if (isValid?.data && isValid?.data?.status === true) {
  //     if (removeFromDb(pId)) {
  //       updateCartState();
  //       setShowPermission(false);
  //       notify("Authorization Successful!", "success");
  //       // setManager("");
  //       // setPass("");
  //     }
  //   } else {
  //     notify("Invalid Manager", "error");
  //   }

  //   // console.log("Hi");
  // };

  /**
   * Add Qty
   * Remove Item Local Cart
   */
  const addQuantities = (id) => {
    // console.log(addQuantity(id));
    if (addQuantity(id)) {
      updateCartState();
    }
  };

  /**
   * remove Qty
   * Remove Item Local Cart
   */
  const removeQuantities = (id) => {
    if (removeQuantity(id)) {
      updateCartState();
    }
  };

  /**
   * Custom Qty
   * Custom Item Local Cart
   */
  const handleCustomQty = (e) => {
    const qty = e.target.value > 0 ? parseFloat(e.target.value) : 0;
    const article_code = e.target.attributes.getNamedItem("id").value;
    customQuantity(article_code, qty);
    updateCartState();
  };

  /**
   * Custom noInBox
   * Custom Item Local Cart
   */
  const handleNoOfBox = (e) => {
    console.log(e);
    const noOfBox = e?.target?.value > 0 ? parseFloat(e.target.value) : 0;
    const article_code = e.target.attributes.getNamedItem("id").value;
    customNoOfBox(article_code, noOfBox);
    updateCartState();
  };

  /**
   * Custom Item Discount
   * Custom Item Local Cart
   */
  const handleItemDiscount = (e, index, article) => {
    e.preventDefault();
    // console.log(e);
    // console.log(article);
    const noOfBox = e?.target?.value > 0 ? parseFloat(e.target.value) : 0;
    const article_code = e?.target?.attributes?.getNamedItem("id")?.value;
    customItemDiscount(article, noOfBox);
    updateCartState();
  };

  /**
   * Custom Sold in Box
   * Custom Item Local Cart
   */
  const handleSoldInBox = (e) => {
    console.log(e.target.value);
    const soldInBox = e.target.checked ? true : false;
    const article_code = e.target.attributes.getNamedItem("id").value;
    customSoldInBox(article_code, soldInBox);
    updateCartState();
  };

  // KEY PRESS
  // control keypress
  // TODO: Replace to Ref()
  const cashAmountField = document.getElementById("cashAmount");
  const cardAmountField = document.getElementById("cardAmount");
  const cardTypeField = document.getElementById("cardType");
  const mfsAmountField = document.getElementById("mfsAmount");
  const mfsNameField = document.getElementById("mfsName");
  // const itemQtyField = document.getElementById("itemQty");
  const discountField = document.getElementById("discountField");
  const discountCashField = document.getElementById("discountCashField");
  const billPrintButton = useRef(null);
  const firstProductRef = useRef(null);

  // const searchField = useRef(null);

  useKeypress("F1", (e) => {
    e.preventDefault();
    supplierProductsRef.current.focus();
  });

  useKeypress("F2", (e) => {
    e.preventDefault();
    discountField.focus();
  });
  useKeypress("F3", (e) => {
    e.preventDefault();
    deleteLastProduct();
    updateCartState();
  });

  useKeypress("Enter", (e) => {
    e.preventDefault();
    const active = document.activeElement;

    // if (active === itemQtyField) {
    //   supplierProductsRef.current.focus();
    // }
    if (active === discountField) {
      discountCashField.focus();
    }
    if (active === discountCashField) {
      cashAmountField.focus();
    }
    if (active === cashAmountField) {
      cardTypeField.focus();
    }
    if (active === cardTypeField) {
      cardAmountField.focus();
    }
    if (active === cashAmountField) {
      cardTypeField.focus();
    }
    if (active === cardAmountField) {
      mfsNameField.focus();
    }
    if (active === mfsNameField) {
      mfsAmountField.focus();
    }
    if (active === mfsAmountField) {
      billPrintButton.current.focus();
    }
    if (active === billPrintButton.current) {
      billPrintButton.current.click();
    }
    // console.log("pressed enter");
  });
  const handleKeyDownToSearch = (event) => {
    if (event.key === "Enter") {
      if (event.target === firstProductRef.current) {
        supplierProductsRef.current.focus();
      }
    }
  };

  /**
   * NEW CODE FOR POS SCREEN
   */

  const [hideEmpty, setHideEmpty] = useState(false);
  const [customer, setCustomer] = useState("62e301c1ee6c8940f6ac1515");
  // const [customerName, setCustomerName] = useState("01700000000");
  const [addCustomer] = useAddCustomerMutation();
  const [updateCustomerPoint] = useUpdatePointCustomerMutation();
  // const [getUserPoint] = useCustomerPointQuery();
  const [lastSale, setLastSale] = useState();
  const [lastBillId, setLastBillId] = useState("01717000000");
  const [lastInvoiceId, setLastInvoiceId] = useState("01717000000");
  const [preLoadValue, setPreLoadValue] = useState({
    name: "",
    phone: "",
    address: "",
  });

  // RETURN PRODUCTS
  const [returnProducts, setReturnProducts] = useState([]);
  const [reCal, setReCal] = useState({});
  const [returnInvoice, setReturnInvoice] = useState("");
  const [invoice, setInvoice] = useState({});

  // RETURN PRODUCTS

  const { register, handleSubmit, reset } = useForm({
    defaultValues: preLoadValue,
  });
  // NEW CODE
  // const [newPoint, setNewPoint] = useState(0);
  // const [newTotalPoint, setNewTotalPoint] = useState(0);
  // const [restPoint, setRestPoint] = useState(0);

  // const [posCalculations, setPosCalculations] = useState([]);

  const supplierProductsRef = useRef(null);
  // dispatch new point
  useEffect(() => {
    // remaning point
    const restPoint = posSaleData.point.old - posSaleData.paidAmount.point;

    // new point total
    const newTotalPoint =
      restPoint + Math.floor(posSaleData.grossTotalRound / 100);
    // console.log(posSaleData.grossTotalRound / 100);
    // console.log(newTotalPoint);

    // dispatch new point
    newTotalPoint > 0 && dispatch(saleNewPoint(newTotalPoint));
  }, [posSaleData.paidAmount.point]);

  // Hold Sale
  useEffect(() => {
    // console.log(data);
    const getHold = JSON.parse(localStorage.getItem("hold_cart"));
    getHold?.length > 0 ? setHoldSale(true) : setHoldSale(false);
  }, []);
  // UnHold sale
  const getData = JSON.parse(localStorage.getItem("pos_cart"));
  useEffect(() => {
    if (getData) {
      let newCart = getData?.sort((a, b) => b.order - a.order);
      // console.log(newCart);
      // dispatch(selcetProduct(newCart));
    }
  }, [getData, holdSale]);

  const navigate = useNavigate();
  const componentRef = useRef();

  // HANDLE PRINT RECIPT
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      // RESET CART ITEM
      localStorage.setItem("last_sale", JSON.stringify(posSaleData));
      localStorage.setItem("pos_cart", JSON.stringify([]));
      updateCartState();
      dispatch(saleReset());
      dispatch(saleVoidReset());
      setInvoice({});
      setDiscountChecked(false);
      setIsPrint(false);
      // TODO:: reset redux state

      notify("Bill Printed Successful!", "success");
    },
    // onBeforePrint: handleOnBeforeGetContent,
  });

  // const [customer, setCustomer] = useState("Walk in Customer");

  // const searchField = document.getElementById("productSearch");
  useEffect(() => {
    if (isPrint !== false) {
      handlePrint();
      notify("Create Sale Successfully!", "success");
      // setLastSale({});
    } else {
      return;
    }
  }, [isPrint]);
  console.log(isPrint);
  const [createSale] = useAddSaleMutation();

  // const { updateInventoryOut, updateInventoryInOnSaleDel } = useInventory();

  // const [customers, setCustomers] = useState([]);

  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosePermission = () =>
    setShowPermission({ type: false, type: "", data: "" });
  const handleShowPermission = () =>
    setShowPermission({ ...showPermission, type: false, type: "", data: "" });
  const handleReturnClose = () => setReturnShow(false);
  const handleReturnShow = () => setReturnShow(true);

  // console.log(show)

  const createNewCustomer = async (customer) => {
    let customerData = customer;
    const message = `সন্মানিত গ্রাহক, আপনাকে TCM-Model-Pharmacy সেবার জগতে স্বাগতম। আপনার মেম্বারশিপ আইডি ${customer?.phone}। আগামীতে আরও রোমাঞ্চকর অফার ও সার্ভিস পেতে আমাদের সাথে সংযুক্ত থাকুন। বিস্তারিত - 01742225636`;
    customerData.username = customer?.phone;
    customerData.membership = customer?.phone;
    customerData.status = "active";
    console.log(customerData);

    const newCustomer = {
      name: customer?.name,
      password: customer?.password,
      // email: null,
      phone: customer?.phone,
      username: customer?.phone,
      membership: customer?.phone,
      address: {
        type: "Home",
        id: customerUniqeId,
        holdingNo: customer?.holdingNo,
        sector: customer?.sector,
        street: customer?.road,
        town: customer?.town,
        city: customer?.city,
        division: customer?.division,
        country: customer?.country,
        zipCode: customer?.zipCode,
      },
      point: customer?.point ? customer?.point : 0,
      status: "active",
    };
    console.log("newCustomer", newCustomer);
    try {
      await addCustomer(newCustomer)
        .then((res) => {
          console.log("customer", res);
          // setCustomer(res.data.id);
          if (res?.data) {
            notify(`Create Customer as ${customer.name}`, "success");
          }
          dispatch(
            selcetCustomer({
              customerId: res.data.id,
              point: 0,
              name: customer.name,
              phone: customer.phone,
            })
          );

          // SEND SMS
          sendMessage(customerData?.phone, message);

          // notify(`Create Customer as ${customer.name}`, "success");
          // CLOSE POP UP
          handleClose();
          // RESET FORM DATA
          reset({
            name: "",
            phone: "",
            address: "",
          });
          // REFATCH CUSTOMEr DATA
        })
        .catch((err) => {
          notify(`Already a Customer`, "error");
        });
    } catch (err) {
      console.log(err);
    }
    // sendMessage("01742225636", message);

    // console.log(customerDW);
  };

  // handlePrintBill
  const handlePrintBill = async (e) => {
    e.preventDefault();
    // console.log(posCalculations);
    // // setLastSale({});
    // console.log("before sale:", posSaleData);
    // console.log("before sale:", posSaleData.products);

    if (window.confirm("Are you sure, Print this bill?") === true) {
      // let data = {};
      console.log("before sale:", posSaleData);
      // die();
      try {
        setBill(true);
        // Create Sale
        if (posSaleData) {
          if (posSaleData.changeAmount >= 0) {
            // console.log(posSaleData);
            const newSale = await createSale(posSaleData);
            // console.log("new Sale", newSale);
            if (newSale?.data?.status === 200) {
              dispatch(selectInvoiceId(newSale?.data?.data?.invoiceId));
              setLastInvoiceId(newSale?.data?.data?._id);
              // console.log(lastInvoiceId);
              // TODO::Add customer point update system backend
              if (posSaleData.customerId !== "62e301c1ee6c8940f6ac1515") {
                const customerData = {
                  _id: posSaleData.customerId,
                  point: posSaleData.point.new,
                };
                const updateCustomer = await updateCustomerPoint(customerData);
                // console.log("customer", updateCustomer);
                updateCustomer &&
                  notify(
                    `Update Points ${posSaleData.point.new} for user ${posSaleData.customerName}`,
                    "success"
                  );
              }
              // if (posSaleData.products.length > 0) {
              //   const inventoryOut = await updateInventoryOut(
              //     posSaleData.products
              //   );
              //   console.log(inventoryOut);

              //   inventoryOut &&
              //     notify(`Update Sale Inventory Stock Out`, "success");
              // }
              // if (posSaleData.returnProducts.length > 0) {
              //   const inventoryIn = await updateInventoryInOnSaleDel(
              //     posSaleData.returnProducts
              //   );
              //   inventoryIn &&
              //     notify(`Update Sale Inventory Stock In on Sale del`, "success");
              // }
              setIsPrint(true);
            } else {
              notify("Sale generation failed! Please try again", "error");
              return false;
            }
          } else {
            notify("Cash received insufficient", "error");
          }
        }
      } catch (err) {
        console.log(err);
        notify("Bill generation Unsuccessful", "error");
      } finally {
        setBill(false);
        return false;
      }
    } else {
      notify("You Cancel the Bill", "error");
    }
  };

  // console.log(posSaleData.returnProducts);

  const handleHoldSale = async () => {
    // const holdCart = carts;
    // TODO:: Create Multiple invoice hold system
    // CHECK HOLD CART
    if (posSaleData?.products?.length > 0) {
      let hold = localStorage.getItem("hold_cart");
      let holdData = JSON.parse(hold);
      if (holdData !== null) {
        holdData = [
          ...holdData,
          { products: posSaleData.products, order: new Date() },
        ];
        // console.log(holdData);

        localStorage.setItem("hold_cart", JSON.stringify(holdData));
      } else {
        localStorage.setItem(
          "hold_cart",
          JSON.stringify([
            { products: posSaleData.products, order: new Date() },
          ])
        );
      }
      localStorage.setItem("pos_cart", JSON.stringify([]));
      // setHoldSale(true);
      dispatch(saleReset());
      updateCartState();
      console.log(hold); //, holdData)
    } else {
      notify("There is no Products for hold", "error");
    }
  };

  const handleVoidReturn = () => {
    setVoidReturnShow(true);
  };

  // HANFLE SHOW HIDE 0 STOCK PRODUCT
  const handleHideEmpty = () => {
    setHideEmpty(!hideEmpty);
  };

  return (
    <div className="pos-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9">
            <div className="pos-terminal mt-4">
              <div className="row">
                <div className="col-md-4">
                  <Form.Control
                    value={user.name}
                    name="biller_id"
                    readOnly
                  ></Form.Control>
                </div>
                <div className="col-md-4">
                  <Form.Control
                    value="PHARMACY"
                    name="warehouse"
                    readOnly
                  ></Form.Control>
                </div>
                <div className="col-md-4">
                  <div className="row g-0">
                    {/* SELECT CUSTOMER */}
                    <div className="col-9">
                      <SelectCustomer />
                    </div>
                    <button
                      type="button"
                      onClick={handleShow}
                      className="col-3 btn btn-outline-dark"
                    >
                      Add +
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-11">
                  {/* SEARCH PRODUCT */}
                  <PosSearchProduct
                    className="searchProduct"
                    addToList={addToList}
                    supplierProductsRef={supplierProductsRef}
                    hideEmpty={hideEmpty}
                  ></PosSearchProduct>
                </div>
                <div className="col-md-1">
                  {hideEmpty ? (
                    <BsCheckSquare onClick={() => handleHideEmpty()} />
                  ) : (
                    <BsSquare onClick={() => handleHideEmpty()} />
                  )}
                  <b>
                    {" "}
                    0<small> Stock </small>
                  </b>
                </div>
                <div className="col-md-12">
                  <table className="table mt-3">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Product</th>
                        <th scope="col">Group</th>
                        <th scope="col">Stock</th>
                        <th className="text-center" scope="col">
                          Box?
                        </th>
                        <th scope="col">No of Box</th>
                        <th scope="col">Price</th>
                        <th className="text-center" scope="col">
                          Quantity
                        </th>
                        <th scope="col">Vat</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Sub-Total</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className="cart-list">
                      <PosCart
                        updateCart={updateCartState}
                        invoice={invoice}
                        returnProducts={returnProducts}
                        removeFromCart={removeFromCart}
                        addQuantities={addQuantities}
                        removeQuantities={removeQuantities}
                        handleCustomQty={handleCustomQty}
                        handleNoOfBox={handleNoOfBox}
                        handleItemDiscount={handleItemDiscount}
                        handleSoldInBox={handleSoldInBox}
                        reCal={reCal}
                        firstProductRef={firstProductRef}
                        handleKeyDownToSearch={handleKeyDownToSearch}
                      ></PosCart>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <PosHeader logo={logo}></PosHeader>
            <PosFinalizes
              customerPoint={customerPoint}
              billPrintButton={billPrintButton}
              // discountValidPermission={managerPermission}
              discountCheckbox={discountCheckbox}
              posFinalizer={posFinalizer}
              handlePrintBill={handlePrintBill}
              reCal={reCal}
              customer={customer}
              isBill={isbill}
              setBill={setBill}
            ></PosFinalizes>
          </div>
        </div>
      </div>
      <PosFooter
        genericSearch={genericSearch}
        emptyCart={emptyCart}
        LastBillId={lastInvoiceId}
        handleHoldSale={handleHoldSale}
        holdSale={holdSale}
        handleReturn={handleReturn}
        updateCartState={updateCartState}
        handleVoidReturn={handleVoidReturn}
      ></PosFooter>

      {/* PRINT BILL */}
      <div className="print-wrapper pt-0 mt-0">
        <div className="print-area" ref={componentRef}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p className="text-center">
                  <img src={logo1} alt="" width="180" className="print-logo" />
                </p>
                <h5 className="text-center">
                  <b> TCM Model Pharmacy</b>
                </h5>
                <p className="text-center info header">
                  <i>BIN 004601696-0102 | Mushak 6.3</i>
                </p>
                <p className="text-center info invoice">
                  <b>Invoice No: {posSaleData.invoiceId}</b>
                </p>
                <p className="info pt-2">
                  Customer Phone: {posSaleData.customerPhone}
                  <span className="float-end">
                    Date: {format(new Date(), "MMMM d, yyyy")}
                  </span>
                </p>

                <p className="info pt-2">
                  Customer: {posSaleData.customerName}
                  <span className="float-end">
                    {format(new Date(), "h:mm a")}
                  </span>
                </p>
                <p className="info pt-2">
                  Biller: {user.name}
                  <span className="float-end">
                    Outlet: <b>TCM Model Pharmacy</b>
                  </span>
                </p>

                <p className="text-center pt-2 order_details">ORDER DETAILS</p>
                <table className="table px-2 d-flex-table">
                  <thead>
                    <tr>
                      <td>SL</td>
                      <td colSpan="3">Item</td>
                      <td>Qty</td>
                      <td>Rate</td>
                      {/* <td>VAT</td> */}
                      {/* <td>Discount</td> */}
                      <td>
                        <span className="float-end">Total</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(bill.products)}  */}
                    {posSaleData?.products?.length > 0 ? (
                      posSaleData?.products?.map((item, i) => (
                        <tr className="d-print-table-row" key={item.id}>
                          <td>{i + 1}</td>
                          <td
                            colSpan="3"
                            style={{
                              textTransform: "capitalize",
                              fontSize: ".9em",
                            }}
                          >
                            {item?.name?.substring(0, 30)}
                            {item?.name?.length > 30 ? "..." : ""}
                          </td>
                          <td>{item?.qty}</td>
                          <td>{item.mrp}</td>
                          {/* <td>
                            {itemVat(
                              item?.qty && item?.vat,
                              item?.qty,
                              itemPrice(item.discount, item.mrp)
                            ).toFixed(2)}
                          </td> */}
                          {/* <td>{item?.discount} %</td> */}
                          <td>
                            <span className="float-end">
                              {itemVatTotal(
                                item?.vat,
                                item?.qty,
                                itemPrice(item.discount, item.mrp)
                              ).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No Product in Purchase cart</td>
                      </tr>
                    )}
                    {posSaleData?.returnProducts?.length > 0 && (
                      <>
                        <tr>
                          <th colSpan={3}>
                            <b>Return Products</b>
                          </th>
                          <td colSpan={5} className="text-end">
                            <b>Ref Invoice id:</b>
                            {posSaleData?.returnProducts?.returnInvoice}
                          </td>
                        </tr>
                        {posSaleData?.returnProducts?.map((item) => (
                          <tr className="d-print-table-row" key={item.id}>
                            <td
                              colSpan="3"
                              style={{
                                textTransform: "capitalize",
                                fontSize: ".9em",
                              }}
                            >
                              {item?.name.toLowerCase().substring(0, 30)}
                              {item?.name?.length > 30 ? "..." : ""}
                            </td>
                            <td>{item?.qty}</td>
                            <td>{item?.mrp}</td>
                            <td>
                              --
                              {/* {itemVat(
                                item?.qty && item?.vat,
                                item?.qty,
                                item?.mrp
                              ).toFixed(2)} */}
                            </td>
                            <td>--</td>
                            <td>
                              <span className="float-end">
                                {(item?.qty * item?.mrp).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
                <p className="bill-info border-bottom border-bottom-dashed">
                  Total Item:{posSaleData?.products?.length}
                  <span className="float-end">
                    Total Qty:{posSaleData.totalItem}
                  </span>
                </p>
                {reCal?.totalItem > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Return Item:{" "}
                    <span className="float-end">{reCal?.totalItem}</span>
                  </p>
                )}
                <p className="bill-info border-bottom border-bottom-dashed">
                  Total:{" "}
                  <span className="float-end">
                    {parseFloat(posSaleData?.total)?.toFixed(2)}
                  </span>
                </p>
                {console.log(posSaleData?.returnCal?.grossTotalRound)}
                {posSaleData?.returnCal?.grossTotalRound > 0 && (
                  <p className="bill-info">
                    Return Total:{" "}
                    <span className="float-end">
                      {posSaleData?.returnCal?.grossTotalRound}
                    </span>
                  </p>
                )}
                <p className="bill-info border-bottom border-bottom-dashed">
                  Due Adjustment:{" "}
                  <span className="float-end">
                    - {posSaleData?.discount ? posSaleData?.discount : 0}
                  </span>
                  {/* <span className="float-end">
                    - {posSaleData?.discount ? posSaleData?.discount + (posSaleData.total - posSaleData.grossTotal) : 0}
                  </span> */}
                </p>
                {/* <p className="bill-info border-bottom border-bottom-dashed">
                  Vat:{" "}
                  <span className="float-end">
                    {" "}
                    {parseFloat(
                      posSaleData.vat - posSaleData.returnCal.vat
                    )?.toFixed(2)}
                    {/* {reCal?.vatAmount > 0
                      ? posSaleData.vat - reCal?.vatAmount
                      : posSaleData.vat} */}
                {/* </span>
                </p> */}
                {/* <p className="bill-info border-bottom border-bottom-dashed">
                  Net Amount:{" "}
                  <span className="float-end border border-dark">
                    {parseFloat(posSaleData.grossTotal)?.toFixed(2) - parseFloat(posSaleData.discount)}
                  </span>
                </p> */}
                <p className="bill-info border-bottom border-bottom-dashed">
                  Net Amount:{" "}
                  <b>
                    <span className="float-end border border-dark px-1">
                      {parseInt(
                        posSaleData.grossTotalRound -
                          parseFloat(posSaleData.discount)
                      )}
                    </span>
                  </b>
                </p>
                {/* {posSaleData?.paidAmount?.cash > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Received Cash:
                    <span className="float-end">
                      {posSaleData?.paidAmount?.cash}
                    </span>
                  </p>
                )} */}
                {/* {posSaleData?.paidAmount?.card?.amount > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Received{" "}
                    {posSaleData?.paidAmount?.card?.name
                      ? posSaleData?.paidAmount?.card?.name
                      : "Visa"}{" "}
                    Card :
                    <span className="float-end">
                      {posSaleData?.paidAmount?.card.amount}
                    </span>
                  </p>
                )} */}
                {/* {posSaleData?.paidAmount?.mfs?.amount > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Received {posSaleData?.paidAmount?.mfs?.name}:
                    <span className="float-end">
                      {posSaleData?.paidAmount?.mfs?.amount}
                    </span>
                  </p>
                )} */}
                {/* {posSaleData?.paidAmount?.point > 0 && (
                  <p className="bill-info border-bottom border-bottom-dashed">
                    Received Point:
                    <span className="float-end">
                      {posSaleData?.paidAmount?.point}
                    </span>
                  </p>
                )} */}

                <p className="bill-info border-bottom border-bottom-dashed">
                  Total Received:
                  {/* <b> */}
                  <span className="float-end">
                    {posSaleData?.totalReceived}
                  </span>
                  {/* </b> */}
                </p>
                <p className="bill-info border-bottom border-bottom-dashed">
                  Change Amount:
                  <span className="float-end">
                    {posSaleData?.changeAmount?.toFixed(2)}
                    BDT
                  </span>
                </p>
                {/* <p className="bill-info text-center ">
                  Previous Point: {posSaleData.point.old} | New Point :
                  {posSaleData.point.new}
                </p> */}

                <p className="nb">
                  বিঃদ্রঃ <br />
                  ১. তাপ সংবেদনশীল সকল ঔষধ, সুগার টেস্ট স্ট্রিপ এবং ঔষধের কাটা
                  পাতা অফেরতযোগ্য। <br />
                  ২. ঔষধ ক্রয়ের সময় নিজ দায়িত্বে ঔষধের পরিমাণ এবং মেয়াদ
                  উত্তীর্ণ তারিখ দেখে নিন।
                  <br />
                  ৩. ক্রয় কৃত পণ্য ৪৮ ঘণ্টার মধ্যে পরিবর্তনযোগ্য এবং সেলস স্লিপ
                  সাথে আনতে হবে। <br />
                  ******************************ধন্যবাদ******************************
                </p>
                {/* <p className="text-center bar-code">
                  {/* {lastSale?.invoiceId && ( */}
                {/* {lastBillId && (
                    <Barcode
                      value={posSaleData.invoiceId}
                      height="60"
                      width="2"
                      fontSize="10"
                    />
                  )} */}
                {/* )} */}
                {/* </p> */}
                <p className="text-center info footer">www.tcm-bd.com</p>
                <p className="text-center info">
                  <b>Hot Line: 01742225636</b>
                </p>
                <p className="text-center info ">
                  <i>Thank you for shopping with us.</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* PRINT BILL */}
      <Toaster position="bottom-right" />

      {/* Add Customer Modal */}
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleSubmit(createNewCustomer)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputCustomer">Customer Name</label>
                <input
                  {...register("name", { required: true })}
                  type="text"
                  className="form-control"
                  id="inputCustomer"
                  aria-describedby="emailHelp"
                  placeholder="Customer Name"
                />
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputMC">Customer Phone</label>
                <input
                  {...register("phone", { required: true })}
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Customer Phone"
                />
              </div>
              {/* <div className="form-group col-12  mb-3">
                <label htmlFor="MCId">Address</label>
                <textarea
                  {...register("address")}
                  className="form-control"
                  id="address"
                  placeholder="Address"
                />
              </div> */}
              <div className="form-group col-6  mb-3">
                <label htmlFor="MCId">Holding Number</label>
                <input
                  {...register("holdingNo")}
                  type="text"
                  className="form-control"
                  id="holdingNo"
                  placeholder="Holding Number"
                />
              </div>
              <div className="form-group col-6  mb-3">
                <label htmlFor="MCId">Road</label>
                <input
                  {...register("road")}
                  type="text"
                  className="form-control"
                  id="road"
                  placeholder="Road"
                />
              </div>
              <div className="form-group col-6  mb-3">
                <label htmlFor="MCId">Sector</label>
                <select {...register("sector")} className="form-control">
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option selected value="7">
                    07
                  </option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                </select>
              </div>
              <div className="form-group col-6  mb-3">
                <label htmlFor="MCId">Town</label>
                <select {...register("town")} className="form-control">
                  <option selected value="Uttara">
                    Uttara
                  </option>
                </select>
              </div>
              <div className="form-group col-6  mb-3">
                <label htmlFor="MCId">City</label>
                <select {...register("city")} className="form-control">
                  <option selected value="Dhaka">
                    Dhaka
                  </option>
                </select>
              </div>
              <div className="form-group col-6  mb-3">
                <label htmlFor="MCId">Division</label>
                <select {...register("division")} className="form-control">
                  <option selected value="Dhaka">
                    Dhaka
                  </option>
                </select>
              </div>
              <div className="form-group col-6  mb-3">
                <label htmlFor="MCId">Zip Code</label>
                <select {...register("zipCode")} className="form-control">
                  <option selected value="1230">
                    1230
                  </option>
                </select>
              </div>
              <div className="form-group col-6  mb-3">
                <label htmlFor="MCId">Country</label>
                <select {...register("country")} className="form-control">
                  <option selected value="BD">
                    Bangladesh
                  </option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              className="btn btn-outline-dark"
              variant="outlineDark"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="dark" type="submit" className="btn btn-dark ">
              <Icons.Plus> </Icons.Plus> Add Customer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* Return Modal */}
      <ReturnModal
        title="Return Product"
        onShow={returnShow}
        handleClose={handleReturnClose}
        returnProducts={returnProducts}
        setReturnProducts={setReturnProducts}
        updateCart={updateCartState}
        reCal={reCal}
        setReCal={setReCal}
        returnInvoice={returnInvoice}
        setReturnInvoice={setReturnInvoice}
        // handleReturnCustomerSelect={handleReturnCustomerSelect}
        invoice={invoice}
        setInvoice={setInvoice}
      ></ReturnModal>
      <VoidReturnModal
        returnProducts={returnProducts}
        setReturnProducts={setReturnProducts}
        onShow={voidReturnShow}
        setOnShow={setVoidReturnShow}
        handleClose={handleVoidReturnClose}
        updateCart={updateCartState}
        setLastInvoiceId={setLastInvoiceId}
        setInvoice={setInvoice}
      ></VoidReturnModal>

      <GenericSearchModal
        onShow={genericSearchShow}
        handleClose={handleGenericSearchClose}
      ></GenericSearchModal>

      {/* Permission Modal */}
      <Modal show={showPermission.show} onHide={handleClosePermission}>
        {console.log(showPermission)}
        <form>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Process</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputCustomer">Manager</label>
                {/* <input
                  name="manager"
                  type="text"
                  className="form-control"
                  id="inputCustomer"
                  placeholder="User name"
                  onBlur={(e) => setManager(e.target.value)}
                /> */}
                <select
                  onChange={(e) => setManager(e.target.value)}
                  className="form-control"
                >
                  <option value="">Select User</option>
                  <option value="manager">Manager</option>
                  <option value="manishankarvakta">Manishankar</option>
                </select>
                <small id="emailHelp" className="form-text text-muted">
                  {/* We'll never share your email with anyone else. */}
                </small>
              </div>
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputMC">Password</label>
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  id="phone"
                  onBlur={(e) => setPass(e.target.value)}
                  placeholder="Password"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              className="btn btn-outline-dark"
              variant="outlineDark"
              onClick={handleClosePermission}
            >
              Cancel
            </Button>
            <Button
              variant="dark"
              type="submit"
              // onClick={(e) =>
              //   managerPermission(e, showPermission.type, showPermission.data)
              // }
              className="btn btn-dark "
            >
              <Icons.Check> </Icons.Check> Confirm
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default POS;
