import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
import "./Supplier.css";
import * as Icons from "heroicons-react";
import { Link } from "react-router-dom";
// import { useForm } from "react-hook-form";
// import { notify } from "../Utility/Notify";
import toast, { Toaster } from "react-hot-toast";
import SideBar from "../Common/SideBar/SideBar";
import {
  // useSuppliersQuery,
  // useSupplierQuery,
  // useAddSupplierMutation,
  // useUpdateSupplierMutation,
  useDeleteSupplierMutation,
  useSupplierPagenationQuery,
  useCountSupplierQuery,
} from "../../services/supplierApi";
import ReactPaginate from "react-paginate";
import LoadingModal from "../Common/Modal/LoadingModal";

const Supplier = () => {
  //   const { data } = useSuppliersQuery();
  const [deleteSupplier] = useDeleteSupplierMutation();
  // get totel product count
  const pageCountQuery = useCountSupplierQuery();

  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useSupplierPagenationQuery({
      page,
      size,
      q,
    });
  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data, isSuccess]);

  // console.log('Supplier Data::>',data);

  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const deleteHandler = async (id) => {
    try {
      const confirm = window.confirm("Are you Sure? Delete this Supplier?");
      if (confirm) {
        setLoader(true);
        const res = await deleteSupplier(id);
        if (res) {
          // TODO::
          // add error hendaler for delete error
          console.log(res);
        } else {
          console.log("Delete Operation Canceled by Supplier!");
          return;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const handlePageClick = (data) => {
    setPage(parseInt(data.selected));
    setPageNo(getPageNumber);
    refetch();
  };
  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    setPageNo(getPageNumber);
    refetch();
  };

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  const getPageNumber = () => {
    const cont = Math.ceil(parseInt(pageCount) / parseInt(size));
  };

  let i = 1;

  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Supplier"></Header>
            <div className="row">
              <div className="col-md-6 mt-4">
                <form>
                  <div className="input-group mb-3 mt-5">
                    <select
                      className="form-select"
                      onChange={(e) => handleDataLimit(e)}
                    >
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="search"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    {/* <input type="text" className="form-control" aria-label="Text input with dropdown button"> */}
                  </div>
                </form>

                <nav aria-label="Page navigation example">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    //dynamic page count
                    // page count total product / size
                    pageCount={Math.ceil(parseInt(pageCount) / parseInt(size))}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={6}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pt-0 pb-2"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                  ></ReactPaginate>
                </nav>
              </div>
              <div className="col-md-2"></div>

              <div className="col-md-2">
                <Link
                  to="/supplier/import"
                  className="float-end btn btn-dark my-3"
                >
                  Add Supplier
                </Link>
              </div>
              <div className="col-md-2">
                <Link
                  to="/create-supplier"
                  className="float-end btn btn-dark my-3"
                >
                  Create Supplier
                </Link>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-12">
                <Link
                  to="/create-supplier"
                  className="float-end btn btn-dark my-3"
                >
                  Create Supplier
                </Link>
              </div> */}
              <div className="col-md-12">
                <Table hover>
                  <thead>
                    <tr>
                      {/* <th scope="col">#</th> */}
                      <th scope="col">Code</th>
                      <th scope="col">Supplier</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Name</th>
                      {/* <th scope="col">Address</th> */}
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data
                        ?.slice()
                        .sort((a, b) => a.code - b.code)
                        .map((supplier) => (
                          <tr key={supplier._id}>
                            {/* <th scope="row">{i++}</th> */}
                            <th scope="row">{supplier.code}</th>
                            <td>{supplier?.company}</td>
                            <td>{supplier?.email ? supplier?.email : "No Email" }</td>
                            <td>
                              {supplier?.phone ? supplier?.phone : "No Phone"}
                            </td>
                            <td>
                              {supplier?.name ? supplier?.name : "No Name" }
                            </td>
                            {/* <td>{supplier?.address}</td> */}
                            <td>
                              <Link to={`/supplier-ledger/${supplier._id}`}>
                                <Icons.DocumentTextOutline
                                  className="icon-edit"
                                  size={20}
                                ></Icons.DocumentTextOutline>
                              </Link>
                              <Link to={`/update-supplier/${supplier._id}`}>
                                <Icons.PencilAltOutline
                                  className="icon-edit"
                                  size={20}
                                ></Icons.PencilAltOutline>
                              </Link>
                              <Icons.TrashOutline
                                className="icon-trash"
                                // onClick={() => deleteHandler(supplier._id)}
                                size={20}
                              ></Icons.TrashOutline>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan={4}>No Supplier Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default Supplier;
