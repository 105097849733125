// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.print-wrapper p{
    margin: 0;
    padding: 0;
}
.print-wrapper p.name{
    margin-top: 5px;
    margin-bottom: -1px;
    padding: 0;
    font-size: .7em;
    line-height: 1em;
    z-index: 1055 !important;
}
.print-wrapper p.mrp{
    margin-top: -5px;
    font-size: .8em;
    z-index: 1055 !important;
    padding: 0;
}


.print-wrapper .barCode{
    margin: 0;
    padding: 0;
    z-index: 1050 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Modal/ProductBarCode.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;AACd;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,wBAAwB;AAC5B;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,wBAAwB;IACxB,UAAU;AACd;;;AAGA;IACI,SAAS;IACT,UAAU;IACV,wBAAwB;AAC5B","sourcesContent":[".print-wrapper p{\n    margin: 0;\n    padding: 0;\n}\n.print-wrapper p.name{\n    margin-top: 5px;\n    margin-bottom: -1px;\n    padding: 0;\n    font-size: .7em;\n    line-height: 1em;\n    z-index: 1055 !important;\n}\n.print-wrapper p.mrp{\n    margin-top: -5px;\n    font-size: .8em;\n    z-index: 1055 !important;\n    padding: 0;\n}\n\n\n.print-wrapper .barCode{\n    margin: 0;\n    padding: 0;\n    z-index: 1050 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
