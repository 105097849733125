import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import "./Inventory.css";
import * as Icons from "heroicons-react";
import { Table, Button } from "react-bootstrap";
import {
  useInventoriesQuery,
  useInventoryCountQuery,
} from "../../services/inventoryApi";
import ReactPaginate from "react-paginate";
import InventoryViewModal from "../Common/Modal/InventoryViewModal";
import InventoryExport from "./InventorySession/InventoryExport";
import ExportInventory from "../Common/Modal/ExportInventory";
import LoadingModal from "../Common/Modal/LoadingModal";
// import useInventory from '../Hooks/useInventory';

const Inventory = () => {
  let i = 1;

  // const [inventories, setInventories] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");
  const [onShow, setOnShow] = useState(false);
  const [onExportShow, setOnExportShow] = useState(false);
  const [code, setCode] = useState("");

  const [loader, setLoader] = useState(true);
  const handleLoaderClose = () => setLoader(false);

  const handleClose = () => setOnShow(false);
  const handleExportClose = () => setOnExportShow(false);

  const { data, error, isSuccess, refetch } = useInventoriesQuery({
    pageNo,
    size,
    q,
  });

  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data]);

  // console.log("data:", data);

  // get totel product count
  const pageCountQuery = useInventoryCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    // console.log(data);
    setPageCount(data);
  }, [pageCountQuery]);

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };
  // console.log(data);
  const handlePageClick = (data) => {
    setPageNo(data.selected + 1);
    // console.log(data);
    // setPageNo(getPageNumber);
    refetch();
  };

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    // setPageNo(getPageNumber);
    refetch();
  };

  const handleViewInventory = (code) => {
    // console.log(code);
    setCode(code);
    setOnShow(true);
  };
  const handelExportModal = () => {
    // console.log("hello");
    setOnExportShow(true);
  };
  // console.log(data, error, isSuccess, pageNo, size, q);
  return (
    <div>
      <div className="container-fluid ">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Products Inventory"></Header>
            <div className="row mt-3">
              <div className="col-md-8">
                <form>
                  <div className="input-group mb-3">
                    <select
                      className="form-select"
                      onChange={(e) => handleDataLimit(e)}
                    >
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                    <input
                      className="form-control"
                      type="text"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    {/* <input type="text" className="form-control" aria-label="Text input with dropdown button"> */}
                  </div>
                </form>
                {/* Pagenation */}

                <nav aria-label="Page navigation example">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    //dynamic page count
                    // page count total product / size
                    pageCount={Math.ceil(parseInt(pageCount) / parseInt(size))}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={6}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pt-0 pb-2"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                  ></ReactPaginate>
                </nav>
              </div>
              <div className="col-md-4">
                <span className="float-end">
                  <Button
                    className="btn btn-dark mb-2 float-end me-2"
                    onClick={handelExportModal}
                  >
                    <Icons.PlusOutline size={22}></Icons.PlusOutline> Export
                    Inventory
                  </Button>
                </span>
              </div>
            </div>
            <Table hover className="table-striped table-bordered table-sm ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {/* <th scope="col">Product Name</th> */}
                  <th scope="col">Article Code</th>
                  <th scope="col">Name</th>
                  <th scope="col">Group</th>
                  <th scope="col">Opening Quantity</th>
                  <th scope="col">GRN Quantity</th>
                  <th scope="col">Sold Quantity</th>
                  <th scope="col">Sales Return</th>
                  <th scope="col">Damage Quantity</th>
                  <th scope="col">RTV Quantity</th>
                  <th scope="col">Closing Quantity</th>
                  <th scope="col">TP</th>
                  <th scope="col">Trade Value</th>
                  {/* <th scope="col">MRP Value</th> */}
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data?.map((inventory) => (
                    <tr key={inventory._id}>
                      <td>{i++}</td>
                      <td>{inventory.article_code}</td>
                      <td className="text-warp">{inventory?.name}</td>
                      <td className="text-warp">{inventory?.groupName}</td>
                      <td>{inventory?.openingQty?.toFixed(2)}</td>
                      <td>{inventory?.totalQty?.toFixed(2)}</td>
                      <td>{inventory?.soldQty?.toFixed(2)}</td>
                      <td>{inventory?.salesReturnQty?.toFixed(2)}</td>
                      <td>{inventory?.damageQty?.toFixed(2)}</td>
                      {/* {console.log(inventory)} */}
                      <td>{inventory?.rtvQty?.toFixed(2)}</td>
                      <td>{inventory?.currentQty?.toFixed(2)}</td>
                      <td> {parseFloat(inventory?.tp).toFixed(2)} </td>
                      <td>
                        {(
                          parseFloat(inventory?.currentQty) *
                          parseFloat(inventory?.tp)
                        ).toFixed(2)}
                      </td>
                      {/* <td>
                        {(
                          parseFloat(inventory?.currentQty) *
                          parseFloat(inventory?.mrp)
                        ).toFixed(2)}
                      </td> */}
                      <td>
                        <Icons.EyeOutline
                          onClick={() => handleViewInventory(inventory?._id)}
                          className="icon-eye me-1"
                          size={20}
                        ></Icons.EyeOutline>
                        {inventory?.currentQty > 0 ? (
                          <small className="text-success">In Stock</small>
                        ) : (
                          <small className="text-danger">No Stock</small>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>No Inventory</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <InventoryViewModal
        onShow={onShow}
        handleClose={handleClose}
        code={code}
      />
      <ExportInventory
        onShow={onExportShow}
        handleClose={handleExportClose}
      ></ExportInventory>
    </div>
  );
};

export default Inventory;
