import React, { Fragment, useEffect, useState } from "react";
import { useUnitsQuery } from "../../../services/unitApi";

import Select from "react-select";

const SelectUnit = ({ ut, handleOnchange }) => {
  const { data, error, isLoading, isFetching, isSuccess } = useUnitsQuery();
  let [unit, setUnit] = useState([]);
  // console.log("data", data)
  useEffect(() => {
    // let units = [{ option: "no-Unit", label: "Select Unit" }];
    let units = [];
    if (isSuccess) {
      if (data.length > 0) {
        data.map((unit) => {
          units = [
            ...units,
            {
              option: unit._id,
              label: `${unit.name}`,
            },
          ];
        });
        setUnit(units);
      }
    }
  }, [data, isSuccess]);


  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={unit[0]}
        value={ut && unit[unit.map(obj => obj.option).indexOf(ut)]}
        // value={unit[unit.indexOf(unit)]}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isSearchable={true}
        onChange={(e) => handleOnchange(e)}
        // name={name}
        options={unit}
      />

      <div
        style={{
          color: "hsl(0, 0%, 40%)",
          display: "inline-block",
          fontSize: 12,
          fontStyle: "italic",
          marginTop: "1em",
        }}
      ></div>
    </Fragment>
  );
};

export default SelectUnit;
