import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Icons from "heroicons-react";
import { useForm } from "react-hook-form";
import { apiUniqueErrHandle } from "../../Utility/Utility";
import { useNavigate } from "react-router-dom";
import { useAddGenericMutation } from "../../../services/genericApi";
import { useAddBrandMutation } from "../../../services/brandApi";
import { notify } from "../../Utility/Notify";

const BrandAddModal = ({ onShow, setOnShow, handleClose }) => {
  let navigate = useNavigate();
  const [addBrand] = useAddBrandMutation();

  const { register, handleSubmit, reset } = useForm({});

  const onSubmit = async (data) => {
    // console.log("data", data)
    try {
      const response = await addBrand(data);
      if (response) {
        // console.log(response);
        if (response?.error) {
          apiUniqueErrHandle(response);
        } else {
          reset({
            name: "",
            code: "",
            photo: "",
            details: "",
            status: "",
          });
          // console.log(response?.data?.message);
          notify("Brand Added", "success");
          setOnShow(false);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const handleReset = () => {
    reset({
      name: "",
      code: "",
      photo: "",
      details: "",
      status: "active",
    });
  };
  return (
    <Modal
      show={onShow}
      onHide={handleClose}
      centered={true}
      size="md"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className="d-flex justify-content-end" closeButton>
        <Modal.Title>Add Brand </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputCustomer">Brand Name</label>
                <input
                  {...register("name", { required: true })}
                  type="text"
                  className="form-control"
                  id="inputCustomer"
                  aria-describedby="emailHelp"
                  placeholder="Brand Name"
                />
              </div>
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputCustomer">Brand Code</label>
                <input
                  {...register("code", { required: true })}
                  type="text"
                  className="form-control"
                  id="inputCustomer"
                  aria-describedby="emailHelp"
                  placeholder="Brand Code"
                />
              </div>
              {/* <div className="form-group col-12  mb-3">
                                <label htmlFor="inputMC"> company</label>
                                <input
                                    {...register("company")}
                                    type="text"
                                    className="form-control"
                                    id="phone"
                                    placeholder="company"
                                />
                            </div> */}
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputMC"> Details</label>
                <textarea
                  {...register("details")}
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="details"
                />
              </div>
              <div className="form-group col-12  mb-3">
                <label htmlFor="MCId">status</label>
                <select
                  {...register("status")}
                  className="form-control"
                  id="address"
                  placeholder="Address"
                >
                  <option value="active">active</option>
                  <option value="inactive">inactive</option>
                </select>
              </div>
            </div>
            <button
              type="reset"
              onClick={handleReset}
              className="btn btn-outline-dark col-4 col-md-4"
            >
              Reset
            </button>
            <button type="submit" className="btn btn-dark col-8 col-md-8">
              <>
                <Icons.Plus> </Icons.Plus>
              </>
              Brand
            </button>
          </form>
        </div>
        {/* <PO ref={componentRef} purchase={purchaseView.data} /> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BrandAddModal;
