import * as Icons from "heroicons-react";
import { Accordion, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../logo.png";
import { useState, useEffect } from "react";
import "./SideBar.css";

const AdminSideBar = () => {
  let active = window.location.pathname;

  return (
    <aside className="sticky-md-top pb-5">
      <Link className="nav-link logo pt-3 pb-5" to="/">
        <Image src={logo} fluid />
      </Link>
      <nav
        className="nav flex-column"
        style={{ overflowY: "scroll", display: "block", height: "100vh" }}
      >
        <>
          {/* DASHBOARD */}
          <Link
            className={`nav-link ${active === "/dashboard" && "active"}`}
            to="/dashboard"
          >
            <Icons.AdjustmentsOutline size={22}></Icons.AdjustmentsOutline>{" "}
            Dashboard
          </Link>

          {/* POS */}
          <Link
            className={`nav-link ${active === "/pos" && "active"}`}
            to="/pos"
          >
            <Icons.ShoppingBagOutline size={22} /> POS
          </Link>

          {/* SALES DW */}
          <Link
            className={`nav-link ${
              (active === "/ecom" ||
                active === "/sales" ||
                active === "/articleSaleExport" ||
                active === "/categorySaleExport" ||
                active === "/customer") &&
              "active"
            }`}
            to="/sales"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.ShoppingCartOutline size={22} /> Sales
              </span>
              <span className="text-end">
                {active === "/sales" ||
                active === "/customer" ||
                active === "/categorySaleExport" ||
                active === "/articleSaleExport" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
                {/*  */}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/sales" ||
              active === "/customer" ||
              active === "/categorySaleExport" ||
              active === "/articleSaleExport"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link pt-0 ${
                  active === "/sales" || active === "/articleSaleExport"|| active === "/categorySaleExport"
                    ? "active"
                    : ""
                }`}
                to="/sales"
              >
                <Icons.ShoppingCartOutline
                  size={18}
                ></Icons.ShoppingCartOutline>{" "}
                Sales
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mt-0 ${
                  active === "/customer" && "active"
                }`}
                to="/customer"
              >
                <Icons.UsersOutline size={18}></Icons.UsersOutline> Customers
              </Link>
            </li>
          </ul>

          {/* ACCOUNTS DW */}
          <Link
            className={`nav-link ${
              (active === "/accountheads" ||
                active === "/accounts" ||
                active === "/addAfterSale" ||
                active === "/addAccountExpense" ||
                active === "/addAccount" ||
                active === "/ledger" ||
                active === "/addAccountHead") &&
              "active"
            }`}
            to="/accounts"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.CurrencyDollarOutline size={22} /> Accounts
              </span>
              <span className="text-end">
                {active === "/accountheads" ||
                active === "/accounts" ||
                active === "/addAfterSale" ||
                active === "/addAccountExpense" ||
                active === "/addAccount" ||
                active === "/ledger" ||
                active === "/addAccountHead" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
                {/*  */}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/accountheads" ||
              active === "/accounts" ||
              active === "/addAfterSale" ||
              active === "/addAccountExpense" ||
              active === "/addAccount" ||
              active === "/ledger" ||
              active === "/addAccountHead"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link  pt-0 ${
                  (active === "/accounts" ||
                    active === "/addAfterSale" ||
                    active === "/addAccountExpense" ||
                    active === "/addAccount") &&
                  "active"
                }`}
                to="/accounts"
              >
                <Icons.CurrencyBangladeshiOutline size={18} />
                Accounts
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link  pt-0 ${
                  (active === "/accountheads" ||
                    active === "/addAccountHead") &&
                  "active"
                }`}
                to="/accountheads"
              >
                <Icons.BookmarkAltOutline size={18} /> Account Heads
              </Link>
            </li>
          </ul>

          {/* RPODUCT DW */}
          <Link
            className={`nav-link ${
              (active === "/product" ||
                active === "/product/import" ||
                active === "/price/import" ||
                active === "/product/add" ||
                active === "/brand" ||
                active === "/brand/import" ||
                active === "/group" ||
                active === "/group/import" ||
                active === "/generic"||
                active === "/generic/import") &&
              "active"
            }`}
            to="/product"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.ArchiveOutline size={22} /> Products
              </span>
              <span className="text-end">
                {active === "/product" ||
                active === "/prduct/import" ||
                active === "/price/import" ||
                active === "/product/add" ||
                active === "/brand" ||
                active === "/brand/import" ||
                active === "/unit" ||
                active === "/group" ||
                active === "/group/import" ||
                active === "/generic" ||
                active === "/generic/import" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
                {/*  */}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/product" ||
              active === "/prduct/import" ||
              active === "/price/import" ||
              active === "/product/add" ||
              active === "/brand" ||
              active === "/brand/import" ||
              active === "/group" ||
              active === "/group/import" ||
              active === "/generic" ||
               active === "/generic/import"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link mt-0 ${
                  (active === "/product" ||
                    active === "/prduct/import" ||
                    active === "/price/import" ||
                    active === "/product/add") &&
                  "active"
                }`}
                to="/product"
              >
                <Icons.ArchiveOutline size={18} /> Products
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mt-0 ${
                  (active === "/generic" ||
                    active === "/generic/addCategory") &&
                  "active"
                }`}
                to="/generic"
              >
                <Icons.CubeTransparentOutline
                  size={18}
                ></Icons.CubeTransparentOutline>{" "}
                Generic
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mt-0 ${active === "/brand" && "active"}`}
                to="/brand"
              >
                <Icons.SparklesOutline size={18} /> Brand
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mt-0 ${active === "/group" && "active"}`}
                to="/group"
              >
                <Icons.ChipOutline size={18} /> Group
              </Link>
            </li>
          </ul>

          {/* procurement */}
          <Link
            className={`nav-link ${
              (
                active === "/purchase" ||
                active === "/purchase-createSearch" ||
                active === "/purchase-create" ||
                active === "/grn" ||
                active === "/grn-create" ||
                active === "/grn-details" ||
                active === "/grn-summary" ||
                active === "/rtv" ||
                active === "/rtv-create" ||
                active === "/rtv-details" ||
                active === "/rtv-summary" ||
                active === "/tpn" ||
                active === "/tpn-out" ||
                active === "/tpn-details" ||
                active === "/tpn-summary" ||
                active === "/damage" ||
                active === "/damagecreate" ||
                active === "/damage-details" ||
                active === "/damage-summary" ||
                active === "/supplier" ||
                active === "/create-supplier"||
              active === "/supplier/import") &&
              "active"
            }`}
            to="/purchase"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.InboxInOutline size={22} /> Procurement
              </span>
              <span className="text-end">
                {active === "/purchase" ||
                active === "/purchase-createSearch" ||
                active === "/purchase-create" ||
                active === "/grn" ||
                active === "/grn-create" ||
                active === "/grn-details" ||
                active === "/grn-summary" ||
                active === "/rtv" ||
                active === "/rtv-create" ||
                active === "/rtv-details" ||
                active === "/rtv-summary" ||
                active === "/tpn" ||
                active === "/tpn-out" ||
                active === "/tpn-details" ||
                active === "/tpn-summary" ||
                active === "/damage" ||
                active === "/damagecreate" ||
                active === "/damage-details" ||
                active === "/damage-summary" ||
                active === "/supplier" ||
              active === "/supplier/import"||
                active === "/create-supplier" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
                {/*  */}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/purchase" ||
              active === "/purchase-createSearch" ||
              active === "/grn" ||
              active === "/grn-create" ||
              active === "/grn-details" ||
              active === "/grn-summary" ||
              active === "/rtv" ||
              active === "/rtv-create" ||
              active === "/rtv-details" ||
              active === "/rtv-summary" ||
              active === "/tpn" ||
              active === "/tpn-out" ||
              active === "/tpn-details" ||
              active === "/tpn-summary" ||
              active === "/damage" ||
              active === "/damagecreate" ||
              active === "/damage-details" ||
              active === "/damage-summary" ||
              active === "/supplier" ||
              active === "/create-supplier"||
              active === "/supplier/import"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link mt-0 ${
                  (active === "/purchase" || active === "/purchase-createSearch") &&
                  "active"
                }`}
                to="/purchase"
              >
                <Icons.CurrencyDollarOutline
                  size={18}
                ></Icons.CurrencyDollarOutline>{" "}
                Purchase
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link  pt-0 ${
                  (active === "/grn" ||
                    active === "/grn-create" ||
                    active === "/grn-details" ||
                    active === "/grn-summary") &&
                  "active"
                }`}
                to="/grn"
              >
                <Icons.ClipboardCheckOutline size={18} /> GRN
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/rtv" ||
                    active === "/rtv-create" ||
                    active === "/rtv-details" ||
                    active === "/rtv-summary") &&
                  "active"
                }`}
                to="/rtv"
              >
                <Icons.ReceiptRefundOutline size={18} /> RTV
              </Link>
            </li>

            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/tpn" ||
                    active === "/tpn-out" ||
                    active === "/tpn-details" ||
                    active === "/tpn-summary") &&
                  "active"
                }`}
                to="/tpn"
              >
                <Icons.HomeOutline size={18}></Icons.HomeOutline> TPN
              </Link>
            </li>

            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/damage" ||
                    active === "/damagecreate" ||
                    active === "/damage-details" ||
                    active === "/damage-summary") &&
                  "active"
                }`}
                to="/damage"
              >
                <Icons.TrashOutline size={18} /> Damage
              </Link>
            </li>

            <li>
              <Link
                className={`nav-link pt-0 ${
                  (active === "/supplier" || active === "/create-supplier") &&
                  "active"
                }`}
                to="/supplier"
              >
                <Icons.UsersOutline size={18}></Icons.UsersOutline> Supplier
              </Link>
            </li>
          </ul>

          {/* INVENTORY */}
          <Link
            className={`nav-link ${
              (active === "/inventory") &&
              "active"
            }`}
            to="/inventory"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.ViewGridAddOutline size={22} /> Inventory
              </span>
              <span className="text-end">
                {active === "/inventory" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
                {/*  */}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/inventory" ||
              active === "/adjust" ||
              active === "/movement" ||
              active === "/inventory-movement" ||
              active === "/cogs"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              <Link
                className={`nav-link pt-0 ${
                  active === "/inventory" && "active"
                }`}
                to="/inventory"
              >
                <Icons.CollectionOutline size={18} /> Inventories
              </Link>
            </li>
            {/* <li>
              <Link
                className={`nav-link pt-0 ${active === "/adjust" && "active"}`}
                to="/adjust"
              >
                <Icons.SwitchVerticalOutline size={18} /> Adjust
              </Link>
            </li> */}
            {/* <li>
              <Link
                className={`nav-link pt-0 ${
                  active === "/movement" && "active"
                }`}
                to="/movement"
              >
                <Icons.ArchiveOutline size={18} /> Movement
              </Link>
            </li> */}
            <li>
              <Link
                className={`nav-link pt-0 ${
                  active === "/inventory-movement" && "active"
                }`}
                to="/inventory-movement"
              >
                <Icons.ArchiveOutline size={18} /> Movement
              </Link>
            </li>
          </ul>

          <Link
            className={`nav-link ${active === "/exports" && "active"}`}
            to="/exports"
          >
            <Icons.DownloadOutline size={22} /> Exports
          </Link>

          {/* SETTINGS */}
          <Link
            className={`nav-link ${
              (active === "/user" ||
                active === "/user/add" ||
                active === "/warehouse" ||
                active === "/sms" ||
                active === "/profile") &&
              "active"
            }`}
            to="/user"
          >
            <div className="d-flex justify-content-between">
              <span>
                <Icons.CogOutline size={22} /> Settings
              </span>
              <span className="text-end">
                {active === "/user" ||
                active === "/user/add" ||
                active === "/warehouse" ||
                active === "/sms" ||
                active === "/profile" ? (
                  <Icons.ChevronDownOutline size={16} />
                ) : (
                  <Icons.ChevronRightOutline size={16} />
                )}
                {/*  */}
              </span>
            </div>
          </Link>
          <ul
            className={`p-0 ps-4 ${
              active === "/user" ||
              active === "/user/add" ||
              active === "/warehouse" ||
              active === "/sms" ||
              active === "/profile"
                ? "d-bolck"
                : "d-none"
            }`}
          >
            <li>
              {/* USER */}
              <Link
                className={`nav-link mt-0 ${
                  (active === "/user" || active === "/user/add") && "active"
                }`}
                to="/user"
              >
                <Icons.UsersOutline size={18} /> Users
              </Link>
            </li>

            <li>
              {/* WAREHOUSE */}
              <Link
                className={`nav-link mt-0 ${
                  active === "/warehouse" && "active"
                }`}
                to="/warehouse"
              >
                <Icons.HomeOutline size={18} /> Warehouse
              </Link>
            </li>
            <li>
              {/* SMS */}
              <Link
                className={`nav-link mt-0 ${active === "/sms" && "active"}`}
                to="/sms"
              >
                <Icons.ChatAltOutline size={18}></Icons.ChatAltOutline> SMS
              </Link>
            </li>
          </ul>
        </>
      </nav>
    </aside>
  );
};
export default AdminSideBar;
