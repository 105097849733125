// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap'); */

.print-area {
  /* font-family: 'DotGothic16', sans-serif; */
  max-width: 400px;
  margin: 20px auto 0;
  /* font-weight: 500; */
}

.print-wrapper .print-logo {
  max-width: 180px;
}

p.info {
  font-size: 0.9em;
  margin: 0px auto;
}

p.bill-info {
  /* font-family: 'DotGothic16', sans-serif; */
  font-size: 0.85em;
  margin: 2px auto;
}

p.order_details {
  font-weight: 500;
}

p.nb {
  font-size: 0.8em;
  margin-bottom: 0;
}

.print-wrapper th {
  font-weight: 400;
}

.print-wrapper th,
.print-wrapper td {
  font-size: 0.6em !important;
  margin: 5px auto;
}

.table > :not(caption) > * > * {
  padding: 0.2rem 0.1rem;
}

p.bar-code {
  margin-bottom: 0px;
}

p.bar-code svg {
  width: 260px;
}

p.info.footer {
  margin: 0px auto 0px;
}

p.text-center.pt-2 {
  font-size: 0.9em;
  margin-bottom: 0px;
}

p.invoice {
  font-size: 0.8em;
}

.print-wrapper p.header {
  font-size: 0.8em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/PrintReceipt/PrintReceipt.css"],"names":[],"mappings":"AAAA,sFAAsF;;AAEtF;EACE,4CAA4C;EAC5C,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,4CAA4C;EAC5C,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/* @import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap'); */\n\n.print-area {\n  /* font-family: 'DotGothic16', sans-serif; */\n  max-width: 400px;\n  margin: 20px auto 0;\n  /* font-weight: 500; */\n}\n\n.print-wrapper .print-logo {\n  max-width: 180px;\n}\n\np.info {\n  font-size: 0.9em;\n  margin: 0px auto;\n}\n\np.bill-info {\n  /* font-family: 'DotGothic16', sans-serif; */\n  font-size: 0.85em;\n  margin: 2px auto;\n}\n\np.order_details {\n  font-weight: 500;\n}\n\np.nb {\n  font-size: 0.8em;\n  margin-bottom: 0;\n}\n\n.print-wrapper th {\n  font-weight: 400;\n}\n\n.print-wrapper th,\n.print-wrapper td {\n  font-size: 0.6em !important;\n  margin: 5px auto;\n}\n\n.table > :not(caption) > * > * {\n  padding: 0.2rem 0.1rem;\n}\n\np.bar-code {\n  margin-bottom: 0px;\n}\n\np.bar-code svg {\n  width: 260px;\n}\n\np.info.footer {\n  margin: 0px auto 0px;\n}\n\np.text-center.pt-2 {\n  font-size: 0.9em;\n  margin-bottom: 0px;\n}\n\np.invoice {\n  font-size: 0.8em;\n}\n\n.print-wrapper p.header {\n  font-size: 0.8em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
