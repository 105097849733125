import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import Select from "react-select";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";

const CategorySelectByMC = ({ sc, handleOnChangeCategory, name, scValue }) => {
  const [category, setCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState(scValue || "All");
  const [options, setOptions] = useState([]);

  const getCategory = async (sc) => {
    let cancelToken;
    let result;

    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Cancel The Previous Request");
    }

    cancelToken = axios.CancelToken.source();
    result = await axios.get(
      `${BASE_URL}/group${sc ? `/mc/${sc}` : ""}`,
      { cancelToken: cancelToken.token }
    );

    setCategory(result.data);
  };

  useEffect(() => {
    getCategory(sc);
    setSelectCategory(scValue || "All");
  }, [sc, scValue]);

  useEffect(() => {
    let categories = [{ option: "All", label: "All Group Select" }];
    if (category?.length) {
      categories = [
        ...categories,
        ...category.map((vendor) => ({
          option: vendor._id,
          label: `${vendor?.name} - ${vendor.code}`,
        })),
      ];
    }
    setOptions(categories);
  }, [category]);

  const handleChange = (selectedOption) => {
    setSelectCategory(selectedOption.option);
    handleOnChangeCategory(selectedOption);
  };

  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        value={options.find((opt) => opt.option === selectCategory)}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isSearchable={true}
        onChange={handleChange}
        name={name}
        options={options}
      />
    </Fragment>
  );
};

export default CategorySelectByMC;
