import React, { useEffect } from "react";
import * as Icons from "heroicons-react";
import logo from "../../../logo.png";
import { Button, Card, Form, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { notify } from "../../Utility/Notify";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useLoginUserMutation } from '../../../services/userApi';

const Login = () => {
  // const count = useSelector((state) => state.user);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const from = location?.state?.from.pathname || "/";


  const { register, handleSubmit } = useForm();

  const [loginUser] = useLoginUserMutation();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessTokens");
    if (accessToken) {
      navigate("/", { replace: true });
      return;
    }
  }, []);

  const handleLogin = async (data: any) => {
    const response: any = await loginUser(data);
    // console.log(data)
    if (response) {
      // console.log(response)
      if (response?.error?.data?.status === false) {
        notify("Login Filed! User ID and Password is not matching", "error");
        console.log("Login Filed! User ID and Password is not matching");
        localStorage.clear();
      } else {
        console.log("Login Success");
        // console.log(response.data.access_token)
        // console.log(response.data)
        if (response?.data?.access_token) {
          localStorage.setItem(
            "accessTokens",
            JSON.stringify(response?.data?.access_token)
          );
          localStorage.setItem("user", JSON.stringify(response?.data?.user));
          notify("Login Success", "success");
          navigate(from, { replace: true });
        }
      }
    }
  };


  return (
    <div className="login">
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4 mt-5 pt-5">
            <div className="d-flex justify-content-center">
              <Card style={{ width: "18rem" }}>
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                  <p className="text-center">
                    <Image src={logo} height="30"></Image>
                  </p>
                  <Card.Title className="text-center">Pharmacy Login</Card.Title>
                  <hr></hr>
                  <Form onSubmit={handleSubmit(handleLogin)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email or User ID</Form.Label>
                      <Form.Control
                        {...register("email")}
                        type="text"
                        placeholder="Enter email OR User id"
                      />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        {...register("password")}
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Group>

                    <div className="d-grid gap-2">
                      <Button variant="dark" className='float-center' type="submit">
                        <Icons.LockOpenOutline size={20}></Icons.LockOpenOutline> Login
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default Login;
