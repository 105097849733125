import React, { useState } from 'react';
import CsvImporterPrice from '../Common/CsvImporter/CsvImporterPrice';
import Header from '../Common/Header/Header';
import SideBar from '../Common/SideBar/SideBar';
import {
    useAddPriceMutation,
    useUpdatePriceMutation,
} from "../../services/priceApi";
import { notify } from '../Utility/Notify';
import { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useUpdateProductMutation } from '../../services/productApi';
const ImportPrice = () => {
    const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
    const [csvData, setCsvData] = useState([]);
    const [updateProduct] = useUpdateProductMutation();

console.log(csvData)
    const [addPrice] = useAddPriceMutation();
    const [updatePrice] = useUpdatePriceMutation();
    const getPriceDat = async (id)=>{
        await axios.get(`${BASE_URL}product/price/${id}`).then(res=> {
            const priceList = res.data.priceList;
            return priceList;
        })
    }

    // ADD PRICE LIST TO PRODUCT
  const addToProduct = async (productId, priceData) => {
    // const priceData = priceData;
    // const createdMessage = priceData.message;
    // get priceData
    const oldPrice = getPriceDat(productId)
console.log(oldPrice)
    // get product price list
    // let oldPrice = ProductPriceList.data.priceList;

    // compare and update the price
    if (oldPrice === null) {
      const newPrice = [priceData];
      const updatePrice = { _id: productId, priceList: newPrice };
      // update product price
      const response = await updateProduct(updatePrice);
      console.log(updatePrice);
      console.log(newPrice);
      if (response) {
        console.log(response);
        notify("Price Created Successful!", "success");
      }
    } else {
        let rest 
        if(oldPrice?.lngth >0 ){
            let rest = oldPrice?.filter((price) => price !== priceData);
        }else{
            rest  =[];
        }
      const newPrice = [...rest, priceData];
      const updatePrice = { _id: productId, priceList: newPrice };
      // update product price
      console.log(updatePrice);
      console.log(newPrice);
      const response = await updateProduct(updatePrice);
      if (response) {
        console.log(response);
        // notify(createdMessage, "success")
        notify("Price Update Successful!", "success");
      }
    }
    // show notification
  };
    
    const handleImportPrice = async () => {
        csvData.map(item => {
            console.log(typeof(item?._id))
            if (item?._id) {
                updatePrice(item);
                notify("Price Updated Successful!", "success")
                console.log("update: ", item)
            } else {
                console.log("create: ", item)
                const productId = item.article_code;
                addPrice(item).then(async (res)=> {
                     const newPriceID = res.data.id
                     await addToProduct(productId,newPriceID)
                }).finally(
                )
                notify("Price Create Successful!", "success")

            }
        })
        console.log("Import Data")
    }

    let i = 1;
    return (
        <div>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-2">
                        <SideBar></SideBar>
                    </div>
                    <div className="col-md-10">
                        <Header title="Import Product Price"></Header>
                        <div className="row">
                            <div className="col-md-4 offset-md-4">
                                {/* <div className="d-flex flex-column justify-content-center min-vh-100  align-items-center"> */}
                                <div className="pt-5 pb-5">
                                    <CsvImporterPrice
                                        setCsvData={setCsvData}
                                        handleImportButton={handleImportPrice}
                                    ></CsvImporterPrice>
                                    {/* SPINNER */}
                                    {/* */}

                                    {/* </div> */}

                                </div>
                            </div>
                            <div className="col-md-12">
                                <table class="table table-striped ">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>_id</th>
                                            <th>Warehouse</th>
                                            <th>Supplier</th>
                                            <th>article_code</th>
                                            <th>tp</th>
                                            <th>mrp</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        { }
                                        {
                                            csvData ?

                                                csvData.map(item =>
                                                    <tr key={i++}>
                                                        <th>{i}</th>
                                                        <td>{item._id ? item._id : "- no id -"}</td>
                                                        <td>{item.warehouse}</td>
                                                        <td>{item.supplier}</td>
                                                        <td>{item.article_code}</td>
                                                        <td>{item?.tp} <small><b>BDT</b></small></td>
                                                        <td>{item?.mrp} <small><b>BDT</b></small></td>
                                                    </tr>
                                                ) :
                                                <div className="spinner-border text-danger" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Toaster position="bottom-right" />
                </div>
            </div>
        </div>
    );
};

export default ImportPrice;