// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-text{
    display: none;
}

td ul{
    /* display: flex;
    flex-direction: row;
    gap: 5px;
    padding:0px */
}


ul.pagination.pagination-md.justify-content-center {
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/Components/Product/Products.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI;;;iBAGa;AACjB;;;AAGA;IACI,gBAAgB;AACpB","sourcesContent":[".form-text{\n    display: none;\n}\n\ntd ul{\n    /* display: flex;\n    flex-direction: row;\n    gap: 5px;\n    padding:0px */\n}\n\n\nul.pagination.pagination-md.justify-content-center {\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
