import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import POS from "./Components/Pages/POS/POS";
// import POS2 from "./Components/Pages/POS2/POS";
import Home from "./Components/Pages/Home/Home";
import NotFound from "./Components/Pages/NotFound/NotFound";
import AddUser from "./Components/User/AddUser";
import User from "./Components/User/User";
import UpdateUser from "./Components/User/UpdateUser";
import Products from "./Components/Product/Products";
import UserDetails from "./Components/User/UserDetails";
import AddProduct from "./Components/Product/AddProduct";
import UpdateProducts from "./Components/Product/UpdateProducts";
import Sales from "./Components/Sale/Sales";
import PrintReceipt from "./Components/Common/PrintReceipt/PrintReceipt";
import Categories from "./Components/Category/Categories";
// import CsvImporter from "./Components/Common/CsvImporter/CsvImporter0";
// import Toast from "./Components/Common/Toast/Toast";
import Brand from "./Components/Brand/Brand";
// import Update from "./Components/Brand/UpateBrand";
import UpdateBrand from "./Components/Brand/UpdateBrand";
import Dashboard from "./Components/Pages/DashBoard/Dashboard";
// import { useEffect, useState } from "react";
// import KeyPress from "./Components/Common/KeyPress/KeyPress";
// import PrintTest from "./Components/Common/PrintReceipt/PrintTest";
import Login from "./Components/Pages/Login/Login";
import RequireAuth from "./Components/Utility/RequireAuth";
import RequireAdminAuth from "./Components/Utility/RequireAdminAuth";
import Customer from "./Components/Customer/Customer";
// import Search from "./Components/Common/ProductSearch/search";
import Purchase from "./Components/Purchase/Purchase";
import Inventory from "./Components/Inventory/Inventory";
import Supplier from "./Components/Supplier/Supplier";
import DataTable from "./Components/Common/DataTable/DataTable";
import { ProgressBar } from "react-bootstrap";
import CategorySales from "./Components/Sale/CategorySales";
import PurchaseCreate from "./Components/Purchase/PurchaseCreate";
// import UpdatePurchase from "./Components/Purchase/UpdatePurchase";
import GRN from "./Components/GRN/GRN";
import GRNCreate from "./Components/GRN/GRNCreate";
import InventorySession from "./Components/Inventory/InventorySession/InventorySession";
import Warehouse from "./Components/Warehouse/Warehouse";
import CreateSupplier from "./Components/Supplier/CreateSupplier";
import UpdateSupplier from "./Components/Supplier/UpdateSupplier";
// import SupplierSelectByProduct from "./Components/Common/CustomSelect/SupplierSelectByProduct";
import AddCategory from "./Components/Category/AddCategory";
import UpdateCategory from "./Components/Category/UpdateCategory";
import UpdateWarehouse from "./Components/Warehouse/UpdateWarehouse";
import UpdateCustomer from "./Components/Customer/UpdateCustomer";
import Unit from "./Components/Unit/Unit";
import UpdateUnit from "./Components/Unit/UpdateUnit";

import Price from "./Components/Price/Price";

// import SearchProduct from "./Components/Common/CustomSelect/SearchProduct";
// import SelectCustomer from "./Components/Common/CustomSelect/selectCustomer";
// import WareHouseDW from "./Components/Common/CustomSelect/WareHouseDW";
// import CategorySelectByMC from "./Components/Common/CustomSelect/categorySelectByMC";
// import SelectBrand from "./Components/Common/CustomSelect/selectBrand";
// import SelectUnit from "./Components/Common/CustomSelect/selectUnit";
import ImportPrice from "./Components/Price/ImportPrice";
import ImportProduct from "./Components/Product/ImportProduct";
// import PrintReceiptById from "./Components/Common/PrintReceipt/PrintReceiptById";
import Damage from "./Components/Damage/Damage";
import Rtv from "./Components/RTV/Rtv";
import CreateRtv from "./Components/RTV/CreateRtv";

import ExportSale from "./Components/Sale/ExportSale";
import { Helmet } from "react-helmet";
import ExportArticleSale from "./Components/Sale/ExportArticleSale";

// Junk Food Pos

import TakeAway from "./Components/Pages/TakeAway/TakeAway";
import Cogs from "./Components/Cogs/Cogs";
import DeleteSale from "./Components/Sale/DeleteSale";
import InventoryInit from "./Components/Inventory/InventoryInit";
import InventoryImport from "./Components/Inventory/InventoryImport";
import InventoryExport from "./Components/Inventory/InventorySession/InventoryExport";
import Exports from "./Components/Exports/Exports";
// import DamageCreate from "./Components/Damage/DamageCreate";
import Ecom from "./Components/Ecom/Pages/Ecom/Ecom";
import EcomSaleUpdate from "./Components/Ecom/Pages/EcomUpdate/EcomSaleUpdate";
import EcomSaleProcess from "./Components/Ecom/Pages/EcomUpdate/EcomSaleProcess";
import EcomSaleDeliver from "./Components/Ecom/Pages/EcomUpdate/EcomSaleDeliver";
import InventoryAdjust from "./Components/Inventory/InventoryAdjust";
import CategorySale from "./Components/Sale/CategorySale";
import SupplierProductSale from "./Components/Sale/SupplierProductSale";
import PurchaseUpdate from "./Components/Purchase/PurchaseUpdate";
import Tpn from "./Components/TPN/Tpn";
import CreateTpn from "./Components/TPN/CreateTpn";
import ReceiveTPN from "./Components/TPN/ReceiveTPN";
// import CreateTpnNew from "./Components/TPN/CreateTpnNew";
import AllCategorySale from "./Components/Sale/AllCategorySale";
import RequireAdminEcomAuth from "./Components/Utility/RequireAdminEcomAuth";
import RequireAdminAuthManager from "./Components/Utility/RequireAdminAuthManager";
import RequireAdminAuthSupervisor from "./Components/Utility/RequireAdminAuthSupervisor";
import UserProfileUpdate from "./Components/User/UserProfileUpdate";
import RequireOnlyAdminAuth from "./Components/Utility/RequireOnlyAdminAuth";
import SmsPage from "./Components/SMS/SmsPage";
import DamageCreate from "./Components/Damage/DamageCreate";
import Group from "./Components/Group/Group";
import Generic from "./Components/Generic/Generic";
import ImportGroup from "./Components/Group/Parts/ImportGroup";
import ImportGeneric from "./Components/Generic/parts/ImportGeneric";
import ImportBrand from "./Components/Brand/parts/ImportBrand";
import ImportSupplier from "./Components/Supplier/ImportSupplier";
import AccountHeads from "./Components/AccountHeads/AccountHeads";
import AddAccountHead from "./Components/AccountHeads/AddAccountHead";
import UpdateAccountHead from "./Components/AccountHeads/UpdateAccountHead";
import Accounts from "./Components/Accounts/Accounts";
import AddAfterSale from "./Components/Accounts/AddAfterSale";
import AddAccount from "./Components/Accounts/AddAccount";
import AddAccountExpense from "./Components/Accounts/AddAccountExpense";
import PopularProductDateWise from "./Components/Sale/PopularProductDateWise";
import PurchaseCreateSearch from "./Components/Purchase/PurchaseCreateSearch";
import ProductLedger from "./Components/Product/ProductLedger";
import PrintReceiptByIdNew from "./Components/Common/PrintReceipt/PrintReceiptByIdNew";
// import ProcessMovement from "./Components/Inventory/movement/parts/ProcessMovement";
import ProductMovement from "./Components/Inventory/movement/ProductMovement";
import SupplierLedger from "./Components/Supplier/supplierLedger";

function App() {
  return (
    // <Router basename="pos-client-type" />
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PHARMACY-POS</title>
      </Helmet>
      <div className="AppGlass">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={<UserProfileUpdate />} />

          <Route path="/table" element={<DataTable />} />
          <Route path="/progress" element={<ProgressBar />} />
          <Route
            path="/pos"
            element={
              <RequireAuth>
                <POS />
              </RequireAuth>
            }
          />
          {/* <Route
            path="/void"
            element={
              <RequireAuth>
                <VoidReturn />
              </RequireAuth>
            }
          /> */}
          <Route
            path="/inventory-session"
            element={
              <RequireAuth>
                <InventorySession />
              </RequireAuth>
            }
          />
          <Route
            path="/inventory-init"
            element={
              <RequireAuth>
                <InventoryInit />
              </RequireAuth>
            }
          />
          <Route
            path="/cogs"
            element={
              <RequireAdminAuthManager>
                <Cogs />
              </RequireAdminAuthManager>
            }
          />
          <Route
            path="/sms"
            element={
              <RequireAuth>
                <SmsPage />
              </RequireAuth>
            }
          />
          <Route
            path="/group"
            element={
              <RequireAuth>
                <Group />
              </RequireAuth>
            }
          />
          <Route
            path="/generic"
            element={
              <RequireAuth>
                <Generic />
              </RequireAuth>
            }
          />
          <Route
            path="/inventory-export"
            element={
              <RequireAdminAuth>
                <InventoryExport />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/inventory-import"
            element={
              <RequireAdminAuth>
                <InventoryImport />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/inventory-adjust"
            element={
              <RequireAdminAuth>
                <InventoryAdjust />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/delete"
            element={
              <RequireAdminAuth>
                <DeleteSale />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/user"
            element={
              <RequireAdminAuth>
                <User />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/user/:id"
            element={
              <RequireAuth>
                <UserDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/user/add"
            element={
              <RequireAdminAuth>
                <AddUser />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/user/update/:id"
            element={
              <RequireOnlyAdminAuth>
                <UpdateUser />
              </RequireOnlyAdminAuth>
            }
          />

          {/* account routes */}
          <Route
            path="/accountheads"
            element={
              <RequireOnlyAdminAuth>
                <AccountHeads />
              </RequireOnlyAdminAuth>
            }
          />
          <Route
            path="/addAccountHead"
            element={
              <RequireOnlyAdminAuth>
                <AddAccountHead />
              </RequireOnlyAdminAuth>
            }
          />
          <Route
            path="/accountheads/update/:id"
            element={
              <RequireOnlyAdminAuth>
                <UpdateAccountHead />
              </RequireOnlyAdminAuth>
            }
          />
          <Route
            path="/accounts"
            element={
              <RequireOnlyAdminAuth>
                <Accounts />
              </RequireOnlyAdminAuth>
            }
          />
          <Route
            path="/addAfterSale"
            element={
              <RequireOnlyAdminAuth>
                <AddAfterSale />
              </RequireOnlyAdminAuth>
            }
          />
          <Route
            path="/addAccount"
            element={
              <RequireOnlyAdminAuth>
                <AddAccount />
              </RequireOnlyAdminAuth>
            }
          />
          <Route
            path="/addAccountExpense"
            element={
              <RequireOnlyAdminAuth>
                <AddAccountExpense />
              </RequireOnlyAdminAuth>
            }
          />
          <Route
            path="/product"
            element={
              <RequireAdminAuthSupervisor>
                <Products />
              </RequireAdminAuthSupervisor>
            }
          />
          <Route
            path="/product/ledger/:productId"
            element={
              <RequireAdminAuthSupervisor>
                <ProductLedger />
              </RequireAdminAuthSupervisor>
            }
          />
          <Route
            path="/price/import"
            element={
              <RequireAdminAuth>
                <ImportPrice />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/product/import"
            element={
              <RequireAdminAuth>
                <ImportProduct />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/group/import"
            element={
              <RequireAdminAuth>
                <ImportGroup />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/generic/import"
            element={
              <RequireAdminAuth>
                <ImportGeneric />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/brand/import"
            element={
              <RequireAdminAuth>
                <ImportBrand />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/supplier/import"
            element={
              <RequireAdminAuth>
                <ImportSupplier />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/product/add"
            element={
              <RequireAdminAuth>
                <AddProduct />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/product/update/:id"
            element={
              <RequireAdminAuth>
                <UpdateProducts />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/ecom"
            element={
              <RequireAdminEcomAuth>
                <Ecom />
              </RequireAdminEcomAuth>
            }
          />
          <Route
            path="/ecom/orderinfoupdate/:id"
            element={
              <RequireAdminAuth>
                <EcomSaleUpdate />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/ecom/orderinfoprocess/:id"
            element={
              <RequireAdminAuth>
                <EcomSaleProcess />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/ecom/orderinfodeliver/:id"
            element={
              <RequireAdminAuth>
                <EcomSaleDeliver />
              </RequireAdminAuth>
            }
          />

          <Route
            path="/price/add"
            element={
              <RequireAdminAuth>
                <Price />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/sales"
            element={
              <RequireAdminAuth>
                <Sales />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/category-sales"
            element={
              <RequireAdminAuth>
                <CategorySales />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/category"
            element={
              <RequireAdminAuthSupervisor>
                <Categories />
              </RequireAdminAuthSupervisor>
            }
          />
          <Route
            path="/category/addCategory"
            element={
              <RequireAdminAuth>
                <AddCategory />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/category/update/:id"
            element={
              <RequireAdminAuth>
                <UpdateCategory />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/brand"
            element={
              <RequireAdminAuthSupervisor>
                <Brand />
              </RequireAdminAuthSupervisor>
            }
          />

          <Route
            path="/brand/update/:id"
            element={
              <RequireAdminAuth>
                <UpdateBrand />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/unit"
            element={
              <RequireAdminAuthSupervisor>
                <Unit />
              </RequireAdminAuthSupervisor>
            }
          />

          <Route
            path="/unit/update/:id"
            element={
              <RequireAdminAuth>
                <UpdateUnit />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/create-supplier"
            element={
              <RequireAdminAuth>
                <CreateSupplier />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/update-supplier/:id"
            element={
              <RequireAdminAuth>
                <UpdateSupplier />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/customer"
            element={
              <RequireAdminAuthSupervisor>
                <Customer />
              </RequireAdminAuthSupervisor>
            }
          />

          <Route
            path="/customer/update/:id"
            element={
              <RequireAdminAuth>
                <UpdateCustomer />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/supplier"
            element={
              <RequireAdminAuth>
                <Supplier />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/supplier-ledger/:id"
            element={
              <RequireAdminAuth>
                <SupplierLedger />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/tpn"
            element={
              <RequireAdminAuth>
                <Tpn />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/tpn-out"
            element={
              <RequireAdminAuth>
                <CreateTpn />
              </RequireAdminAuth>
            }
          />
          {/* <Route
            path="/tpn-out"
            element={
              <RequireAdminAuth>
                <CreateTpnNew />
              </RequireAdminAuth>
            }
          /> */}
          <Route
            path="/tpn-received"
            element={
              <RequireAdminAuth>
                <ReceiveTPN />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/purchase"
            element={
              <RequireAdminAuth>
                <Purchase />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/purchase-create"
            element={
              <RequireAdminAuth>
                <PurchaseCreate />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/purchase-createSearch"
            element={
              <RequireAdminAuth>
                <PurchaseCreateSearch />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/purchase-update/:id"
            element={
              <RequireAdminAuth>
                <PurchaseUpdate />
              </RequireAdminAuth>
            }
          />
          {/* 
          <Route
            path="/update-purchase/:id"
            element={
              <RequireAdminAuth>
                <UpdatePurchase />
              </RequireAdminAuth>
            }
          /> */}
          <Route
            path="/grn"
            element={
              <RequireAdminAuth>
                <GRN />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/grn-create"
            element={
              <RequireAdminAuth>
                <GRNCreate />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/inventory"
            element={
              <RequireAdminAuth>
                <Inventory />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/inventory-movement"
            element={
              <RequireAdminAuth>
                <ProductMovement />
              </RequireAdminAuth>
            }
          />

          <Route
            path="/exports"
            element={
              <RequireAdminAuth>
                <Exports />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/purchase-create"
            element={
              <RequireAdminAuth>
                <PurchaseCreate />
              </RequireAdminAuth>
            }
          />

          {/* <Route path="/purchase-create" element={<PurchaseCreate />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/sale" element={<PrintReceipt />} />
          {/* <Route
            path="/print"
            element={<PrintReceipt invoiceId="62757c6dbc14617b6facc688" />}
          /> */}
          <Route path="/print/:id" element={<PrintReceiptByIdNew />} />
          {/* <Route path="/print/:id" element={<PrintReceiptById />} /> */}
          <Route path="/warehouse" element={<Warehouse />} />
          <Route
            path="/warehouse/update/:id"
            element={
              <RequireAdminAuth>
                <UpdateWarehouse />
              </RequireAdminAuth>
            }
          />

          {/* Damage router */}
          <Route
            path="/damage"
            element={
              <RequireAdminAuth>
                <Damage />
              </RequireAdminAuth>
            }
          />

          <Route
            path="/damagecreate"
            element={
              <RequireAdminAuth>
                <DamageCreate />
              </RequireAdminAuth>
            }
          />

          {/* Rtv router */}
          <Route
            path="/rtv-create"
            element={
              <RequireAdminAuth>
                <CreateRtv />
              </RequireAdminAuth>
            }
          />

          <Route
            path="/rtv"
            element={
              <RequireAdminAuth>
                <Rtv />
              </RequireAdminAuth>
            }
          />

          <Route
            path="/categorySaleExport"
            element={
              <RequireAdminAuth>
                <CategorySale />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/supplierSaleExport"
            element={
              <RequireAdminAuth>
                <SupplierProductSale />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/popular"
            element={
              <RequireAdminAuth>
                <PopularProductDateWise />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/saleExport"
            element={
              <RequireAdminAuth>
                <ExportSale />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/articleSaleExport"
            element={
              <RequireAdminAuth>
                <ExportArticleSale />
              </RequireAdminAuth>
            }
          />
          <Route
            path="/allCategorySales"
            element={
              <RequireAdminAuth>
                <AllCategorySale />
              </RequireAdminAuth>
            }
          />

          <Route
            path="/takeAway"
            element={
              <RequireAdminAuth>
                <TakeAway />
              </RequireAdminAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
