import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import DatePicker from "react-datepicker";
import { Table } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";
import CategorySelectByMC from "../Common/CustomSelect/categorySelectByMC";
import { useSaleExportByDateAndCatQuery } from "../../services/saleApi";

const CategorySale = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [sales, setSales] = useState([]);
  const [exportCSV, setExportCSV] = useState([]);
  const [scValue, setScValue] = useState("");
  const [cat, setCat] = useState("All");
  const [catName, setCatName] = useState("");

  // Custom hook for fetching sales data based on date range and category
  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useSaleExportByDateAndCatQuery({
      startDate,
      endDate,
      cat,
    });

  // Refetch data when startDate, endDate, or category changes
  useEffect(() => {
    refetch();
  }, [startDate, endDate, cat, refetch]);

  // Set sales data once successfully fetched
  useEffect(() => {
    if (isSuccess) {
      setSales(data || []);
    }
  }, [isSuccess, data]);

  // Format sales data for CSV export
  useEffect(() => {
    let saleData = [];
    sales?.map((sale) => {
      saleData = [
        ...saleData,
        {
          article_code: sale.article_code,
          name: sale.name,
          tp: sale.tp,
          mrp: sale.mrp,
          totalQuantity: sale.totalQuantity,
          total: sale.total,
        },
      ];
    });
    setExportCSV(saleData);
  }, [sales]);

  // CSV headers configuration
  const headers = [
    { label: "article_code", key: "article_code" },
    { label: "name", key: "name" },
    { label: "tp", key: "tp" },
    { label: "mrp", key: "mrp" },
    { label: "totalQuantity", key: "totalQuantity" },
    { label: "total", key: "total" },
  ];

  // Handle category selection
  const handleOnchangeCategory = (e) => {
    setScValue(e.option);
    setCat(e.option);
    setCatName(e.label);
  };

  // Calculate totals (TP, MRP, Total, Total Quantity)
  const totalTP = sales
    ?.reduce((sum, sale) => sum + (parseFloat(sale.tp) || 0), 0)
    .toFixed(2);
  const totalMRP = sales
    ?.reduce((sum, sale) => sum + (parseFloat(sale.mrp) || 0), 0)
    .toFixed(2);
  const totalQuantity = sales?.reduce(
    (sum, sale) => sum + (parseInt(sale.totalQuantity) || 0),
    0
  );
  const total = sales
    ?.reduce((sum, sale) => sum + (parseFloat(sale.total) || 0), 0)
    .toFixed(2);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PHARMACY-POS-SALE-EXPORT-CATEGORY-WISE</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar />
          </div>
          <div className="col-md-10">
            <Header title="Export Sales CategoryWise" />
            <div className="row py-4">
              <div className="d-flex justify-content-between align-items-center">
                {/* Left-side date range and category filter */}
                <div className="d-flex align-items-center gap-3">
                  {/* Start Date */}
                  <div className="d-flex align-items-center">
                    <label className="me-2">Start Date:</label>
                    <DatePicker
                      selected={new Date(startDate)}
                      className="form-control"
                      onChange={(date) =>
                        setStartDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                  </div>

                  {/* End Date */}
                  <div className="d-flex align-items-center">
                    <label className="me-2">End Date:</label>
                    <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                  </div>

                  {/* Category filter */}
                  <div>
                    <CategorySelectByMC
                      scValue={scValue}
                      handleOnChangeCategory={handleOnchangeCategory}
                    />
                  </div>
                </div>

                {/* Right-side export button */}
                <div>
                  <CSVLink
                    className="btn btn-dark"
                    data={exportCSV}
                    asyncOnClick={true}
                    headers={headers}
                    filename={`categoryWiseSale-${startDate}to${endDate}-${catName}`}
                  >
                    {exportCSV.length === 0
                      ? "Loading CSV..."
                      : "Export Sales Report"}
                  </CSVLink>
                </div>
              </div>
            </div>

            {/* Sales Table */}
            <div className="row">
              <div className="col-md-12">
                <Table hover className="mt-4">
                  <thead>
                    <tr>
                      <th>Article Code</th>
                      <th>Name</th>
                      <th>TP</th>
                      <th>MRP</th>
                      <th>Total Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sales ? (
                      sales.length > 0 ? (
                        sales.map((sale) => (
                          <tr key={sale._id}>
                            <td>{sale.article_code}</td>
                            <td>{sale.name}</td>
                            <td>{sale.tp}</td>
                            <td>{sale.mrp}</td>
                            <td>{sale.totalQuantity}</td>
                            <td>{sale.total?.toFixed(2)}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={6}
                            className="text-center pe-20"
                            style={{ height: "50vh" }}
                          >
                            No products found
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan={6}>Please Select a Category...</td>
                      </tr>
                    )}
                  </tbody>
                  {/* Totals Row */}
                  <tfoot>
                    <tr className="bg-dark text-light">
                      <td></td>
                      <td className="py-3">
                        <strong>Total</strong>
                      </td>
                      <td className="py-3">
                        <strong>{totalTP}</strong>
                      </td>
                      <td className="py-3">
                        <strong>{totalMRP}</strong>
                      </td>
                      <td className="py-3">
                        <strong>{totalQuantity}</strong>
                      </td>
                      <td className="py-3">
                        <strong>{total}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorySale;
