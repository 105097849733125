import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
import "./Customer.css";
import * as Icons from "heroicons-react";
import { useForm } from "react-hook-form";
import CsvImporter from "../Common/CsvImporter/CsvImporter";
import toast, { Toaster } from "react-hot-toast";
import SideBar from "../Common/SideBar/SideBar";
import {
  useAddCustomerMutation,
  useCustomerQuery,
  useCustomerPagenationQuery,
  useCustomersQuery,
  useDeleteCustomerMutation,
  useCustomerCountQuery,
  useCustomersExportQuery,
} from "../../services/customerApi";
import { apiUniqueErrHandle } from "../Utility/Utility";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { notify } from "../Utility/Notify";
import CustomerExportModal from "../Common/Modal/CustomerExportModal";
import LoadingModal from "../Common/Modal/LoadingModal";
import { v4 as uuidv4 } from 'uuid';

const Customer = () => {
  let navigate = useNavigate();
  const id = uuidv4();
  const [Addcustomer] = useAddCustomerMutation();
  const [deleteCustomer] = useDeleteCustomerMutation();
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");
  const CustomersView = useCustomersQuery();

  const [loader, setLoader] = useState(true)
  const handleLoaderClose = () => setLoader(false);

  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useCustomerPagenationQuery({
      page,
      size,
      q,
    });
  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data]);
  // console.log(data);
  // const [Datacustomer] = useCustomerQuery(`${_id}`);
  const [onShowCustomer, setOnShowCustomer] = useState(false);
  const handleCloseCustomer = () => setOnShowCustomer(false);

  const { data: customer, isSuccess: isSuccessCustomer } = useCustomersExportQuery();

  const { register, handleSubmit, reset } = useForm({});
  let i = 1;
  const onSubmit = async (customer) => {
    console.log("customer", customer)
    let newCustomer = {}
    let email = customer?.email;
    let trimEmail = email.trim()
    if (trimEmail?.length > 0) {

      newCustomer = {
        name: customer?.name,
        password: customer?.password,
        email: customer?.email,
        phone: customer?.phone,
        username: customer?.username,
        membership: customer?.membership,
        address: {
          type: 'Home',
          id: id,
          holdingNo: customer?.holdingNo,
          sector: customer?.sector,
          street: customer?.road,
          town: customer?.town,
          city: customer?.city,
          division: customer?.division,
          country: customer?.country,
          zipCode: customer?.zipCode
        },
        point: customer?.point ? customer?.point : 0,
        type: customer?.type,
        status: customer?.status,

      }
      console.log("newCustomer", newCustomer)
      setLoader(true)
    }
    else {
      newCustomer = {
        name: customer?.name,
        password: customer?.password,
        phone: customer?.phone,
        username: customer?.username,
        membership: customer?.membership,
        address: {
          type: 'Home',
          id: id,
          holdingNo: customer?.holdingNo,
          sector: customer?.sector,
          street: customer?.road,
          town: customer?.town,
          city: customer?.city,
          division: customer?.division,
          country: customer?.country,
          zipCode: customer?.zipCode
        },
        point: customer?.point ? customer?.point : 0,
        type: customer?.type,
        status: customer?.status,

      }
      console.log("newCustomer", newCustomer)
      setLoader(true)
    }
    try {
      // console.log(data);
      const response = await Addcustomer(newCustomer);
      if (response) {
        console.log(response);
        if (response?.error) {
          apiUniqueErrHandle(response);
        } else {
          reset({
            name: "",
            username: "",
            password: "",
            // membership: "",
            // address: "",
            // holdingNo: "",
            // sector: "",
            // street: "",
            // town: "",
            // city: "",
            // division: "",
            // country: "",
            // zipCode: "",
            phone: "",
            email: "",
            type: "",
            status: "active",
          });
          notify("Customer Create Successful!", "success");

          console.log(response?.data?.message);
          return navigate("/customer");
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }
  };

  const deleteHandler = async (id) => {
    setLoader(true)
    try {
      const confirm = window.confirm("Are you Sure? Delete this Customer?");
      if (confirm) {
        const res = await deleteCustomer(id);
        if (res) {
          // TODO::
          // add error hendaler for delete error
          console.log(res);
        } else {
          console.log("Delete Operation Canceled by Customer!");
          return;
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }
  };

  const handleReset = () => {
    reset({
      name: "",
      phone: "",
      address: "",
    });
  };

  // get totel product count
  const pageCountQuery = useCustomerCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    setPageNo(getPageNumber);
    refetch();
  };

  // console.log(pageCount, size);
  const getPageNumber = () => {
    const cont = Math.ceil(parseInt(pageCount) / parseInt(size));
  };

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };

  const handlePageClick = (data) => {
    console.log(data)
    setPage(parseInt(data.selected));
    setPageNo(getPageNumber);
    refetch();
  };
  const handleCustomerExport = () => {
    setOnShowCustomer(true);
    console.log(onShowCustomer)
    console.log(customer)
  }

  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Customer"></Header>
            <div className="row">
              <div>
                <Button
                  className="btn btn-dark mt-2 float-end me-2"
                  onClick={() => handleCustomerExport()}
                >
                  Export Customer Details
                </Button>
              </div>
              <div className="col-md-4 ">
                <div className="">
                  <div className="card mt-5">
                    <div className="card-header">
                      <h5 className="card-title">Create Customer</h5>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputCustomer"> Name</label>
                            <input
                              {...register("name", { required: true })}
                              type="text"
                              className="form-control"
                              id="inputCustomer"
                              aria-describedby="emailHelp"
                              placeholder="Name"
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              We'll never share your email with anyone else.
                            </small>
                          </div>
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputMC">Username</label>
                            <input
                              {...register("username")}
                              type="text"
                              className="form-control"
                              id="username"
                              placeholder="username"
                            />
                          </div>
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputMC">Phone</label>
                            <input
                              {...register("phone")}
                              type="text"
                              className="form-control"
                              id="phone"
                              placeholder="Phone"
                            />
                          </div>
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputMC">Email</label>
                            <input
                              {...register("email")}
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder=" email"
                            />
                          </div>
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputMC">Member Ship</label>

                            <select
                              {...register("membership")}
                              className="form-select"
                              id="status"
                            >
                              <option value="gold" selected>
                                Gold
                              </option>
                              <option value="diamond">Diamond</option>
                              <option value="premium">Premium</option>
                            </select>
                          </div>

                          {/* <div className="form-group col-12  mb-3">
                            <label htmlFor="MCId">Address</label>
                            <textarea
                              {...register("address")}
                              className="form-control"
                              id="address"
                              placeholder="Address"
                            />
                          </div> */}
                          <div className="form-group col-6  mb-3">
                            <label htmlFor="MCId">Holding Number</label>
                            <input
                              {...register("holdingNo")}
                              type="text"
                              className="form-control"
                              id="holdingNo"
                              placeholder="Holding Number"
                            />
                          </div>
                          <div className="form-group col-6  mb-3">
                            <label htmlFor="MCId">Road</label>
                            <input
                              {...register("road")}
                              type="text"
                              className="form-control"
                              id="road"
                              placeholder="Road"
                            />
                          </div>
                          <div className="form-group col-6  mb-3">
                            <label htmlFor="MCId">Sector</label>
                            <select {...register("sector")}
                              className="form-control"
                            >
                              <option value="1">01</option>
                              <option value="2">02</option>
                              <option value="3">03</option>
                              <option value="4">04</option>
                              <option value="5">05</option>
                              <option value="6">06</option>
                              <option selected value="7">07</option>
                              <option value="8">08</option>
                              <option value="9">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                            </select>
                          </div>
                          <div className="form-group col-6  mb-3">
                            <label htmlFor="MCId">Town</label>
                            <select {...register("town")}
                              className="form-control"
                            >
                              <option selected value="Uttara">Uttara</option>
                            </select>
                          </div>
                          <div className="form-group col-6  mb-3">
                            <label htmlFor="MCId">City</label>
                            <select {...register("city")}
                              className="form-control"
                            >
                              <option selected value="Dhaka">Dhaka</option>
                            </select>
                          </div>
                          <div className="form-group col-6  mb-3">
                            <label htmlFor="MCId">Division</label>
                            <select {...register("division")}
                              className="form-control"
                            >
                              <option selected value="Dhaka">Dhaka</option>
                            </select>
                          </div>
                          <div className="form-group col-6  mb-3">
                            <label htmlFor="MCId">Zip Code</label>
                            <select {...register("zipCode")}
                              className="form-control"
                            >
                              <option selected value="1230">1230</option>
                            </select>
                          </div>
                          <div className="form-group col-6  mb-3">
                            <label htmlFor="MCId">Country</label>
                            <select {...register("country")}
                              className="form-control"
                            >
                              <option selected value="BD">Bangladesh</option>
                            </select>
                          </div>
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputMC">Type</label>
                            <select
                              {...register("type")}
                              className="form-select"
                              id="status"
                            >
                              <option value="regular" selected>
                                Regular
                              </option>
                              <option value="premium">Premium</option>
                              <option value="vip">VIP</option>
                            </select>
                          </div>
                          {/* <input
                              {...register("type")}
                              type="text"
                              className="form-control"
                              id="password"
                              placeholder="type"
                            />
                          </div> */}

                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputMC">status</label>
                            <select
                              {...register("status")}
                              className="form-control"
                              id="status"
                              placeholder="status"
                            >
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                            </select>
                          </div>
                        </div>
                        <button
                          type="reset"
                          onClick={handleReset}
                          className="btn btn-outline-dark col-4 col-md-4"
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-dark col-8 col-md-8"
                        >
                          {data?._id ? (
                            <>
                              <Icons.SaveOutline></Icons.SaveOutline>
                            </>
                          ) : (
                            <>
                              <Icons.Plus> </Icons.Plus>
                            </>
                          )}
                          Customer
                        </button>
                      </form>
                    </div>
                  </div>
                  {/* <CsvImporter setCsvData={setCsvData} handleImportButton={handleImportButton} title="Customer"></CsvImporter> */}
                </div>
              </div>
              <div className="col-md-8">
                <form>
                  <div className="input-group mb-3 mt-5">
                    <select
                      className="form-select"
                      onChange={(e) => handleDataLimit(e)}
                    >
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                    <input
                      className="form-control"
                      type="text"
                      placeholder='search'
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    {/* <input type="text" className="form-control" aria-label="Text input with dropdown button"> */}
                  </div>
                </form>

                <nav aria-label="Page navigation example">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    //dynamic page count
                    // page count total product / size
                    pageCount={Math.ceil(parseInt(pageCount) / parseInt(size))}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={6}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pt-0 pb-2"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                  ></ReactPaginate>
                </nav>
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Address</th>
                      <th scope="col">ActionBtn</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((Customer) => (
                        <tr key={Customer._id}>
                          <th scope="row">{i++}</th>
                          <td>{Customer.name}</td>
                          <td>
                            {Customer.phone ? Customer.phone : "No Phone"}
                          </td>
                          <td>{Customer.address}</td>
                          <td>
                            <Link to={`/customer/update/${Customer._id}`}>
                              <Icons.PencilAltOutline
                                className="icon-edit"
                                size={20}
                              ></Icons.PencilAltOutline>
                            </Link>
                            <Icons.TrashOutline
                              className="icon-trash"
                              onClick={() => deleteHandler(Customer._id)}
                              size={20}
                            ></Icons.TrashOutline>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9}> No Customer Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
      <CustomerExportModal
        onShow={onShowCustomer}
        handleClose={handleCloseCustomer}
        exportCustomer={customer}
      ></CustomerExportModal>
    </div>
  );
};

export default Customer;
