import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Product } from "../models/product.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

console.log(BASE_URL);

export const SearchApi = createApi({
  reducerPath: "SearchApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    productSearch: builder.query<Product[], any>({
      query: ({q}) => `/product/search/${q}`,
      providesTags: ["Product"],
    }),
    // ecomSearch: builder.query<Product[], String>({
    //   query: (q) => `/product/search/${q}`,
    //   providesTags: ["Product"],
    // }),
  }),
});

export const { useProductSearchQuery } = SearchApi;

export default SearchApi;
