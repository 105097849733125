import React, { useState } from 'react';
import Header from '../Common/Header/Header';
import SideBar from '../Common/SideBar/SideBar';
import { notify } from '../Utility/Notify';
import { Toaster } from 'react-hot-toast';
import CsvImporterProduct from '../Common/CsvImporter/CsvImporterProduct';
import { useAddProductMutation } from '../../services/productApi';
const ImportProduct = () => {
    const [csvData, setCsvData] = useState([]);

    console.log('csvData', csvData)
    const [addProduct] = useAddProductMutation();
    // const [updatePrice] = useUpdatePriceMutation();

    const handleImportProduct = () => {
        csvData.map(item => {
            console.log("create: ", item)
            addProduct(item).then(res => {
                console.log(res)
                notify(`Product ${item.name} Create Successful!`, "success")
            }).catch(err => {
                notify(`Product ${err} Create Successful!`, "success")
            });
        })
        // setCsvData([])
        // console.log(csvData)
    }

    let i = 1;
    return (
        <div>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-2">
                        <SideBar></SideBar>
                    </div>
                    <div className="col-md-10">
                        <Header title="Import Product"></Header>
                        <div className="row">
                            <div className="col-md-4 offset-md-4">
                                {/* <div className="d-flex flex-column justify-content-center min-vh-100  align-items-center"> */}
                                <div className="pt-5 pb-5">
                                    <CsvImporterProduct
                                        setCsvData={setCsvData}
                                        handleImportButton={handleImportProduct}
                                    ></CsvImporterProduct>
                                    {/* SPINNER */}
                                    {/* */}

                                    {/* </div> */}

                                </div>
                            </div>
                            <div className="col-md-12">
                                <table class="table table-striped ">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>name</th>
                                            {/* <th>product_type</th> */}
                                            <th>article_code</th>
                                            {/* <th>shipping method</th> */}
                                            <th>group</th>
                                            <th>generic</th>
                                            <th>brand</th>
                                            <th>size</th>
                                            <th>pcsBox</th>
                                            <th>tp</th>
                                            <th>mrp</th>
                                            <th>profit</th>
                                            <th>vat</th>
                                            <th>unit</th>
                                            <th>type</th>
                                            <th>status</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            csvData ?
                                                csvData.map(item =>
                                                    <tr key={i++}>
                                                        <th>{i}</th>
                                                        <td>{item.name}</td>
                                                        {/* <td>{item.product_type}</td> */}
                                                        <td>{item.article_code}</td>
                                                        {/* <td>{item.shipping_method}</td> */}
                                                        <td>{item?.group}</td>
                                                        <td>{item?.generic}</td>
                                                        <td>{item?.brand}</td>
                                                        <td>{item?.size}</td>
                                                        <td>{item?.pcsBox}</td>
                                                        <td>{item?.tp}</td>
                                                        <td>{item?.mrp}</td>
                                                        <td>{item?.profit}</td>
                                                        <td>{item?.vat}</td>
                                                        <td>{item?.unit}</td>
                                                        <td>{item?.type}</td>
                                                        <td>{item?.status}</td>
                                                    </tr>
                                                ) :
                                                <div className="spinner-border text-danger" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Toaster position="bottom-right" />
                </div>
            </div>
        </div>
    );
};

export default ImportProduct;