import * as Icons from "heroicons-react";
import { Accordion, Image, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../logo.png";
import "./SideBar.css";

const SupervisorSideBar = () => {
    let active = window.location.pathname;
    return (
        <aside className="sticky-md-top" >
            <Link className="nav-link logo pt-3 pb-5" to="/">
                <Image src={logo} fluid />
            </Link>
            <nav className="nav flex-column">
                {/* <Link className='nav-link' to="/"><Icons.HomeOutline size={22}></Icons.HomeOutline> Home</Link> */}
                <>
                    <Link
                        className={`nav-link ${active === "/dashboard" && "active"}`}
                        to="/dashboard"
                    >
                        <Icons.AdjustmentsOutline size={22}></Icons.AdjustmentsOutline>{" "}
                        Dashboard
                    </Link>
                    <Link className={`nav-link ${active === "/pos" && "active"}`} to="/pos">
                        <Icons.ShoppingBagOutline size={22}></Icons.ShoppingBagOutline> POS
                    </Link>
                    {/* <Link
                        className={`nav-link ${active === "/sales" && "active"}`}
                        to="/sales"
                    >
                        <Icons.ShoppingCartOutline size={22}></Icons.ShoppingCartOutline>{" "}
                        Sales
                    </Link> */}
                    {/* <Link
                        className={`nav-link ${active === "/ecom" && "active"}`}
                        to="/ecom"
                    >
                        <Icons.ShoppingCartOutline size={22}></Icons.ShoppingCartOutline>{" "}
                        Ecom Sales
                    </Link> */}
                    {/* <Link
                        className={`nav-link ${active === "/user" && "active"}`}
                        to="/user"
                    >
                        <Icons.UserOutline size={22}></Icons.UserOutline> Users
                    </Link> */}
                    {/* <Link className={`nav-link ${active === '/user/ && 'active'add'}`} to="/user/add"><Icons.UserAddOutline size={22}></Icons.UserAddOutline> Create User</Link> */}
                    <Link
                        className={`nav-link ${active === "/product" && "active"}`}
                        to="/product"
                    >
                        <Icons.ArchiveOutline size={22}></Icons.ArchiveOutline> Products
                    </Link>
                    <Link
                        className={`nav-link ${active === "/brand" && "active"}`}
                        to="/brand"
                    >
                        <Icons.ArchiveOutline size={22}></Icons.ArchiveOutline> Brand
                    </Link>
                    <Link
                        className={`nav-link ${active === "/unit" && "active"}`}
                        to="/unit"
                    >
                        <Icons.ArchiveOutline size={22}></Icons.ArchiveOutline> Unit
                    </Link>
                    {/* <Link
                        className={`nav-link ${active === "/category" && "active"}`}
                        to="/category"
                    >
                        <Icons.CubeTransparentOutline
                            size={22}
                        ></Icons.CubeTransparentOutline>{" "}
                        Category
                    </Link> */}
                    <Link
                        className={`nav-link ${active === "/customer" && "active"}`}
                        to="/customer"
                    >
                        <Icons.UsersOutline size={22}></Icons.UsersOutline> Customer
                    </Link>
                    {/* <Link
                        className={`nav-link ${active === "/warehouse" && "active"}`}
                        to="/warehouse"
                    >
                        <Icons.HomeOutline size={22}></Icons.HomeOutline> Warehouse
                    </Link> */}
                    {/* <Link
                        className={`nav-link ${active === "/tpn" && "active"}`}
                        to="/tpn"
                    >
                        <Icons.HomeOutline size={22}></Icons.HomeOutline> TPN
                    </Link> */}
                    {/* <Link
                        className={`nav-link ${active === "/damage" && "active"}`}
                        to="/damage"
                    >
                        <Icons.HomeOutline size={22}></Icons.HomeOutline> Damage
                    </Link> */}
                    {/* <Link
                        className={`nav-link ${active === "/supplier" && "active"}`}
                        to="/supplier"
                    >
                        <Icons.UsersOutline size={22}></Icons.UsersOutline> Supplier
                    </Link>
                    <Link
                        className={`nav-link ${active === "/purchase" && "active"}`}
                        to="/purchase"
                    >
                        <Icons.CurrencyDollarOutline size={22}></Icons.CurrencyDollarOutline>{" "}
                        Purchase
                    </Link>
                    <Link className={`nav-link ${active === "/grn" && "active"}`} to="/grn">
                        <Icons.ArchiveOutline size={22}></Icons.ArchiveOutline> GRN
                    </Link> */}

                    {/* <Link className={`nav-link ${active === "/rtv" && "active"}`} to="/rtv">
                        <Icons.ArchiveOutline size={22}></Icons.ArchiveOutline>RTV
                    </Link>

                    <Link
                        className={`nav-link ${active === "/inventory" && "active"}`}
                        to="/inventory"
                    >
                        <Icons.ViewGridAddOutline size={22}></Icons.ViewGridAddOutline>{" "}
                        Inventory
                    </Link>
                    <Link
                        className={`nav-link ${active === "/cogs" && "active"}`}
                        to="/cogs"
                    >
                        <Icons.ViewListOutline size={22}></Icons.ViewListOutline> COGS
                    </Link> */}
                    {/* <Link
                        className={`nav-link ${active === "/exports" && "active"}`}
                        to="/exports"
                    >
                        <Icons.ViewListOutline size={22}></Icons.ViewListOutline> Exports
                    </Link> */}
                </>
            </nav>
        </aside>
    );
};

export default SupervisorSideBar;