// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customFrom{
    text-align: center;
    width: 80%;
}

.customFromTax{
    /* width: 50%; */
    
}
/* 
tbody{height: 400px; overflow-y: scroll;display:block;}
th { position: sticky; top: 0; } */
/* tbody{
    height: 400px;
    overflow-y: scroll;
} */`, "",{"version":3,"sources":["webpack://./src/Components/Purchase/Purchase.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,gBAAgB;;AAEpB;AACA;;kCAEkC;AAClC;;;GAGG","sourcesContent":[".customFrom{\n    text-align: center;\n    width: 80%;\n}\n\n.customFromTax{\n    /* width: 50%; */\n    \n}\n/* \ntbody{height: 400px; overflow-y: scroll;display:block;}\nth { position: sticky; top: 0; } */\n/* tbody{\n    height: 400px;\n    overflow-y: scroll;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
