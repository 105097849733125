import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  useAddDamageMutation,
  useDamagesExportQuery,
  useDamagesQuery,
  useDeleteDamageMutation,
  useDamageByDateQuery,
} from "../../services/damageApi";
import Header from "../Common/Header/Header";
import DamageExportModal from "../Common/Modal/DamageExportModal";
import DamageViewModal from "../Common/Modal/DamageViewModal";
import SideBar from "../Common/SideBar/SideBar";
import useInventory from "../Hooks/useInventory";
import { notify } from "../Utility/Notify";
import { compareAsc, format } from "date-fns";
import { apiUniqueErrHandle } from "../Utility/Utility";
import * as Icons from "heroicons-react";
import LoadingModal from "../Common/Modal/LoadingModal";
import DatePicker from "react-datepicker";

const Damage = () => {
  const [warehouse, setWarehouse] = useState("");
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  const [selectedOption, setSelectedOption] = useState({});
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));

  const [damage, setDamage] = useState([]);
  const [totalDamage, setTotalDamage] = useState(0);

  const [onShow, setOnShow] = useState(false);
  const handleClose = () => setOnShow(false);

  const [loader, setLoader] = useState(true)
  const handleLoaderClose = () => setLoader(false);

  const [onShowDamage, setOnShowDamage] = useState(false);
  const handleCloseDamage = () => setOnShowDamage(false);

  const { updateInventoryInOnDamageDel, updateInventoryOutOnDamageIn } =
    useInventory();

  // const { data, isSuccess } = useDamagesQuery();
  // console.log(data)
  const { data, isSuccess } = useDamageByDateQuery({
    startDate,
    endDate
  });

  
  const { data: damageExport, isSuccess: damageExportIsSuccess } =
    useDamagesExportQuery();
  const [deleteDamage] = useDeleteDamageMutation();


  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data]);

  useEffect(() => {
    let total = 0;
    data?.map(purchase => {
      total = total + purchase?.total
    })
    setTotalDamage(total)
  }, [data]);

  const navigateToDamageCreate = () => {
    navigate(`/damagecreate`);
  };

  const handleDamageExport = () => {
    console.log(damageExport);
    setOnShowDamage(true);
  };

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    reset({
      userId: loggedInUser.id,
    });
  }, []);

  // submit function

  const { register, handleSubmit, reset, setValue } = useForm({});
  let i = 1;

  //   delete function

  const deleteHandler = async (id) => {
    try {
      let newIn = [];
      /// axios diye data from data get
      const result = await axios.get(`${BASE_URL}/damage/${id}`);
      console.log(result?.data);

      newIn = [
        ...newIn,
        {
          article_code: result?.data?.product?.article_code,
          qty: result?.data?.qty,
          priceId: result?.data?.priceId,
          name: result?.data?.product?.name,
        },
      ];

      const confirm = window.confirm("Are you Sure? Delete this Damage?");
      if (confirm) {
        setLoader(true)
        const res = await deleteDamage(id);
        if (res) {
          // TODO::
          const inventory = await updateInventoryInOnDamageDel(newIn);
          console.log(inventory);

          notify("Damage Delete successful", "success");
        } else {
          notify("Delete Operation Canceled!", "error");
          return;
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoader(false)
    }
  };

  //show damage details
  const damageDetailsHandler = async (id) => {
    console.log("damage id", id);
    const damage = await axios.get(`${BASE_URL}/damage/${id}`);
    console.log(damage.data);
    setDamage(damage.data);
    setOnShow(true);
  };

  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Damage"></Header>
            <div className="row">
              <div>
                <div>
                  <Button
                    className="btn btn-dark mb-2 float-end mt-2 me-2"
                    onClick={() => navigateToDamageCreate()}
                  >
                    Create Damage
                  </Button>
                </div>
                <div>
                  <Button
                    className="btn btn-dark mb-2 float-end mt-2 me-2"
                    onClick={() => handleDamageExport()}
                  >
                    Export Damage Details
                  </Button>
                </div>
              </div>
              <div className="col-md-12">
                <div className="col-md-6">

                  {/* Sort date range */}
                  <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                    {/* <b>Start:</b> */}
                    <DatePicker
                      selected={new Date(startDate)}
                      className="form-control me-2"
                      onChange={(date) =>
                        setStartDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                    <span width="10px"></span>
                    {/* <b>End:</b> */}
                    <DatePicker
                      selected={new Date(endDate)}
                      className="form-control"
                      onChange={(date) =>
                        setEndDate(format(new Date(date), "MM-dd-yyyy"))
                      }
                    />
                  </div>

                </div>
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Damage ID</th>
                      <th scope="col">Damage Date</th>
                      <th scope="col">Prepared By</th>
                      <th scope="col">Total items</th>
                      <th scope="col">Total</th>
                      <th scope="col">Reason</th>
                      {/* <th scope="col">warehouse</th> */}
                      <th scope="col">ActionBtn</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      console.log(data)
                    }
                    {data?.length > 0 ? (
                      data?.map((damage) => (
                        <tr key={damage._id}>
                          <th scope="row">{i++}</th>
                          <td>{damage?.damageNo}</td>
                          <td>{damage?.createdAt &&
                            format(new Date(damage.createdAt), "MM/dd/yyyy")}</td>
                          <td>{damage?.userId?.name}</td>
                          <td>{damage?.products?.length}</td>
                          <td>{damage?.total?.toFixed(2)}</td>
                          <td>{damage.note}</td>
                          {/* <td>{damage?.warehouse?.name}</td> */}
                          <td>
                            <Icons.EyeOutline
                              className="icon-eye"
                              onClick={() => damageDetailsHandler(damage._id)}
                              size={20}
                            ></Icons.EyeOutline>
                            <Icons.TrashOutline
                              className="icon-trash"
                              onClick={() => deleteHandler(damage._id)}
                              size={20}
                            ></Icons.TrashOutline>
                          </td>
                        </tr>
                      ))
                    ) : (

                      <tr colSpan={9}>No damage Found</tr>

                    )}{
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {/* <td></td> */}
                        {/* <td></td> */}
                        <td>Total :</td>
                        <td>{(totalDamage).toFixed(2)}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DamageExportModal
        onShow={onShowDamage}
        handleClose={handleCloseDamage}
        damageExport={damageExport}
      ></DamageExportModal>
      <DamageViewModal
        onShow={onShow}
        handleClose={handleClose}
        damage={damage}
      ></DamageViewModal>
      <Toaster position="bottom-right" />
    </div >
  );
};

export default Damage;
