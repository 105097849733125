// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterSelect{
    display: flex;
}
.filterSelect label{
    text-transform: uppercase;
    
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/DataTable/DataTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;;AAE7B","sourcesContent":[".filterSelect{\n    display: flex;\n}\n.filterSelect label{\n    text-transform: uppercase;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
