import React, { useEffect, useRef, useState } from "react";
// import { Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CategorySelectByMC from "../Common/CustomSelect/categorySelectByMC";
import SelectBrand from "../Common/CustomSelect/selectBrand";
// import SelectMC from "../Common/CustomSelect/selectMC";
import SelectUnit from "../Common/CustomSelect/selectUnit";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
// import productPhoto from "../../product.jpg";
import {
  useAddProductMutation,
  useProductLastQuery,
  // useProductQuery
} from "../../services/productApi";
import { apiUniqueErrHandle } from "../Utility/Utility";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingModal from "../Common/Modal/LoadingModal";
import { BsCheckSquare, BsPlusCircle, BsSquare } from "react-icons/bs";
import SelectGroup from "../Common/CustomSelect/selectGroup";
import SelectGeneric from "../Common/CustomSelect/selectGeneric";
// import SelectBrandNew from "../Common/CustomSelect/selectBrandNew";
import SelectCategory from "../Common/CustomSelect/selectCategory";
import { useDispatch, useSelector } from "react-redux";
import {
  alertQtyInput,
  discountInput,
  // inputMulti,
  inputName,
  inputProductCode,
  inputProductDetails,
  inputShippingMethod,
  maxQtyInput,
  minQtyInput,
  mrpInput,
  pcsBoxInput,
  productName,
  productNameSize,
  // productTypeInput,
  resetProductAdd,
  selectBrand,
  selectBrandName,
  selectCategory,
  selectDiscountType,
  selectGeneric,
  selectGroup,
  selectStatus,
  selectSubCategory,
  selectType,
  selectUnit,
  selectVatMethod,
  sizeInput,
  tpInput,
  vatInput,
} from "../../features/productAddSlice";
import GroupAddModal from "../Common/Modal/GroupAddModal";
import GenericAddModal from "../Common/Modal/GenericAddModal";
import BrandAddModal from "../Common/Modal/BrandAddModal";
// import { productNumber } from "../Utility/generateProductNumber";
import { compareAsc, format } from "date-fns";
import { notify } from "../Utility/Notify";
import axios from "axios";
import useKeypress from "react-use-keypress";

const AddProduct = () => {
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.productAddReducer);
  const { register, handleSubmit, reset } = useForm({});
  const [gp, setGp] = useState("");
  const [gn, setGn] = useState("");
  const [bn, setBn] = useState("");
  // const [scValue, setScValue] = useState("");
  // const [brand, setBrand] = useState([]);
  // const [unit, setUnit] = useState([]);
  const [addProduct] = useAddProductMutation();
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const groupRef = useRef(null);

  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);

  const [groupShow, setGroupShow] = useState(false);
  const handleGroupClose = () => setGroupShow(false);

  const [genericShow, setGenericShow] = useState(false);
  const handleGenericClose = () => setGenericShow(false);

  const [brandShow, setBrandShow] = useState(false);
  const handleBrandClose = () => setBrandShow(false);
  const [profit, setProfit] = useState(0);

  const { data: lastProduct, isSuccess } = useProductLastQuery();

  useKeypress("F1", (e) => {
    e.preventDefault();
    groupRef.current.focus();
  });

  useEffect(() => {
    if (lastProduct?.length > 0) {
      generateNumber(lastProduct);
    }
  }, [lastProduct, isSuccess]);

  useEffect(() => {
    if (productData?.mrp !== 0 && productData?.tp !== 0) {
      const profitAmount = productData?.mrp - productData?.tp;
      // console.log('profitAmount', profitAmount);
      const profitMargin = (profitAmount / productData?.tp) * 100;
      // console.log('profitMargin', profitMargin);
      setProfit(profitMargin);
    }
  }, [productData?.mrp, productData?.tp]);

  useEffect(() => {
    generateName();
  }, [productData?.brandName, productData?.size, productData?.product_type]);

  const generateNumber = (lastProduct) => {
    // console.log("lastProduct", lastProduct[0]?.article_code)
    const lastSix = lastProduct[0]?.article_code?.substring(
      lastProduct[0]?.article_code?.length - 6
    );
    const firstEight = lastProduct[0]?.article_code?.substring(0, 8);
    const date = format(new Date(new Date()), "MMddyyyy");
    // console.log("firstEight", firstEight);
    // console.log("lastSix", lastSix);
    let productCode = "";
    if (date === firstEight) {
      const nextNumber = parseInt(lastSix) + 1;
      const nextNumberString = nextNumber?.toString().padStart(6, "0");
      // console.log("nextNumber", nextNumber);
      productCode = date + nextNumberString;
    } else {
      productCode = date + "000001";
    }

    // console.log("produtCode", productCode);

    dispatch(inputProductCode(productCode));
  };
  const generateName = () => {
    const name =
      productData?.brandName +
      " " +
      productData?.size +
      " " +
      productData?.product_type;
    dispatch(inputName(name));
  };

  const handleMultiProductAdd = async () => {
    // console.log("productData", productData);
    // console.log("hi from multi add product");
    setLoader(true);
    try {
      const response = await addProduct(productData);
      if (response) {
        // console.log(response);
        if (response?.error) {
          apiUniqueErrHandle(response);
        } else {
          // write multi product
          // window.location.reload(true);

          // console.log(response);
          // console.log(productData);
          // console.log(response?.data);
          // console.log(response?.data?.product);
          if (dispatch(resetProductAdd())) {
            dispatch(selectGroup(response?.data?.product?.group));
            dispatch(selectGeneric(response?.data?.product?.generic));
            dispatch(selectBrand(response?.data?.product?.brand));
            const brandName = await axios.get(
              `${BASE_URL}/brand/${response?.data?.product?.brand}`
            );
            // console.log("brandName", brandName);
            dispatch(selectBrandName(brandName?.data?.name));
            dispatch(productName(brandName?.data?.name));
            notify("Product Add Successfully", "success");
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
      generateNumber();
    }
  };

  // console.log("productData", productData);
  const handelAddProduct = async (data) => {
    setLoader(true);
    try {
      const response = await addProduct(productData);
      if (response) {
        // console.log(response);
        if (response?.error) {
          apiUniqueErrHandle(response);
          notify("Product addition failed", "error");
        } else {
          dispatch(resetProductAdd());
          alert("Product added successfully", "success");
          // console.log(response?.data);
          if (response?.data) {
            // Additional logic if needed
          }
        }
      }
    } catch (err) {
      console.log(err);
      notify("An error occurred while adding the product", "error");
    } finally {
      setLoader(false);
      // navigate("/product");
    }
  };
  const handleProductPageExit = () => {
    dispatch(resetProductAdd());
    navigate("/product");
  };

  // SUPPLIER SELECT HANDLE
  const handleOnchangeMasterCategory = (e) => {
    // console.log("e", e);
  };
  const handleOnchangeGroup = (e) => {
    // console.log("e", e);
    if (e !== null) {
      dispatch(selectGroup(e.value));
    } else {
      dispatch(selectGroup(null));
    }
  };
  const handleOnchangeGeneric = (e) => {
    // console.log("e", e);
    if (e !== null) {
      dispatch(selectGeneric(e.value));
    } else {
      dispatch(selectGeneric(null));
    }
  };
  const handleOnchangeBrand = (e) => {
    // console.log("e", e);
    if (e !== null) {
      dispatch(selectBrand(e.value));
      dispatch(selectBrandName(e.label));
      dispatch(productName(e.label));
    } else {
      dispatch(selectBrand(null));
      dispatch(selectBrandName(""));
      dispatch(productName(""));
    }
  };
  const handleOnchangeCategory = (e) => {
    // console.log("e", e);
    if (e !== null) {
      dispatch(selectCategory(e.value));
    } else {
      dispatch(selectCategory(null));
    }
  };
  const handleOnchangeSubCategory = (e) => {
    // console.log("e", e);
    if (e !== null) {
      dispatch(selectSubCategory(e.value));
    } else {
      dispatch(selectSubCategory(null));
    }
  };
  const handleOnchangeUnit = (e) => {
    // console.log("e", e);
    if (e !== null) {
      dispatch(selectUnit(e.value));
    } else {
      dispatch(selectUnit(null));
    }
  };

  const handleProductSize = (e) => {
    dispatch(sizeInput(e.target.value));
    dispatch(productNameSize(e.target.value));
  };

  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10 mb-4">
            <Header title="Add New Product"></Header>
            <div className="row">
              <div className="col-md-12">
                <form
                  onSubmit={handleSubmit(handelAddProduct)}
                  className="mt-4"
                >
                  {/* 1st row */}
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="group" className="form-label">
                          Group
                        </label>
                        <SelectGroup
                          gp={gp}
                          groupRef={groupRef}
                          required
                          handleOnchange={(e) => {
                            handleOnchangeGroup(e);
                          }}
                        ></SelectGroup>
                        <BsPlusCircle onClick={() => setGroupShow(true)} />{" "}
                        <span>Add new Group ??</span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="generic" className="form-label">
                          Generic Name
                        </label>
                        <SelectGeneric
                          gn={gn}
                          required
                          handleOnchange={(e) => {
                            handleOnchangeGeneric(e);
                          }}
                        ></SelectGeneric>
                        <BsPlusCircle onClick={() => setGenericShow(true)} />{" "}
                        <span>Add new Generic ??</span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="brand" className="form-label">
                          Brand
                        </label>
                        <SelectBrand
                          bn={bn}
                          required
                          handleOnchange={(e) => {
                            handleOnchangeBrand(e);
                          }}
                        ></SelectBrand>
                        <BsPlusCircle onClick={() => setBrandShow(true)} />{" "}
                        <span>Add new Brand ??</span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="brand" className="form-label">
                          Size
                        </label>
                        <div className="row">
                          <div className="col-md-12">
                            <input
                              placeholder="Size"
                              className="form-control"
                              id="p_name"
                              aria-describedby="nameHelp"
                              defaultValue={productData?.size}
                              value={productData?.size}
                              onChange={(e) => {
                                handleProductSize(e);
                              }}
                            />
                          </div>
                          {/* <div className="col-md-5">
                            <select
                              className="form-select"
                              id="product_type"
                              placeholder="product_type"
                              onChange={(e) => {
                                dispatch(productTypeInput(e.target.value))
                              }}
                            >
                              <option value="mg">mg</option>
                              <option value="ml">ml</option>
                            </select>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 2nd row */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_name">
                          Name
                        </label>
                        <input
                          placeholder="Product name"
                          className="form-control"
                          id="p_name"
                          required
                          aria-describedby="nameHelp"
                          defaultValue={productData?.name}
                          value={productData?.name}
                          onChange={(e) => dispatch(inputName(e.target.value))}
                        />
                        <div id="nameHelp" className="form-text">
                          Product Name with pack size
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_article_code">
                          Article Code
                        </label>
                        <input
                          placeholder="Article Code"
                          className="form-control"
                          id="p_article_code"
                          aria-describedby="article_codeHelp"
                          defaultValue={productData?.article_code}
                          value={productData?.article_code}
                          onChange={(e) =>
                            dispatch(inputProductCode(e.target.value))
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_article_code">
                          Type
                        </label>
                        <select
                          {...register("type")}
                          className="form-select"
                          id="type"
                          placeholder="type"
                          onChange={(e) => {
                            dispatch(selectType(e.target.value));
                          }}
                        >
                          <option value="LOCAL">local</option>
                          <option value="FOREIGN">Foreign</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* 3rd row */}
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="masterCategory" className="form-label">
                          Category
                        </label>

                        <SelectCategory
                          handleOnchange={(e) => {
                            handleOnchangeCategory(e);
                          }}
                        ></SelectCategory>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="subCategory" className="form-label">
                          Sub Category
                        </label>

                        <CategorySelectByMC
                          handleOnchange={(e) => {
                            handleOnchangeSubCategory(e);
                          }}
                        ></CategorySelectByMC>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="masterCategory" className="form-label">
                          Unit
                        </label>

                        <SelectUnit
                          handleOnchange={(e) => {
                            handleOnchangeUnit(e);
                          }}
                        ></SelectUnit>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="p_type">
                          Pieces in Box
                        </label>
                        <input
                          placeholder="PCs in Box"
                          className="form-control"
                          id="p_name"
                          aria-describedby="nameHelp"
                          value={productData?.pcsBox}
                          onChange={(e) => {
                            dispatch(pcsBoxInput(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* 4rd row  */}
                  <div className="row">
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="p_type">
                          TP
                        </label>
                        <input
                          placeholder="TP"
                          className="form-control"
                          id="p_name"
                          aria-describedby="nameHelp"
                          defaultValue={productData?.tp}
                          value={productData?.tp}
                          onChange={(e) => {
                            dispatch(tpInput(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="p_type">
                          MRP
                        </label>
                        <input
                          placeholder="MRP"
                          className="form-control"
                          id="p_name"
                          aria-describedby="nameHelp"
                          defaultValue={productData?.mrp}
                          value={productData?.mrp}
                          onChange={(e) => {
                            dispatch(mrpInput(e.target.value));
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="p_type">
                          Profit
                        </label>
                        <div class="input-group mb-3">
                          <input
                            placeholder="Profit"
                            className="form-control"
                            id="p_name"
                            aria-describedby="nameHelp"
                            value={profit}
                            disabled
                          />
                          <span class="input-group-text p-1" id="basic-addon2">
                            %
                          </span>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-8">
                            <input
                              placeholder="Profit"
                              className="form-control"
                              id="p_name"
                              aria-describedby="nameHelp"
                              value={productData.tp > 0 ? parseInt(((parseInt(productData.mrp > 0 ? productData.mrp : 0) - parseInt(productData.tp > 0 ? productData.tp : 0)) / parseInt(productData.tp)) * 100) : 0}
                              disabled
                            />
                          </div>
                          <div className="col-md-4">
                            <input
                              placeholder="Profit"
                              className="form-control"
                              id="p_name"
                              aria-describedby="nameHelp"
                              value={"%"}
                              disabled
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="p_type">
                          Max Order Qty
                        </label>
                        <input
                          placeholder="Max Order Qty"
                          className="form-control"
                          id="p_name"
                          aria-describedby="nameHelp"
                          defaultValue={productData?.maxQty}
                          value={productData?.maxQty}
                          onChange={(e) => {
                            dispatch(maxQtyInput(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="p_type">
                          Min Order Qty
                        </label>
                        <input
                          placeholder="Min Order Qty"
                          className="form-control"
                          id="p_name"
                          aria-describedby="nameHelp"
                          defaultValue={productData?.minQty}
                          value={productData?.minQty}
                          onChange={(e) => {
                            dispatch(minQtyInput(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="p_type">
                          Alert Qty
                        </label>
                        <input
                          placeholder="Alert Qty"
                          className="form-control"
                          id="p_name"
                          aria-describedby="nameHelp"
                          defaultValue={productData?.alert_qty}
                          value={productData?.alert_qty}
                          onChange={(e) => {
                            dispatch(alertQtyInput(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* 5th row  */}
                  <div className="row">
                    <div className="col-md-2">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_vat">
                          VAT
                        </label>
                        <input
                          placeholder="VAT"
                          className="form-control"
                          id="p_vat"
                          defaultValue={productData?.vat}
                          value={productData?.vat}
                          aria-describedby="vatHelp"
                          onChange={(e) => {
                            dispatch(vatInput(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_vat_method">
                          Vat Method
                        </label>
                        {/* <input placeholder='Vat Method' className="form-control" id="p_vat_method" aria-describedby="vat_methodHelp" {...register("vat_method", { required: true, maxLength: 20 })} /> */}
                        <select
                          className="form-select"
                          onChange={(e) => {
                            dispatch(selectVatMethod(e.target.value));
                          }}
                        >
                          <option value="true">Include</option>
                          <option value="false">Exclude</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Shipping Method
                        </label>
                        {/* <input placeholder='shipping' className="form-control" id="p_shipping" aria-describedby="shippingHelp" {...register("shipping", { required: true, maxLength: 20 })} /> */}
                        <select
                          className="form-select"
                          onChange={(e) => {
                            dispatch(inputShippingMethod(e.target.value));
                          }}
                        >
                          <option value="cod">COD</option>
                          <option value="free">Free Shipping</option>
                          <option value="uttara">Inside Uttara</option>
                        </select>
                        <div id="shippingHelp" className="form-text">
                          What is the delivery method?
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_vat">
                          Discount
                        </label>
                        <div className="row">
                          <div className="col-md-10">
                            <input
                              placeholder="Discount"
                              className="form-control ml-5"
                              id="p_vat"
                              aria-describedby="Discount"
                              defaultValue={productData?.discount}
                              value={productData?.discount}
                              onChange={(e) => {
                                dispatch(discountInput(e.target.value));
                              }}
                            />
                          </div>
                          <div className="col-md-2">
                            {productData?.discount_type === false ? (
                              <BsSquare
                                onClick={() =>
                                  dispatch(selectDiscountType(true))
                                }
                              />
                            ) : (
                              <BsCheckSquare
                                onClick={() =>
                                  dispatch(selectDiscountType(false))
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3 ">
                        <label className="form-label" htmlFor="p_shipping">
                          Status
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => {
                            dispatch(selectStatus(e.target.value));
                          }}
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* 6th row */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3 ">
                        <label
                          className="form-label"
                          htmlFor="p_product_details"
                        >
                          Product Description
                        </label>
                        <textarea
                          placeholder="Product Description"
                          className="form-control"
                          id="p_product_details"
                          aria-describedby="product_detailsHelp"
                          value={productData?.details}
                          onChange={(e) => {
                            dispatch(inputProductDetails(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="reset"
                    onClick={() => {
                      dispatch(resetProductAdd());
                    }}
                    className="btn btn-dark col-4 col-md-2 m-1"
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleMultiProductAdd();
                    }}
                    className="btn btn-dark col-4 col-md-2 m-1"
                  >
                    Add Multi
                  </button>
                  <input
                    type="submit"
                    className="btn btn-dark col-4 col-md-2"
                    value="Add Product"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GroupAddModal
        onShow={groupShow}
        setOnShow={setGroupShow}
        handleClose={handleGroupClose}
      ></GroupAddModal>
      <GenericAddModal
        onShow={genericShow}
        setOnShow={setGenericShow}
        handleClose={handleGenericClose}
      ></GenericAddModal>
      <BrandAddModal
        onShow={brandShow}
        setOnShow={setBrandShow}
        handleClose={handleBrandClose}
      ></BrandAddModal>
    </div>
  );
};

export default AddProduct;
