import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import { notify } from "../../Utility/Notify";
import SideBar from "../../Common/SideBar/SideBar";
import Header from "../../Common/Header/Header";
import { useAddBrandMutation } from "../../../services/brandApi";
import CsvImportBrand from "../../Common/CsvImporter/CsvImportBrand";

const ImportBrand = () => {
  const [csvData, setCsvData] = useState([]);

  // console.log('csvData', csvData)
  const [addBrand] = useAddBrandMutation();
  // const [updatePrice] = useUpdatePriceMutation();

  const handleImportBrand = () => {
    csvData.map((item) => {
      // console.log("create: ", item)
      addBrand(item)
        .then((res) => {
          // console.log(res)
          notify(`Brand ${item.name} Create Successful!`, "success");
        })
        .catch((err) => {
          notify(`Brand ${err} Create Successful!`, "success");
        });
    });
    // setCsvData([])
    // console.log(csvData)
  };

  let i = 1;
  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Import Brand"></Header>
            <div className="row">
              <div className="col-md-4 offset-md-4">
                {/* <div className="d-flex flex-column justify-content-center min-vh-100  align-items-center"> */}
                <div className="pt-5 pb-5">
                  <CsvImportBrand
                    setCsvData={setCsvData}
                    handleImportButton={handleImportBrand}
                  ></CsvImportBrand>
                  {/* SPINNER */}
                  {/* */}

                  {/* </div> */}
                </div>
              </div>
              <div className="col-md-12">
                <table class="table table-striped ">
                  <thead>
                    <tr>
                      <th>#</th>

                      <th>name</th>
                      <th>code</th>
                      <th>status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {}
                    {csvData ? (
                      csvData.map((item) => (
                        <tr key={i++}>
                          <th>{i}</th>

                          <td>{item.name}</td>
                          <td>{item.code}</td>

                          <td>{item?.status}</td>
                        </tr>
                      ))
                    ) : (
                      <div className="spinner-border text-danger" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Toaster position="bottom-right" />
        </div>
      </div>
    </div>
  );
};

export default ImportBrand;
