import React, { useEffect, useState } from "react";
import SideBar from "../Common/SideBar/SideBar";
import Header from "../Common/Header/Header";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import * as Icons from "heroicons-react";
import {
  useAccountByDateQuery,
  useAccountQuery,
  useAccountsQuery,
  useDeleteAccountMutation,
  useUpdateAccountDeleteMutation,
} from "../../services/accountApi";
import { compareAsc, format } from "date-fns";
import DatePicker from "react-datepicker";
import { notify } from "../Utility/Notify";
import AccountView from "./AccountView";

const Accounts = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));

  // const data = []
  const { data, isSuccess, refetch } = useAccountByDateQuery({
    startDate,
    endDate,
  });
  const [deleteUpdateAccount] = useUpdateAccountDeleteMutation();
  // console.log(data)
  // const { data, isSuccess, refetch } = useAccountsQuery()
  let i = 1;

  const [accountId, setAccountId] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const { data: accountDetails, refetch: accountDEtailsRefetch } =
    useAccountQuery(accountId);
  useEffect(() => {
    if (accountId !== "") {
      accountDEtailsRefetch();
    }
  }, [accountId]);

  const handleDataLimit = () => {};
  const handleSearch = () => {};
  const deleteHandler = async (e) => {
    // console.log(e)
    const confirm = window.confirm("Are you Sure? Delete this Category?");
    if (confirm) {
      await deleteUpdateAccount({ _id: e })
        .then((res) => {
          //   console.log(res);
          notify("Category delete successful", "error");
        })
        .then((err) => {
          console.log(err);
          notify("Can not delete", "error");
        });
    } else {
      notify("Account Delete Canceled", "error");
    }
  };
  const handleAccountsView = async (e) => {
    setShow(true);
    // console.log(e);
    setAccountId(e);
    // accountDEtailsRefetch()
  };
  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-12">
                <Header title="Accounts"></Header>
              </div>
              <div className="col-md-6 mt-2 mb-2">
                <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                  {/* <b>Start:</b> */}
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control me-2"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                  <span width="10px"></span>
                  {/* <b>End:</b> */}
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                </div>
              </div>
              <div className="col-md-2 mt-2 mb-2">
                <Link to="/addAfterSale" className="btn btn-dark float-end">
                  <Icons.Plus className="icon-edit" size={20}></Icons.Plus>
                  AfterSale Payment
                </Link>
              </div>
              {/* <div className="col-md-9  mt-2 mb-2">Filters</div> */}
              <div className="col-md-2  mt-2 mb-2">
                <Link
                  to="/addAccountExpense"
                  className="btn btn-dark float-end"
                >
                  <Icons.Plus className="icon-edit" size={20}></Icons.Plus>
                  Add Expenditure
                </Link>
              </div>
              <div className="col-md-2  mt-2 mb-2">
                <Link to="/addAccount" className="btn btn-dark float-end">
                  <Icons.Plus className="icon-edit" size={20}></Icons.Plus>
                  Add Payment
                </Link>
              </div>
            </div>

            <Table hover>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">AC No</th>
                  <th scope="col">Account Head</th>
                  <th scope="col">Date</th>
                  {/* <th scope="col">Group</th> */}
                  <th scope="col">Amount</th>
                  <th scope="col">Actions </th>
                </tr>
              </thead>
              <tbody>
                {/* {isLoading && <h2>...Loading</h2>}
                {isFetching && <h2>...isFetching</h2>}
                {error && <h2>Something went wrong</h2>} */}
                {data?.length > 0 ? (
                  data?.map((ac) => (
                    <tr key={ac?._id}>
                      <th scope="row">{i++}</th>
                      <td>{ac?.acId}</td>
                      <td>{ac?.accountHead?.name}</td>
                      <td>
                        {format(new Date(ac?.createdAt), "yyyy-MM-dd HH:mm:ss")}
                      </td>
                      <td>{ac?.amount}</td>
                      <td>
                        <Icons.EyeOutline
                          onClick={() => handleAccountsView(ac._id)}
                          className="icon-eye me-1"
                          size={20}
                        ></Icons.EyeOutline>
                        {/* <Link to={`/account/update/${ac._id}`}>
                                                    <Icons.PencilAltOutline
                                                        className="icon-edit"
                                                        size={20}
                                                    ></Icons.PencilAltOutline>
                                                </Link> */}
                        <Icons.TrashOutline
                          className="icon-trash"
                          size={20}
                          onClick={() => deleteHandler(ac._id)}
                        ></Icons.TrashOutline>
                      </td>
                    </tr>
                  ))
                ) : (
                  // <tr>
                  //   <td colSpan={6}>
                  //     {isLoading && <Skeleton count={100} />}
                  //     {/* {isFetching && <h2>...isFetching</h2>}
                  //     {error && <h2>Something went wrong</h2>} */}
                  //   </td>
                  // </tr>
                  <tr colSpan={9}>No Accounts Found</tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <AccountView
        show={show}
        handleClose={handleClose}
        accountId={accountId}
        accountDetails={accountDetails}
      ></AccountView>
    </div>
  );
};

export default Accounts;
