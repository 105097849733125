import React from "react";
import { compareAsc, format } from "date-fns";
import toWord from "../../Utility/toWord";
import SupplierSalesHeader from "./SupplierSalesHeader";


const SupplierSalesPrint = React.forwardRef(({ ...props }, ref) => {
    const { data, supplierInfo, endDate, startDate } = props;
    console.log(data);
    let i = 1;
    return (
        <div className="container py-2" ref={ref}>
            <SupplierSalesHeader
                data={data}
                startDate={startDate}
                endDate={endDate}
                supplierInfo={supplierInfo}
                title="Supplier Wise Sale"
                format={format}
                className="mb-5"
            />

            <div className="row pt-2">
                <div className="col-12">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Code</th>
                                <th scope="col">Name</th>
                                {/* <th scope="col">UoM</th> */}
                                <th scope="col">Qty</th>
                                {/* <th scope="col">Tax</th> */}
                                <th scope="col">Discount</th>
                                <th scope="col">TP</th>
                                <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data ? (
                                data?.map((item) => (
                                    <tr>
                                        <th>{i++}</th>
                                        <td>{item?.article_code}</td>
                                        <td>{item?.name}</td>
                                        {/* <td>{item?.unit}</td> */}
                                        <td>{item?.totalQuantity}</td>
                                        {/* <td>
                      {" "}
                      {(item?.qty * 100) / item?.tax !== 0
                        ? parseInt(item?.tax)
                        : 1}
                    </td> */}
                                        <td>{item?.discount ? item?.discount : 0}</td>
                                        <td>{item?.tp}</td>
                                        <td className="text-end">{parseFloat(item?.tp * item?.totalQuantity).toFixed(2)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr colSpan="9" className="text-center">
                                    <th>Sorry! No Product Found</th>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="4" className="text-end">
                                    {/* Tax:{" "} */}
                                </th>
                                {/* <th>{data.tax}</th> */}
                                <th colSpan="2" className="text-end">
                                    Ground Total:{" "}
                                </th>
                                <th>{Math.round(data?.total)}</th>
                            </tr>
                            <tr>
                                <td colSpan="9" className="text-start">
                                    <i>
                                        <b>In Words:</b> {toWord(Math.round(data?.total))} Taka Only
                                    </i>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <br />
            <br />
            <br />
            <div className="row px-2 bottom-2 ">
                <div className="col-4">
                    <p>
                        <b>Prepared By:</b>
                        {data?.userId?.name}
                    </p>
                </div>
                <div className="col-4">
                    <p>
                        <b>Checked By:</b>
                    </p>
                </div>
                <div className="col-4">
                    <p>
                        <b>Authorized By:</b>
                    </p>
                </div>
            </div>
        </div>
    );
});

export default SupplierSalesPrint;
