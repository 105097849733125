// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h-70vh {
  height: 70vh;
  border-radius: 5px;
  background-color: #f8f9fa;
  border: 1px solid #e6e6e6;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-y: scroll;
  /* height: 100%; */
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
}

.text-red {
  color: #1477BD
}

.card-deck {
  max-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/SMS/SMS.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE;AACF;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".h-70vh {\n  height: 70vh;\n  border-radius: 5px;\n  background-color: #f8f9fa;\n  border: 1px solid #e6e6e6;\n  margin-bottom: 20px;\n  overflow: hidden;\n  overflow-y: scroll;\n  /* height: 100%; */\n}\n\n.title {\n  font-size: 1.25rem;\n  font-weight: bold;\n}\n\n.text-red {\n  color: #1477BD\n}\n\n.card-deck {\n  max-height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
