// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customFrom {
    text-align: center;
    width: 80%;
}

.customFromTax {
    /* width: 50%; */

}

/* 
tbody{height: 400px; overflow-y: scroll;display:block;}
th { position: sticky; top: 0; } */
/* tbody{
    height: 400px;
    overflow-y: scroll;
} */

/* GRN TABLE */
.table-fixed thead {
    width: 97%;
}

.table-fixed tbody {
    height: 230px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
    border-bottom-width: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/GRN/Purchase.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,gBAAgB;;AAEpB;;AAEA;;kCAEkC;AAClC;;;GAGG;;AAEH,cAAc;AACd;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,WAAW;AACf;;AAEA;;;;;IAKI,cAAc;AAClB;;AAEA;;IAEI,WAAW;IACX,sBAAsB;AAC1B","sourcesContent":[".customFrom {\n    text-align: center;\n    width: 80%;\n}\n\n.customFromTax {\n    /* width: 50%; */\n\n}\n\n/* \ntbody{height: 400px; overflow-y: scroll;display:block;}\nth { position: sticky; top: 0; } */\n/* tbody{\n    height: 400px;\n    overflow-y: scroll;\n} */\n\n/* GRN TABLE */\n.table-fixed thead {\n    width: 97%;\n}\n\n.table-fixed tbody {\n    height: 230px;\n    overflow-y: auto;\n    width: 100%;\n}\n\n.table-fixed thead,\n.table-fixed tbody,\n.table-fixed tr,\n.table-fixed td,\n.table-fixed th {\n    display: block;\n}\n\n.table-fixed tbody td,\n.table-fixed thead>tr>th {\n    float: left;\n    border-bottom-width: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
