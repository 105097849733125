// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-wrapper {
    position: absolute;
    background: white;
    z-index: 9;
}

ul,
li {
    margin: 0;
    padding: 0;
}

ul {
    max-height: 500px;
    overflow-y: scroll;
    padding: 30px;

}

li {
    list-style: none;
    cursor: pointer;
}

li:hover {
    color: #1477BD;
}

.remove-from-cart {
    cursor: pointer;
}

.remove-from-cart:hover {
    color: #1477BD;
}

.from-group svg {
    height: 20px;
}

.from-group input {
    height: 20px;
}

.pos-wrapper nav {
    margin-top: 22px;
    margin-bottom: 15px;
}

.pos-wrapper nav a {
    padding: 15px 0;
}

input.quantity {
    height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/POS/POS.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,UAAU;AACd;;AAEA;;IAEI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;;AAEjB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".search-wrapper {\n    position: absolute;\n    background: white;\n    z-index: 9;\n}\n\nul,\nli {\n    margin: 0;\n    padding: 0;\n}\n\nul {\n    max-height: 500px;\n    overflow-y: scroll;\n    padding: 30px;\n\n}\n\nli {\n    list-style: none;\n    cursor: pointer;\n}\n\nli:hover {\n    color: #1477BD;\n}\n\n.remove-from-cart {\n    cursor: pointer;\n}\n\n.remove-from-cart:hover {\n    color: #1477BD;\n}\n\n.from-group svg {\n    height: 20px;\n}\n\n.from-group input {\n    height: 20px;\n}\n\n.pos-wrapper nav {\n    margin-top: 22px;\n    margin-bottom: 15px;\n}\n\n.pos-wrapper nav a {\n    padding: 15px 0;\n}\n\ninput.quantity {\n    height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
