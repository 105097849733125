import React, { useEffect, useState } from 'react';
import { Button, Modal, ProgressBar, Spinner } from 'react-bootstrap';
import * as Icons from "heroicons-react";
import { CSVLink } from "react-csv";

const CustomerExportModal = ({ onShow, handleClose, exportCustomer }) => {
    const [exportCSV, setExportCSV] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [current, setCurrent] = useState(0);

    let csvData = [];
    let x = 0;
    const processCSV = async (exportCustomer) => {
        exportCustomer?.map((item) => {
            x++;
            csvData = [
                ...csvData,
                {
                    name: item.name,
                    // email: item.email,
                    // username: item.username,
                    // membership: item.membership,
                    // address: item.address,
                    // point: item.point,
                    phone: item.phone,
                },
            ];
        });
        setCurrent(x);
        setDataReady(true);
        setExportCSV(csvData);
    };


    useEffect(() => {
        processCSV(exportCustomer);
    }, [exportCustomer]);

    const headers = [
        { label: "name", key: "name" },
        // { label: "email", key: "email" },
        // { label: "username", key: "username" },
        // { label: "membership", key: "membership" },
        // { label: "address", key: "address" },
        // { label: "point", key: "point" },
        { label: "phone", key: "phone" },
    ];



    return (
        <Modal show={onShow} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Export Customer Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {dataReady === false ? (
                    <div className="d-flex flex-column align-items-center">
                        <Spinner animation="grow" variant="warning" size="sm" />
                        <Icons.ClockOutline className="icon-trash text-warning" size={80} />
                        <p className="text-dark mt-3 mb-3">
                            <small>Please Wait! when Your Export is Getting ready!</small>
                        </p>
                        <ProgressBar
                            className="w-100"
                            striped
                            variant="success"
                            now={
                                current > 0
                                    ? exportCustomer?.length > 0
                                        ? Math.fround((100 / exportCustomer?.length) * current)?.toFixed(2)
                                        : 0
                                    : 0
                            }
                            label={`${current > 0
                                ? exportCustomer?.length > 0
                                    ? Math.fround((100 / exportCustomer?.length) * current)?.toFixed(2)
                                    : 0
                                : 0
                                }%  - ${current} of ${exportCustomer?.length}`}
                        />
                    </div>
                ) : (
                    <div className="d-flex flex-column align-items-center">
                        <Icons.CheckCircleOutline
                            className="icon-trash text-success"
                            size={100}
                        />
                        <p className="text-dark my-3">Your Export is ready!</p>
                        <CSVLink
                            className="btn btn-dark"
                            data={exportCSV}
                            asyncOnClick={true}
                            headers={headers}
                            filename="Export_Customers.csv"
                        >
                            <Icons.DownloadOutline
                                className="icon-trash text-warning"
                                size={22}
                            />{" "}
                            Download Customer Data
                        </CSVLink>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomerExportModal;