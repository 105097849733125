import { configureStore } from "@reduxjs/toolkit";
import userApi from "../services/userApi";
import categoryApi from "../services/categoryApi";
import productApi from "../services/productApi";
import customerApi from "../services/customerApi";
import SearchApi from "../services/searchApi";
// import companyApi from "../services/companyApi";
// import DamageApi from "../services/damagApi";
import DamageApi from "../services/damageApi";
// import inventoryApi from "../services/inventoryApi";
import priceApi from "../services/priceApi";
import purchasApi from "../services/purchasApi";
import grnApi from "../services/grnApi";
import rtvApi from "../services/rtvApi";
import saleApi from "../services/saleApi";
import supplierApi from "../services/supplierApi";
// import tpnApi from "../services/tpnApi";
import unitApi from "../services/unitApi";
import warehouseApi from "../services/warehouseApi";
import brandApi from "../services/brandApi";
import groupApi from "../services/groupApi";
import genericApi from "../services/genericApi";
import { accountReducer } from "../features/accountSlice";
import { supplierProductsReducer } from "../features/supplierSlice";
import { grnReducer } from "../features/grnSlice";
import { tpnReducer } from "../features/tpnSlice";
import { rtvReducer } from "../features/rtvSlice";
import { posReducer } from "../features/posSlice";
import { voidReducer } from "../features/voidSlice";
import { damageReducer } from "../features/damageSlice";
import { smsReducer } from "../features/smsSlice";
import { ecomSalesReducer } from "../features/ecomSalesSlice";
import { productPriceReducer } from "../features/productPriceSlice";
import { purchaseReducer } from "../features/purchaseSlice";
import { priceUpdateReducer } from "../features/priceUpdateSlice";
import { productAddReducer } from "../features/productAddSlice";
import InventoryApi from "../services/inventoryApi";
import InventoryCountApi from "../services/inventoryCountApi";
import ecomSaleApi from "../services/ecomApi";
import tpnApi from "../services/tpnApi";
import accountHeadApi from "../services/accountHeadApi";
import accountApi from "../services/accountApi";

const Store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [warehouseApi.reducerPath]: warehouseApi.reducer,
    [SearchApi.reducerPath]: SearchApi.reducer,
    [supplierApi.reducerPath]: supplierApi.reducer,
    [priceApi.reducerPath]: priceApi.reducer,
    [grnApi.reducerPath]: grnApi.reducer,
    [purchasApi.reducerPath]: purchasApi.reducer,

    // [companyApi.reducerPath]: companyApi.reducer,
    [DamageApi.reducerPath]: DamageApi.reducer,
    [InventoryApi.reducerPath]: InventoryApi.reducer,
    [InventoryCountApi.reducerPath]: InventoryCountApi.reducer,
    [rtvApi.reducerPath]: rtvApi.reducer,
    [saleApi.reducerPath]: saleApi.reducer,
    [ecomSaleApi.reducerPath]: ecomSaleApi.reducer,
    [tpnApi.reducerPath]: tpnApi.reducer,

    [unitApi.reducerPath]: unitApi.reducer,
    [warehouseApi.reducerPath]: warehouseApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [genericApi.reducerPath]: genericApi.reducer,
    [accountHeadApi.reducerPath]: accountHeadApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    supplierProductsReducer,
    grnReducer,
    posReducer,
    voidReducer,
    tpnReducer,
    purchaseReducer,
    rtvReducer,
    damageReducer,
    smsReducer,
    productPriceReducer,
    ecomSalesReducer,
    priceUpdateReducer,
    productAddReducer,
    accountReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(categoryApi.middleware)
      .concat(productApi.middleware)
      .concat(customerApi.middleware)
      .concat(warehouseApi.middleware)
      .concat(unitApi.middleware)
      .concat(brandApi.middleware)
      .concat(groupApi.middleware)
      .concat(genericApi.middleware)
      .concat(supplierApi.middleware)
      .concat(priceApi.middleware)
      .concat(SearchApi.middleware)
      .concat(purchasApi.middleware)
      .concat(saleApi.middleware)
      .concat(ecomSaleApi.middleware)
      .concat(tpnApi.middleware)
      .concat(DamageApi.middleware)
      .concat(rtvApi.middleware)
      .concat(grnApi.middleware)
      .concat(InventoryApi.middleware)
      .concat(priceApi.middleware)
      .concat(InventoryCountApi.middleware)
      .concat(accountHeadApi.middleware)
      .concat(accountApi.middleware),

  // .concat(companyApi.middleware)
  // .concat(tpnApi.middleware).concat(unitApi.middleware)
  // .contcat(customerApi.middleware),
});

export default Store;
