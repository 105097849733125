import React from "react";
import { Link } from "react-router-dom";
import * as Icons from "heroicons-react";
import { Accordion, Image, Navbar } from "react-bootstrap";
import logo from "../../../logo.png";
import "./SideBar.css";
import AdminSideBar from "./AdminSideBar";
import EcomSideBar from "./EcomSideBar";
import ManagerSideBar from "./ManagerSideBar";
import SupervisorSideBar from "./SupervisorSideBar";

const SideBar = () => {
  let active = window.location.pathname;
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log("user", user)
  // console.log(active);
  return (
    <>
      {user?.type === "admin" && <AdminSideBar></AdminSideBar>}
      {user?.type === "ecom" && <EcomSideBar></EcomSideBar>}
      {user?.type === "manager" && <ManagerSideBar></ManagerSideBar>}
      {user?.type === "supervisor" && <SupervisorSideBar></SupervisorSideBar>}
    </>
  );
};

export default SideBar;
