import React, { useEffect, useState } from 'react';
import { Button, Modal, ProgressBar, Spinner } from 'react-bootstrap';
import * as Icons from "heroicons-react";
import { CSVLink } from "react-csv";

const GenericDetailsExportModal = ({ onShow, handleClose, exportGeneric }) => {
    const [exportCSV, setExportCSV] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [current, setCurrent] = useState(0);

    let csvData = [];
    let x = 0;
    const processCSV = async (exportGeneric) => {
        exportGeneric?.map((item) => {
            x++;
            csvData = [
                ...csvData,
                {
                    _id: item._id,
                    name: item.name,
                    code: item.code,
                    status: item.status,
                },
            ];
        });
        setCurrent(x);
        setDataReady(true);
        setExportCSV(csvData);
    };

    useEffect(() => {
        if (exportGeneric?.length > 0) {

            processCSV(exportGeneric);
        }
    }, [exportGeneric]);


    const headers = [
        { label: "id", key: "_id" },
        { label: "name", key: "name" },
        { label: "code", key: "code" },
        { label: "status", key: "status" },
    ];

    return (
        <Modal show={onShow} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Export Generic Details</Modal.Title>
            </Modal.Header>
            {/* <Modal.Body >
            {/* <CSVLink data={csvData}>
              Download me
              </CSVLink> */}
            {/* <CSVLink data={exportCSV} asyncOnClick={true} headers={headers}>
                {exportCSV.length > 0
                    ? "Preparing csv for Download..."
                    : "Download Product Details"}
            </CSVLink>
        </Modal.Body> */}
            <Modal.Body className="text-center">
                {dataReady === false ? (
                    <div className="d-flex flex-column align-items-center">
                        <Spinner animation="grow" variant="warning" size="sm" />
                        <Icons.ClockOutline className="icon-trash text-warning" size={80} />
                        <p className="text-dark mt-3 mb-3">
                            <small>Please Wait! when Your Export is Getting ready!</small>
                        </p>
                        <ProgressBar
                            className="w-100"
                            striped
                            variant="success"
                            now={
                                current > 0
                                    ? exportGeneric?.length > 0
                                        ? Math.fround((100 / exportGeneric?.length) * current)?.toFixed(2)
                                        : 0
                                    : 0
                            }
                            label={`${current > 0
                                ? exportGeneric?.length > 0
                                    ? Math.fround((100 / exportGeneric?.length) * current)?.toFixed(2)
                                    : 0
                                : 0
                                }%  - ${current} of ${exportGeneric?.length}`}
                        />
                    </div>
                ) : (
                    <div className="d-flex flex-column align-items-center">
                        <Icons.CheckCircleOutline
                            className="icon-trash text-success"
                            size={100}
                        />
                        <p className="text-dark my-3">Your Export is ready!</p>
                        <CSVLink
                            className="btn btn-dark"
                            data={exportCSV}
                            asyncOnClick={true}
                            headers={headers}
                            filename="Export_Generic.csv"
                        >
                            <Icons.DownloadOutline
                                className="icon-trash text-warning"
                                size={22}
                            />{" "}
                            Download Generic Data
                        </CSVLink>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GenericDetailsExportModal;