import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const RequireOnlyAdminAuth = ({ children }) => {
  const accessToken = localStorage.getItem("accessTokens");
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();

  // console.log( user)

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (user.type !== "admin") {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default RequireOnlyAdminAuth;
