import React from 'react';
import Logo from "../../../logo.png"
import Barcode from "react-barcode";

const SupplierSalesHeader = ({ data, format, title, supplierInfo, endDate, startDate }) => {
    console.log(data);
    const now = format(new Date(), "MM-dd-yyyy hh:mm:ss")
    return (
        <>
            <div className="row pt-3 pb-3 mb-5 border-bottom">
                <div className="col-7">
                    <img src={Logo} height="40" alt="not Image" />
                </div>

                <div className="col-5">
                    <p className="p-0 m-0 text-end">
                        <b>Hotline: </b> 01742225636
                    </p>
                    <p className="p-0 m-0 text-end">
                        <small>H#6, R#27, Sector 7, Uttara, Dhaka - 1230</small>
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-6 print-header-text">
                    <p>
                        {/* <b>To</b>
                        <br /> */}
                        <b>Vendor Name :</b> {supplierInfo.label} <br />
                        <b>Vendor Code :</b> {supplierInfo.code} <br />
                        <b>Vendor Phone :</b> {supplierInfo?.phone} <br />
                        {/* {data?.supplier?.address} <br /> */}
                    </p>

                    <p>
                        <b>Form</b> <br />
                        The Community Magasin <br />
                        communitymagasin@gmail.com <br />
                        01742225636 <br />
                        Uttara, Dhaka - 1230 <br />
                    </p>
                </div>
                <div className="col-6 text-end">
                    <h4>{title}</h4>
                    <span className="ps-6 d-block">
                        <p>
                            {startDate} to {endDate}
                        </p>
                        <p>Print Time : {now && now}</p>
                        <p className="text-end bar-code">
                            <Barcode value={data?._id} height="60" width="2" fontSize="10" />
                        </p>
                    </span>
                </div>
            </div>
        </>
    );
};

export default SupplierSalesHeader;