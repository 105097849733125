import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Group } from "../models/group.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

console.log(BASE_URL);

export const GroupApi = createApi({
  reducerPath: "GroupApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Group"],
  endpoints: (builder) => ({
    groups: builder.query<Group[], void>({
      query: () => "/group",
      providesTags: ["Group"],
    }),
    groupsExport: builder.query<Group[], void>({
      query: () => "/group/export",
      providesTags: ["Group"],
    }),
    group: builder.query<Group, string>({
      query: (_id) => `/group/${_id}`,
      providesTags: ["Group"],
    }),
    groupCount: builder.query<Group[], void>({
      query: () => "/group/count",
      providesTags: ["Group"],
    }),
    groupPagination: builder.query<Group, any>({
      query: ({ page, size, q }) => `/group/all/${page}/${size}?q=${q}`,
      // query: ({page, size, q}) => `/Customer`,
      providesTags: ["Group"],
    }),
    addGroup: builder.mutation<{}, Group>({
      query: (group) => ({
        url: "/group",
        method: "POST",
        body: group,
      }),
      invalidatesTags: ["Group"],
    }),
    updateGroup: builder.mutation<void, Group>({
      query: ({ _id, ...rest }) => ({
        url: `/group/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Group"],
    }),
    deleteGroup: builder.mutation<void, string>({
      query: (id) => ({
        url: `/group/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Group"],
    }),
  }),
});

export const {
  useGroupsQuery,
  useGroupsExportQuery,
  useGroupQuery,
  useGroupCountQuery,
  useGroupPaginationQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} = GroupApi;

export default GroupApi;
