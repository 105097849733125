import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Icons from "heroicons-react";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { CSVLink, CSVDownload } from "react-csv";
import useInventory from "../Hooks/useInventory";
import axios from "axios";
import {
  useSaleArticelExportByDateQuery,
} from "../../services/saleApi";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet";

const ExportArticleSale = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [q, setQ] = useState("");

  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useSaleArticelExportByDateQuery({
      startDate,
      endDate,
      q,
    });

  console.log(startDate, endDate, data);
  console.log(q);
  // const { adjustInventorySaleDel } = useInventory();
  const [articleSale, setArticleSale] = useState([]);
  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate, q]);

  useEffect(() => {
    let articleSales = [];
    console.log("article", data);

    // (product = [...product, sale.products])
    // console.log(sale?.products);
    let product = [];
    data?.map((sale) =>

      {if(sale.products?.length > 0){
        sale?.products?.map((product) => {
          // console.log(product);
          articleSales = [
            ...articleSales,
            {
              invoice_no: sale?.invoiceId,
              date:
                sale?.createdAt &&
                format(new Date(sale?.createdAt), "MM/dd/yyyy"),
              // Product Loop
              code: product.article_code,
              tp: product.tp,
              priceId: product.priceId,
              mrp: product.mrp,
              name: product.name,
              vat: product.vat,
              qty: product.qty,
              total: (
                product.qty * product.mrp +
                product.vat * product.qty
              ).toFixed(2),
            },
          ];
        })
      }
      if(sale.returnProducts?.length > 0){
        sale?.returnProducts?.map((product) => {
          // console.log(product);
          articleSales = [
            ...articleSales,
            {
              invoice_no: sale?.invoiceId,
              date:
                sale?.createdAt &&
                format(new Date(sale?.createdAt), "MM/dd/yyyy"),
              // Product Loop
              code: product.article_code,
              tp:parseFloat( product.tp),
              priceId: product.priceId,
              mrp: product.mrp,
              name: product.name,
              vat: product.vat ? product.vat : 0,
              qty: `-${parseInt(product.qty)}`,
              total: `-${(
                (parseFloat(product.qty) * parseFloat(product.tp)) 
              ).toFixed(2)}`,
            },
          ];
        })
      }
    }

    );
    console.log("product", articleSales);
    setArticleSale(articleSales);
  }, [isSuccess, isFetching, data]);

  const articleHeaders = [
    { label: "Invoice No", key: "invoice_no" },
    { label: "Date", key: "date" },
    { label: "code", key: "code" },
    { label: "name", key: "name" },
    { label: "qty", key: "qty" },
    { label: "Tp", key: "tp" },

    { label: "Mrp", key: "mrp" },
    { label: "Vat", key: "vat" },
    { label: "Total", key: "total" },
  ];

  // let exportData = [];
  useEffect(() => {
    if (articleSale.length > 0) {
      // let expD = articleSale;
      setExportData(articleSale);
    }
  }, [articleSale]);

  const filterByArticleCode = (code) => {
    setQ(code);
    refetch();
  };
  // invoice id, article code, name, qty, mrp, tp, vat, total

  // const handleOnBiller = (e) => {
  //   setBillerSelect(e.target.value);
  // };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PHARMACY-POS-Sale-Export</title>
      </Helmet>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Export Sales"></Header>
            <div className="row">
              <div className="col-md-12">
                {/* Sort date range */}
                <div className="row">
                  <div className="col-md-3">
                    <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                      <DatePicker
                        selected={new Date(startDate)}
                        className="form-control me-2"
                        onChange={(date) =>
                          setStartDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                      <DatePicker
                        selected={new Date(endDate)}
                        className="form-control"
                        onChange={(date) =>
                          setEndDate(format(new Date(date), "MM-dd-yyyy"))
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-3 mt-2 mb-2">
                    <input
                      className="form-control"
                      type="text"
                      name="product"
                      placeholder="article code"
                      onChange={(e) => filterByArticleCode(e.target.value)}
                    />
                  </div>

                  <div className="col-md-3">
                    <CSVLink
                      filename={`Sales Report- ${startDate} to ${endDate}.csv`}
                      className="btn btn-dark float-end my-2 mr-2"
                      data={exportData}
                      asyncOnClick={true}
                      headers={articleHeaders}
                    >
                      {exportData?.length === 0
                        ? "Loading csv..."
                        : "Export Article Sales Report"}
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>

            {/* <Link to="/category-sales" className="btn btn-dark float-end my-2  mx-2">Category Sales </Link> */}
            <Table hover className="mt-4">
              <thead>
                <tr>
                  <th scope="col">Invoice ID</th>
                  <th scope="col">Date</th>
                  <th scope="col">Code</th>
                  <th scope="col">Name</th>
                  <th scope="col">MRP</th>
                  <th scope="col">TP</th>
                  <th scope="col">Qty</th>
                  <th scope="col">VAT</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {console.log(exportData)}
                {exportData ? (
                  exportData.map((product) => (
                    <tr key={product.code}>
                      {/* <th >{i++}</th> */}
                      <th scope="row">{product.invoice_no}</th>
                      <td>{product.date}</td>
                      <td>{product?.code}</td>
                      <td>{product?.name}</td>
                      <td>{product?.mrp}</td>
                      <td>{product?.tp}</td>
                      <td>{product?.qty}</td>
                      <td>{product?.vat}</td>
                      <td>{product?.total}</td>
                      {/* <td>{sale.totalReceived}</td>
                      <td>{sale.status}</td> */}
                      {/* 
                                              <td>{sale.price}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>Loading...</td>
                  </tr>
                )}
              </tbody>
            </Table>

            { }
          </div>
        </div>
      </div>
    </div >
  );
};

export default ExportArticleSale;
