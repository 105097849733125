import React, { useEffect } from "react";
import {
  useAddGroupMutation,
  useGroupQuery,
  useUpdateGroupMutation,
} from "../../../services/groupApi";
import { Button, Modal } from "react-bootstrap";
import * as Icons from "heroicons-react";
import { useForm } from "react-hook-form";
import { apiUniqueErrHandle } from "../../Utility/Utility";
import { useNavigate } from "react-router-dom";
import { notify } from "../../Utility/Notify";

const GroupAddModal = ({ onShow, handleClose, setOnShow }) => {
  let navigate = useNavigate();
  const [addGroup] = useAddGroupMutation();

  const { register, handleSubmit, reset } = useForm({});

  const onSubmit = async (data) => {
    // console.log("data", data)
    // console.log(data);
    try {
      const response = await addGroup(data);
      if (response) {
        // console.log(response);
        if (response?.error) {
          apiUniqueErrHandle(response);
        } else {
          reset({
            name: "",
            details: "",
            symbol: "",
            status: "active",
          });
          //   console.log(response?.data?.message);
          notify("Group Add", "success");
          setOnShow(false);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const handleReset = () => {
    reset({
      name: "",
      details: "",
      symbol: "",
      status: "active",
    });
  };
  return (
    <Modal
      show={onShow}
      onHide={handleClose}
      centered={true}
      size="md"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className="d-flex justify-content-end" closeButton>
        <Modal.Title>Add Group </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputCustomer">Group Name</label>
                <input
                  {...register("name", { required: true })}
                  type="text"
                  className="form-control"
                  id="inputCustomer"
                  aria-describedby="emailHelp"
                  placeholder="Group Name"
                />
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputMC"> Symbol</label>
                <input
                  {...register("symbol")}
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="symbol"
                />
              </div>
              <div className="form-group col-12  mb-3">
                <label htmlFor="inputMC"> Details</label>
                <textarea
                  {...register("details")}
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="details"
                />
              </div>
              <div className="form-group col-12  mb-3">
                <label htmlFor="MCId">Status</label>
                <select
                  {...register("status")}
                  className="form-control"
                  id="address"
                  placeholder="Address"
                >
                  <option value="active">active</option>
                  <option value="inactive">inactive</option>
                </select>
              </div>
            </div>
            <button
              type="reset"
              onClick={handleReset}
              className="btn btn-outline-dark col-4 col-md-4"
            >
              Reset
            </button>
            <button type="submit" className="btn btn-dark col-8 col-md-8">
              <>
                <Icons.Plus> </Icons.Plus>
              </>
              Group
            </button>
          </form>
        </div>
        {/* <PO ref={componentRef} purchase={purchaseView.data} /> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupAddModal;
