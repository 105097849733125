import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import CsvImporterGroup from '../../Common/CsvImporter/CsvImporterGroup';
import { notify } from '../../Utility/Notify';
import SideBar from '../../Common/SideBar/SideBar';
import Header from '../../Common/Header/Header';
import {
    useAddGroupMutation
} from "../../../services/groupApi";

const ImportGroup = () => {
    const [csvData, setCsvData] = useState([]);

    console.log('csvData', csvData)
    const [addGroup] = useAddGroupMutation();
    // const [updatePrice] = useUpdatePriceMutation();

    const handleImportGroup = () => {
        console.log('csvData', csvData)
        csvData.map(item => {
            console.log("create: ", item)
            addGroup(item).then(res => {
                console.log(res)
                notify(`Group ${item.name} Create Successful!`, "success")
            }).catch(err => {
                notify(`Group ${err} Create Successful!`, "success")
            });
        })
        // setCsvData([])
        // console.log(csvData)
    }

    let i = 1;
    return (
        <div>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-2">
                        <SideBar></SideBar>
                    </div>
                    <div className="col-md-10">
                        {/* <Header title="Import Group"></Header> */}
                        <div className="row">
                            <div className="col-md-4 offset-md-4">
                                {/* <div className="d-flex flex-column justify-content-center min-vh-100  align-items-center"> */}
                                <div className="pt-5 pb-5">
                                    <CsvImporterGroup
                                        setCsvData={setCsvData}
                                        handleImportButton={handleImportGroup}
                                    ></CsvImporterGroup>
                                    {/* SPINNER */}
                                    {/* */}

                                    {/* </div> */}

                                </div>
                            </div>
                            <div className="col-md-12">
                                <table class="table table-striped ">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>name</th>
                                            <th>code</th>
                                            <th>status</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        { }
                                        {
                                            csvData ?
                                                csvData.map(item =>
                                                    <tr key={i++}>
                                                        <th>{i}</th>
                                                        <td>{item.name}</td>
                                                        <td>{item.code}</td>
                                                        <td>{item?.status}</td>
                                                    </tr>
                                                ) :
                                                <div className="spinner-border text-danger" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Toaster position="bottom-right" />
                </div>
            </div>
        </div>
    );
};

export default ImportGroup;