// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link {
  color: #333;
}

.nav-link:hover {
  color: #1477BD;
}

.nav-link.active {
  color: #1477BD;
  /* background-color: #eeeeee; */
  border-radius:5px ;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/SideBar/SideBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,+BAA+B;EAC/B,kBAAkB;AACpB","sourcesContent":[".nav-link {\n  color: #333;\n}\n\n.nav-link:hover {\n  color: #1477BD;\n}\n\n.nav-link.active {\n  color: #1477BD;\n  /* background-color: #eeeeee; */\n  border-radius:5px ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
