import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EcomSale } from "../models/ecomSale.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";
console.log(BASE_URL)

export const EcomSaleApi = createApi({
    reducerPath: "EcomSaleApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['EcomSale'],
    endpoints: (builder) => ({
        ecomSales: builder.query<EcomSale[], void>({
            query: () => `/ecom/sale`, 
            providesTags: ['EcomSale']
        }),
        ecomSalesByStatus: builder.query<EcomSale[], string>({
            query: (status) => `/ecom/sale/${status}`, //  not found
            providesTags: ['EcomSale']
        }),
        ecomSalesById: builder.query<EcomSale[], string>({
            query: (id) => `/ecom/sale/details/${id}`,
            providesTags: ['EcomSale']
        }),
        updateEcomSale: builder.mutation<void, EcomSale>({
            query: ({ _id, ...rest }) => ({
                url: `/ecom/sale/${_id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['EcomSale']
        }),
    })
})
export const {
    useEcomSalesQuery,
    useEcomSalesByStatusQuery,
    useEcomSalesByIdQuery,
    useUpdateEcomSaleMutation,
} = EcomSaleApi;

export default EcomSaleApi;