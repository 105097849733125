import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsArchive } from "react-icons/bs";
import { useInventoryByArticleQuery } from "../../../services/inventoryApi";

const SupplierProductDetail = ({
  localStorageAddFromCart,
  cartItem,
  i,
  index,
  selectedIndex,
}) => {
  const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";

  return (
    <tr
      style={index === selectedIndex ? { backgroundColor: "yellow" } : null}
      // ref={index === 0 ? firstProductRef : null}
      key={cartItem?.article_code}
    >
      <th scope="row">{i++}</th>
      <td title={cartItem?.article_code} className="text-break">
        {cartItem?.name}-{cartItem?.group}
      </td>
      <td className="text-center">{cartItem?.stock}</td>
      <td className="text-center">
        <BsArchive onClick={() => localStorageAddFromCart(cartItem)} />
      </td>
    </tr>
  );
};

export default SupplierProductDetail;
