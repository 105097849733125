import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Brand } from "../models/brand.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

console.log(BASE_URL);

export const BrandApi = createApi({
  reducerPath: "BrandApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Brand"],
  endpoints: (builder) => ({
    brands: builder.query<Brand[], void>({
      query: () => "/brand",
      providesTags: ["Brand"],
    }),
    brandsExport: builder.query<Brand[], void>({
      query: () => "/brand/export",
      providesTags: ["Brand"],
    }),
    brandsNew: builder.query<Brand[], void>({
      query: () => "/brand/new",
      providesTags: ["Brand"],
    }),
    brandCount: builder.query<Brand[], void>({
      query: () => "/brand/count",
      providesTags: ["Brand"],
    }),
    brand: builder.query<Brand, string>({
      query: (_id) => `/brand/${_id}`, //  not found
      providesTags: ["Brand"],
    }),
    brandPagination: builder.query<Brand, any>({
      query: ({ page, size, q }) => `/brand/all/${page}/${size}?q=${q}`,
      // query: ({page, size, q}) => `/Customer`,
      providesTags: ["Brand"],
    }),
    addBrand: builder.mutation<{}, Brand>({
      query: (Brand) => ({
        url: "/brand",
        method: "POST",
        body: Brand,
      }),
      invalidatesTags: ["Brand"],
    }),
    updateBrand: builder.mutation<void, Brand>({
      query: ({ _id, ...rest }) => ({
        url: `/brand/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Brand"],
    }),
    deleteBrand: builder.mutation<void, string>({
      query: (id) => ({
        url: `/brand/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Brand"],
    }),
  }),
});

export const {
  useBrandsQuery,
  useBrandsExportQuery,
  useBrandsNewQuery,
  useBrandCountQuery,
  useBrandPaginationQuery,
  useBrandQuery,
  useAddBrandMutation,
  useUpdateBrandMutation,
  useDeleteBrandMutation,
} = BrandApi;

export default BrandApi;
