import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import Header from '../Common/Header/Header';
import LoadingModal from '../Common/Modal/LoadingModal';
import SideBar from '../Common/SideBar/SideBar';
import * as Icons from "heroicons-react";
import DatePicker from "react-datepicker";
import { compareAsc, format } from "date-fns";
import { useNavigate } from 'react-router-dom';
import { useDeleteTpnMutation, useTpnByDateQuery } from '../../services/tpnApi';
import { notify } from '../Utility/Notify';
import TpnView from '../Common/Modal/TpnView';

const Tpn = () => {
    const navigate = useNavigate()
    let i = 1
    const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
    const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
    const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
    const [tpnView, setTpnView] = useState("");
    const [totalTpn, setTotalTpn] = useState(0);

    const [loader, setLoader] = useState(false)
    const handleLoaderClose = () => setLoader(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const { data, isSuccess, refetch } = useTpnByDateQuery({ startDate, endDate });

    useEffect(() => {
        let total = 0;
        data?.map(purchase => {
            total = total + purchase?.total
        })
        setTotalTpn(total)
    }, [data, isSuccess]);

    console.log("data", data)
    const [deleteTpn] = useDeleteTpnMutation()

    const navigateToCreateTPN = () => {
        navigate(`/tpn-out`)
    }
    const navigateToReceiveTpn = () => {
        navigate(`/tpn-received`)
    }
    const deleteHandler = async (id) => {
        try {
            const confirm = window.confirm("Are you Sure? Delete this tpn?");
            if (confirm) {
                setLoader(true)
                const res = await deleteTpn(id);
                if (res) {
                    // TODO::
                    notify("Tpn Deleted Successful!", "success");
                    // add error hendaler for delete error
                    console.log(res);
                } else {
                    console.log("Delete Operation Canceled by Tpn!");
                    return;
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    };
    const tpnDetailsHandler = (id) => {
        setTpnView(id);
        setShow(true);
    }
    return (
        <div>
            <div className="container-fluid">
                <LoadingModal
                    title={"Please Wait"}
                    onShow={loader}
                    handleClose={handleLoaderClose}
                ></LoadingModal>
                <div className="row">
                    <div className="col-md-2">
                        <SideBar></SideBar>
                    </div>
                    <div className="col-md-10">
                        <Header title="TPN"></Header>
                        <div className="row">
                            <div>
                                <div>
                                    <Button
                                        className="btn btn-dark mb-2 float-end mt-2 me-2"
                                        onClick={() => navigateToCreateTPN()}
                                    >
                                        Create Tpn
                                    </Button>
                                </div>
                                {/* <div>
                                    <Button
                                        className="btn btn-dark mb-2 float-end mt-2 me-2"
                                        onClick={() => navigateToReceiveTpn()}
                                    >
                                        Receive Tpn
                                    </Button>
                                </div> */}
                            </div>
                            <div className="col-md-12">

                                <div className="col-md-6">

                                    {/* Sort date range */}
                                    <div className="date-picker d-flex mt-2 mb-2 align-items-center">
                                        {/* <b>Start:</b> */}
                                        <DatePicker
                                            selected={new Date(startDate)}
                                            className="form-control me-2"
                                            onChange={(date) =>
                                                setStartDate(format(new Date(date), "MM-dd-yyyy"))
                                            }
                                        />
                                        <span width="10px"></span>
                                        {/* <b>End:</b> */}
                                        <DatePicker
                                            selected={new Date(endDate)}
                                            className="form-control"
                                            onChange={(date) =>
                                                setEndDate(format(new Date(date), "MM-dd-yyyy"))
                                            }
                                        />
                                    </div>

                                </div>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">TPN ID</th>
                                            <th scope="col">TPN Date</th>
                                            <th scope="col">Warehouse From</th>
                                            <th scope="col">Warehouse To</th>
                                            <th scope="col">Prepared By</th>
                                            <th scope="col">Total items</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Note</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">ActionBtn</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            console.log(data)
                                        }
                                        {data?.length > 0 ? (
                                            data?.map((tpn) => (
                                                <tr key={tpn._id}>
                                                    <th scope="row">{i++}</th>
                                                    <td>{tpn?.tpnNo}</td>
                                                    <td>{tpn?.createdAt &&
                                                        format(new Date(tpn.createdAt), "MM/dd/yyyy")}</td>
                                                    <td>{tpn?.warehouseFrom?.name}</td>
                                                    <td>{tpn?.warehouseTo?.name}</td>
                                                    <td>{tpn?.userId?.name}</td>
                                                    <td>{tpn?.products?.length}</td>
                                                    <td>{tpn?.total.toFixed(2)}</td>
                                                    <td>{tpn?.note}</td>
                                                    <td>{tpn?.status}</td>
                                                    <td>
                                                        <Icons.EyeOutline
                                                            className="icon-eye"
                                                            onClick={() => tpnDetailsHandler(tpn._id)}
                                                            size={20}
                                                        ></Icons.EyeOutline>
                                                        <Icons.TrashOutline
                                                            className="icon-trash"
                                                            onClick={() => deleteHandler(tpn._id)}
                                                            size={20}
                                                        ></Icons.TrashOutline>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (

                                            <tr colSpan={9}>No tpn Found</tr>

                                        )}
                                        {
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>Total :</td>
                                                <td>{(totalTpn).toFixed(2)}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TpnView
                show={show}
                handleClose={handleClose}
                tpn={tpnView}
            ></TpnView>
            <Toaster position="bottom-right" />
        </div >
    );
};

export default Tpn;