import React, { useState, useRef } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import WareHouseDWPurchase from "../Common/CustomSelect/WareHouseDWPurchase";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import {
  selectProducts,
  selectWareHouse,
  selectTotal,
  selectTotalItem,
  selectNote,
  selectUser,
  selectStatus,
  resetDamage,
} from "../../features/damageSlice";
import { useDispatch, useSelector } from "react-redux";
import SearchProduct from "../Common/CustomSelect/SearchProduct";
import * as Icons from "heroicons-react";
import DamageNewProductSelect from "../Common/CustomSelect/DamageNewProductSelect";
import { notify } from "../Utility/Notify";
import DamageProducts from "./DamageProducts";
import DamageProductImpoter from "../Common/CsvImporter/DamageProductImporter";
import axios from "axios";
import LoadingModal from "../Common/Modal/LoadingModal";
import { Toaster } from "react-hot-toast";
import usePurchase from "../Hooks/usePurchase";
import useDamage from "../Hooks/useDamage";
import { useNavigate } from "react-router-dom";
import { apiUniqueErrHandle } from "../Utility/Utility";
import { useAddDamageMutation } from "../../services/damageApi";
import { signInUser } from "../Utility/Auth";
// import updateInventoryOutOnDamageIn from "../Hooks/useInventory";
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";

const DamageCreate = () => {
  let i = 1;
  const dispatch = useDispatch();
  const [wh, setWh] = useState("");
  const [onShow, setOnShow] = useState(false);
  const handleClose = () => setOnShow(false);
  const { register, handleSubmit, isSubmitSuccessful, reset } =
    useForm();
  const damageNewProductsRef = useRef(null);
  const damageData = useSelector((state) => state.damageReducer);
  const [csvData, setCsvData] = useState([]);
  const [damageProducts, setDamageProducts] = useState([]);
  const navigate = useNavigate();
  console.log(damageData);
  const [addDamage] = useAddDamageMutation();

  const { totalItems } = useDamage();
  const { total_item, total, productList } = totalItems(damageProducts);

  const user = signInUser();
  const handleImportButton = () => {
    let importProducts = [];
    let i = 1;
    console.log(csvData);
    setOnShow(true);
    if (csvData?.length > 0) {
      csvData.map(async (pro) => {
        // console.log(pro);
        try {
          const details = await axios(
            `${BASE_URL}/product/pro-details/${pro.article_code}`
          );
          // console.log(details);
          if (details.status === 200) {
            const data = details.data;
            importProducts = [
              ...importProducts,
              {
                id: data?._id,
                article_code: data?.article_code,
                tp: data?.tp > 0 ? data?.tp : 0,
                mrp: data?.mrp > 0 ? data?.mrp : 0,
                name: data?.name,
                qty: pro.qty,
                tax: 0,
                discount: 0,
                order: i++,
              },
            ];
            setDamageProducts(importProducts)
          }
        } catch (err) {
          notify(err, "error");
        } finally {
          console.log(importProducts);
          dispatch(selectProducts(importProducts));
          if (importProducts?.length === csvData?.length) {
            setOnShow(false);
          }
        }
      });
    } else {
      setOnShow(false);
      notify("There is no products to import", "error");
    }
  };

  const handleDamageSubmit = async () => {

    console.log(damageData)
    const newDamage = { ...damageData, total: total }
    console.log(newDamage)
    let newIn = [];

    if (newDamage?.products.length > 0) {


      const confirm = window.confirm("Are you sure? Wanna create Damage?");
      if (confirm) {
        try {
          console.log("Damage Data", newDamage)
          const response = await addDamage(newDamage);
          console.log(response);
          if (response?.data?.message) {

          } else {
            apiUniqueErrHandle(response?.data);
          }
        } catch (err) {
          console.log(err);
        } finally {
          // setPurchaseProduct([]);
          // UPDATE INVENTORY


          notify("Damage Creation update successful!", "success");
          navigate("/damage");
          dispatch(resetDamage());


        }
      }

      // clearTimeout(timeout);
      // console.log("clicked");

      // if (response?.error) {
      // } else {

      //   // const interval = setInterval(async () => {
      //   // console.log("This will run every second!");
      //   // setGrnNote("");
      //   // setIsFull(false);
      // }
    } else {
      notify("There must be products for Damage", "error");
    }
  };

  const handleOnchangeWareHouse = (e) => {

    console.log(e);
    setWh(e.option);
    dispatch(selectWareHouse(e.option));
    dispatch(selectUser(user.id));
  };

  const emptyDamage = () => {
    if (window.confirm("Refresh Cart! Start New Damage?") === true) {
      dispatch(selectProducts([]));
      localStorage.removeItem("damage_cart"); // check by manishankar please
    } else {
      console.log("Refresh Operation Cancelled by User");
    }
  };


  const addQuantities = () => { };


  const handleCustomQty = (qty, id) => {
    // const qty = e.target.value();
    const selected = damageData?.products?.find((p) => p.article_code === id);
    const rest = damageData?.products?.filter((p) => p.article_code !== id);
    console.log("selected", selected);
    console.log("rest", rest);
    console.log("id", id);
    let newProducts = [
      ...rest,
      {
        ...selected,
        qty: qty,
      },
    ];
    setDamageProducts(newProducts);
    dispatch(selectProducts(newProducts));
  };
  const handleCustomReason = (reason, id) => {
    const selected = damageData?.products?.find((p) => p.article_code === id);
    const rest = damageData?.products?.filter((p) => p.article_code !== id);

    let newProducts = [];
    if (rest) {
      newProducts = [
        ...rest,
        {
          ...selected,
          reason: reason,
        },
      ];
    } else {
      newProducts = [...{ ...selected, reason: reason }];
    }

    dispatch(selectProducts(newProducts));
  };

  const removeQuantities = () => { };


  const addToList = async (data) => {
    console.log(data);
    if (data) {
      console.log("add to list:", data);
      // await damageNewProductsRef.current.blur();
      let listData = damageData.products;

      let newProduct = {};
      // unique check
      const idExist = listData.find((pro) => pro.id === data[0]._id);
      if (idExist) {
        notify("Product Already in List", "error");
        // console.log()
      } else {
        notify("Product is Added", "success");
        if (data.length > 0) {
          newProduct = {
            id: data[0]?._id,
            name: data[0]?.name,
            article_code: data[0]?.article_code,
            qty: 1,
            order: listData?.length + 1,
            tp: data[0]?.tp,
            mrp: data[0]?.mrp,
            stock: data[0]?.stock ? data[0]?.stock : 0,
            group: data[0]?.group[0]?.name,
            reason: "",
          };
          listData = [...listData, newProduct];
        } else {
          newProduct = {
            id: data?._id,
            name: data?.name,
            article_code: data?.article_code,
            qty: 1,
            order: listData?.length + 1,
            tp: data?.tp,
            mrp: data?.mrp,
            stock: data?.stock ? data?.stock : 0,
            group: data?.group[0]?.name,
            reason: "",
          };
          listData = [...listData, newProduct];
        }


      }

      // console.log("upcoming damage:", newProduct);
      // console.log("Updated damage:", listData);
      setDamageProducts(listData);
      dispatch(selectProducts(listData));
    } else {
      return false;
    }
  };

  const removeFromCart = (id) => {
    const selected = damageData?.products?.find((p) => p.id === id);
    const rest = damageData?.products?.filter((p) => p.id !== id);

    dispatch(selectProducts(rest));
  };
  const handleOnChangePrice = async (tp, id) => {

    const selected = damageData?.products?.find((p) => p.article_code === id);
    const rest = damageData?.products?.filter((p) => p.article_code !== id);
    console.log("selected", selected);
    console.log("rest", rest);
    console.log("id", id);
    let newProducts = [
      ...rest,
      {
        ...selected,
        tp: tp,
      },
    ];
    setDamageProducts(newProducts);
    dispatch(selectProducts(newProducts));

  };
  // console.log("damage create page");
  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10 mt-2">
            <Header title="Create Damage"></Header>
            <Form className="pt-3">
              <div className="row">
                <div className="col-8">
                  <Form.Group className="" controlId="warehouse">
                    <Form.Label>Warehouse</Form.Label>
                    <WareHouseDWPurchase
                      // {...setValue("warehouse", `${wh}`)}
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouse}
                      wh={wh !== "" ? wh : 0}
                    // {...register("warehouse")}
                    />
                  </Form.Group>
                </div>
                <div className="col-4">
                  <Form.Group className="">
                    <Form.Label>Damage Status</Form.Label>
                    <Form.Select
                      onChange={(e) => dispatch(selectStatus(e.target.value))}
                      disabled
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-6 my-3">
                  <Form.Label>Damage Products</Form.Label>
                  <DamageNewProductSelect
                    className="searchProduct"
                    // getValue={getValue}
                    addToList={addToList}
                    damageNewProductsRef={damageNewProductsRef}
                  ></DamageNewProductSelect>
                </div>
                <div className="col-6 my-3 ">
                  <DamageProductImpoter
                    setCsvData={setCsvData}
                    handleImportButton={handleImportButton}
                    title=""
                  />
                </div>
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">Damaged Products List</div>
                    <div className="card-body">
                      <Table className="mt-3 ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Group</th>
                            <th>Stock</th>

                            <th>Quantity</th>
                            <th>TP</th>
                            {/* <th>Tax</th> */}
                            <th>Total</th>
                            <th>Reason</th>
                          </tr>
                        </thead>

                        <tbody>
                          <DamageProducts
                            damageProducts={damageData?.products}
                            i={i}
                            // removeQuantities={removeQuantities}
                            // addQuantities={addQuantities}
                            handleOnChangePrice={handleOnChangePrice}
                            handleCustomReason={handleCustomReason}
                            handleCustomQty={handleCustomQty}
                            removeFromCart={removeFromCart}
                          ></DamageProducts>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 my-2">
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label>Note</Form.Label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Note"
                      onChange={(e) => dispatch(selectNote(e.target.value))}
                    />
                  </Form.Group>
                </div>
              </div>
              <Table className="bordered striped ">
                <thead>
                  <tr>
                    <th>Total Items:{total_item?.toFixed(2)}</th>
                    <th>Total Cost: {total?.toFixed(2)}</th>
                  </tr>
                </thead>
              </Table>
              <Button
                variant="dark"
                onClick={handleDamageSubmit}
                className="float-end my-2"
                type="button"
              >
                <Icons.SaveOutline size={20} /> Submit
              </Button>
              <Button
                variant="dark"
                className="float-end my-2 mx-2"
                type="button"
                onClick={emptyDamage}
              >
                Reset Cart
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <LoadingModal
        onShow={onShow}
        title="Please Wait.."
        handleClose={handleClose}
      />
      <Toaster position="bottom-right" />
    </div>
  );
};

export default DamageCreate;
