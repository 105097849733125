import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import AsyncSelect from "react-select/async";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api";
// AVLOSEF

const SelectSupplier = ({ sp, supplierProductsRef, handleOnchange }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [cancelTokenSource, setCancelTokenSource] = useState(
    axios.CancelToken.source()
  );
  const selectRef = useRef(null);

  const fetchData = async (inputValue, callback) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Canceling the previous request");
    }

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    try {
      const result = await axios.get(
        `${BASE_URL}/supplier/search/po/${inputValue}`,
        {
          cancelToken: newCancelTokenSource.token,
        }
      );

      // console.log("search Supplier", result);

      let tempArray = [];
      if (result.data.length > 0) {
        result.data.forEach((element) => {
          tempArray.push({
            label: `${element.company}`,
            option: element._id,
          });
        });
      } else {
        tempArray.push({
          label: `Please Search Supplier Company`,
          option: `please select`,
        });
      }

      // console.log("tempArray:", tempArray);
      callback(tempArray);
    } catch (error) {
      if (!axios.isCancel(error)) {
        // handle error here
      }
    }
  };

  const fetchSupplierById = async (id) => {
    try {
      const result = await axios.get(`${BASE_URL}/supplier/po/${id}`);
      setSelectedOption({
        label: `${result.data.company}`,
        option: result.data._id,
      });
    } catch (error) {
      // handle error here
    }
  };

  useEffect(() => {
    if (sp) {
      fetchSupplierById(sp);
    }

    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Canceling on unmount");
      }
    };
  }, [sp]);

  const onSearchChange = (option) => {
    // console.log("Selected", option.e);
    setSelectedOption(option);
    handleOnchange(option);
  };

  return (
    <AsyncSelect
      value={selectedOption}
      loadOptions={fetchData}
      placeholder="Supplier Search"
      onChange={onSearchChange}
      defaultOptions={true}
      classNamePrefix="react-select"
      innerRef={selectRef}
      ref={supplierProductsRef}
    />
  );
};

export default SelectSupplier;
