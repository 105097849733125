import React from "react";
import { IoFastFoodOutline } from "react-icons/io5";

const CategoryList = () => {
  return (
    <div className="row">
      <div className="col-12">
        <small>
          <b>Category List</b>
          <ul className="m-0 p-0">
            <li>Pasta</li>
            <li>Pizza</li>
            <li>Pastry</li>
            <li>Salad</li>
            <li>Sub Sandwich</li>
            <li>Sandwich</li>
            <li>Fish fry</li>
            <li>Fries</li>
            <li>Lassi</li>
            <li>Cup Fresh Food</li>
            <li>Cup Fresh Fruits</li>
            <li>Fruits Juice</li>
            <li>Cold Drinks</li>
            <li>Hot Drinks</li>
          </ul>
        </small>
      </div>
    </div>
  );
};

export default CategoryList;
