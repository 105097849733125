import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Supplier } from "../models/supplier.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

console.log(BASE_URL);

export const SupplierApi = createApi({
  reducerPath: "SupplierApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Supplier"],
  endpoints: (builder) => ({
    Suppliers: builder.query<Supplier[], void>({
      query: () => "/supplier",
      providesTags: ["Supplier"],
    }),
    SupplierExport: builder.query<Supplier[], void>({
      query: () => "/supplier/export",
      providesTags: ["Supplier"],
    }),
    countSupplier: builder.query<Supplier[], void>({
      query: () => "/supplier/count",
      providesTags: ["Supplier"],
    }),
    supplierLedger: builder.query<Supplier, any>({
      query: (_id) => `/supplier/ledger/${_id}`,
      providesTags: ["Supplier"],
    }),
    Supplier: builder.query<Supplier, string>({
      query: (_id) => `/supplier/${_id}`,
      providesTags: ["Supplier"],
    }),
    SupplierTest: builder.query<Supplier, string>({
      query: (_id) => `/supplier/test/${_id}`,
      providesTags: ["Supplier"],
    }),
    SupplierByProduct: builder.query<Supplier, string>({
      query: (code) => `/supplier/product/${code}`,
      providesTags: ["Supplier"],
    }),

    addSupplier: builder.mutation<{}, Supplier>({
      query: (supplier) => ({
        url: "/supplier",
        method: "POST",
        body: supplier,
      }),
      invalidatesTags: ["Supplier"],
    }),
    updateSupplier: builder.mutation<{}, Supplier>({
      query: ({ _id, ...rest }) => ({
        url: `/supplier/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Supplier"],
    }),

    supplierPagenation: builder.query<Supplier, any>({
      query: ({ page, size, q }) => `/supplier/${page}/${size}?q=${q}`,
      // query: ({page, size, q}) => `/Customer`,
      providesTags: ["Supplier"],
    }),

    deleteSupplier: builder.mutation<void, string>({
      query: (id) => ({
        url: `/supplier/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Supplier"],
    }),
  }),
});

export const {
  useSuppliersQuery,
  useSupplierQuery,
  useSupplierTestQuery,
  useSupplierExportQuery,
  useSupplierLedgerQuery,
  useSupplierByProductQuery,
  useSupplierPagenationQuery,
  useCountSupplierQuery,
  useAddSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation,
} = SupplierApi;

export default SupplierApi;
