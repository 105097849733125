import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "../Common/Header/Header";
import * as Icons from "heroicons-react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import SideBar from "../Common/SideBar/SideBar";
import CsvImporter from "../Common/CsvImporter/CsvImporter";
import {
  // useBrandsQuery,
  // useBrandQuery,
  useAddBrandMutation,
  // useUpdateBrandMutation,
  useDeleteBrandMutation,
  useBrandPaginationQuery,
  useBrandCountQuery,
} from "../../services/brandApi";
import { useNavigate } from "react-router-dom";
import { apiUniqueErrHandle } from "../Utility/Utility";
import LoadingModal from "../Common/Modal/LoadingModal";
import { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";

const Brand = () => {
  const [addBrand] = useAddBrandMutation();

  const { register, handleSubmit, reset } = useForm({});
  const [deleteBrand] = useDeleteBrandMutation();
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [q, setQ] = useState("");

  const { data, isSuccess, isLoading, refetch } = useBrandPaginationQuery({
    page,
    size,
    q,
  });
  let navigate = useNavigate();
  let i = 1;

  const [loader, setLoader] = useState(false);
  const handleLoaderClose = () => setLoader(false);
  useEffect(() => {
    data ? setLoader(false) : setLoader(true);
  }, [data, isSuccess]);

  const pageCountQuery = useBrandCountQuery();
  useEffect(() => {
    const { data } = pageCountQuery;
    setPageCount(data);
  }, [pageCountQuery]);

  const handleDataLimit = (e) => {
    setSize(parseInt(e.target.value));
    setPageNo(getPageNumber);
    refetch();
  };

  const getPageNumber = () => {
    const cont = Math.ceil(parseInt(pageCount) / parseInt(size));
  };

  const handleSearch = (e) => {
    setQ(e.target.value);
    refetch();
  };
  const handlePageClick = (data) => {
    setPage(parseInt(data.selected));
    setPageNo(getPageNumber);
    refetch();
  };

  const onSubmit = async (data) => {
    setLoader(true);
    // console.log(data);
    try {
      const response = await addBrand(data);
      if (response) {
        // console.log(response);
        if (response?.error) {
          apiUniqueErrHandle(response);
        } else {
          reset({
            name: "",
            code: "",
            photo: "",
            details: "",
            status: "",
          });
          // console.log(response?.data?.message);
          return navigate("/brand");
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
      refetch();
    }
  };

  const deleteHandler = async (id) => {
    const confirm = window.confirm("Are you Sure? Delete this Brand?");
    if (confirm) {
      const res = await deleteBrand(id);
      if (res) {
        // TODO::
        // add error hendaler for delete error
        console.log(res);
      } else {
        console.log("Delete Operation Canceled by Brand!");
        return;
      }
    }
  };

  const handleReset = () => {
    reset({
      name: "",
      code: "",
      photo: "",
      details: "",
      status: "active",
    });
  };
  return (
    <div>
      <div className="container-fluid">
        <LoadingModal
          title={"Please Wait"}
          onShow={loader}
          handleClose={handleLoaderClose}
        ></LoadingModal>
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Brand"></Header>
            <div className="row">
              <div className="col-md-12 ">
                <span className="float-end">
                  <Link
                    className="btn btn-dark mb-2 float-end me-2 mt-2"
                    to="/brand/import"
                  >
                    <Icons.PlusOutline size={22}></Icons.PlusOutline> Import
                    Brand
                  </Link>
                </span>
              </div>
              <div className="col-md-4 ">
                <div className="sticky-md-top ">
                  <div className="card mt-2">
                    <div className="card-header">
                      <h5 className="card-title">
                        {data?._id ? "Update" : "Add"} Brand
                      </h5>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputCustomer">Brand Name</label>
                            <input
                              {...register("name", { required: true })}
                              type="text"
                              className="form-control"
                              id="inputCustomer"
                              aria-describedby="emailHelp"
                              placeholder="Unit Name"
                            />
                          </div>
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputCustomer">Brand Code</label>
                            <input
                              {...register("code", { required: true })}
                              type="text"
                              className="form-control"
                              id="inputCustomer"
                              aria-describedby="emailHelp"
                              placeholder="Brand Code"
                            />
                          </div>
                          {/* <div className="form-group col-12  mb-3">
                            <label htmlFor="inputMC"> Company</label>
                            <input
                              {...register("company")}
                              type="text"
                              className="form-control"
                              id="phone"
                              placeholder="company"
                            />
                          </div> */}
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="inputMC"> Details</label>
                            <textarea
                              {...register("details")}
                              type="text"
                              className="form-control"
                              id="phone"
                              placeholder="details"
                            />
                          </div>
                          <div className="form-group col-12  mb-3">
                            <label htmlFor="MCId">status</label>
                            <select
                              {...register("status")}
                              className="form-control"
                              id="address"
                              placeholder="Address"
                            >
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                            </select>
                          </div>
                        </div>
                        <button
                          type="reset"
                          onClick={handleReset}
                          className="btn btn-outline-dark col-4 col-md-4"
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-dark col-8 col-md-8"
                        >
                          {data?._id ? (
                            <>
                              <Icons.SaveOutline></Icons.SaveOutline>
                            </>
                          ) : (
                            <>
                              <Icons.Plus> </Icons.Plus>
                            </>
                          )}
                          Brand
                        </button>
                      </form>
                    </div>
                  </div>
                  {/* <CsvImporter setCsvData={setCsvData} handleImportButton={handleImportButton} title="Customer"></CsvImporter> */}
                </div>
              </div>
              <div className="col-md-8">
                <form>
                  <div className="input-group mb-3 mt-5">
                    <select
                      className="form-select"
                      onChange={(e) => handleDataLimit(e)}
                    >
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                      <option value="250">250</option>
                    </select>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="search"
                      onKeyUp={(e) => handleSearch(e)}
                    />
                    {/* <input type="text" className="form-control" aria-label="Text input with dropdown button"> */}
                  </div>
                </form>
                <nav aria-label="Page navigation example">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    //dynamic page count
                    // page count total product / size
                    pageCount={Math.ceil(parseInt(pageCount) / parseInt(size))}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={6}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pt-0 pb-2"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                  ></ReactPaginate>
                </nav>

                <Table hover>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Code</th>
                      <th scope="col">Brand Name</th>
                      <th scope="col">Details</th>
                      <th scope="col">status</th>
                      <th scope="col">ActionBtn</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data ? (
                      data
                        .slice()
                        .sort((a, b) => a.code - b.code)
                        .map((Brand) => (
                          <tr key={Brand._id}>
                            <th scope="row">{i++}</th>
                            <td>{Brand.code}</td>
                            <td>{Brand.name}</td>
                            <td>{Brand.details}</td>
                            <td>{Brand.status}</td>
                            <td>
                              <Link to={`/brand/update/${Brand._id}`}>
                                <Icons.PencilAltOutline
                                  className="icon-edit"
                                  size={20}
                                ></Icons.PencilAltOutline>
                              </Link>

                              <Icons.TrashOutline
                                className="icon-trash"
                                onClick={() => deleteHandler(Brand._id)}
                                size={20}
                              ></Icons.TrashOutline>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan={4}>1</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default Brand;
