import React, { useEffect, useState } from "react";
// import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Icons from "heroicons-react";
import Header from "../Common/Header/Header";
import SideBar from "../Common/SideBar/SideBar";
import { signInUser } from "../Utility/Auth";
import { CSVLink, CSVDownload } from "react-csv";
// import ExportSales from "..x/Common/Modal/ExportSales";
// import ExportArticleSales from "../Common/Modal/ExportArticleSales";
// import useInventory from "../Hooks/useInventory";
// import axios from "axios";
import {
  // useSaleByDateQuery,
  useDeleteTempSaleMutation,
  // useSaleByDateInvoiceQuery,
  useSaleExportByDateQuery,
} from "../../services/saleApi";
import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import { MaterialReactTable } from "material-react-table";
import { Box, Button, MenuItem } from "@mui/material";
import LoadingModal from "../Common/Modal/LoadingModal";

const Sales = () => {
  // const [startDate, setStartDate] = useState(new Date().toISOString());
  // const [endDate, setEndDate] = useState(new Date().toISOString());
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));

  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useSaleExportByDateQuery({
      startDate,
      endDate,
    });

  const user = signInUser();
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportCSV, setExportCSV] = useState([]);

  const [deleteSale] = useDeleteTempSaleMutation();

  useEffect(() => {
    isFetching ? setLoading(true) : setLoading(false);
  }, [isLoading, isFetching, isSuccess]);

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  useEffect(() => {
    if (data?.length > 0) {
      setSales(data);
      setExportCSV(data);
    }
  }, [isLoading, isFetching, isSuccess, data]);

  console.log(data, sales);
  // console.log("dates", startDate, endDate);

  const handelDeleteSale = async (id) => {
    // let newIn = [];
    // console.log(id);

    const confirm = window.confirm("Are you Sure? Delete this Sale?");
    const data = {
      _id: id,
      updateUser: user.id,
      status: "delete",
    };
    if (confirm) {
      console.log(deleteSale(data));
      // // // UPDATE INVENTORY
      // const inventory = await updateInventoryInOnSaleDel(newIn);
      // console.log(inventory)
    }
  };

  const columns = [
    { accessorKey: "invoiceId", header: "Invoice No" },
    { header: "Date", accessorKey: "date", size: 30 },
    { header: "Biller", accessorKey: "biller", size: 40 },
    { header: "Customer", accessorKey: "customer", size: 40 },
    { header: "Item No", accessorKey: "totalItem", size: 30 },
    { header: "Return Items", accessorKey: "returnItem", size: 30 },
    { header: "Total MRP", accessorKey: "mrpTotal", size: 30 },
    { header: "Return MRP", accessorKey: "returnMrp", size: 30 },
    { header: "Earning Discount", accessorKey: "earningDiscount", size: 30 },
    { header: "Return Total", accessorKey: "returnTotal", size: 30 },
    { header: "Gross Sales", accessorKey: "grossSale", size: 30 },
    { header: "Discount", accessorKey: "discount", size: 30 },
    { header: "Net Sales", accessorKey: "netSale", size: 30 },
    { header: "NetSale Round", accessorKey: "netSaleRound", size: 30 },
    { header: "Point Used", accessorKey: "pointUsed", size: 30 },
    { header: "Total Receivable", accessorKey: "totalReceivable", size: 30 },
    { header: "Total Received", accessorKey: "totalReceived", size: 30 },
    { header: "Change Amount", accessorKey: "changeAmount", size: 30 },
    { header: "Total Tp", accessorKey: "tpTotal", size: 30 },
    { header: "Return TP", accessorKey: "returnTp", size: 30 },
    { header: "Cash", accessorKey: "cash", size: 30 },
    { header: "Card", accessorKey: "card", size: 30 },
    { header: "MFS", accessorKey: "mfs", size: 30 },
    { header: "Status", accessorKey: "status", size: 30 },
  ];

  const headers = [
    { label: "Invoice No", key: "invoiceId" },
    { label: "Date", key: "date" },
    { label: "Biller", key: "biller" },
    { label: "Customer", key: "customer" },
    { label: "Item No", key: "totalItem" },
    { label: "Return Items", key: "returnItem" },
    { label: "Total MRP", key: "mrpTotal" },
    { label: "Return MRP", key: "returnMrp" },
    { label: "Earning Discount", key: "earningDiscount" },
    { label: "Return Total", key: "returnTotal" },
    { label: "Gross Sales", key: "grossSale" },
    { label: "Discount", key: "discount" },
    { label: "Net Sales", key: "netSale" },
    { label: "NetSale Round", key: "netSaleRound" },
    { label: "Point Used", key: "pointUsed" },
    { label: "Total Reciveable", key: "totalReceivable" },
    { label: "Total Recieved", key: "totalReceived" },
    { label: "Change Amount", key: "changeAmount" },
    { label: "Total Tp", key: "tpTotal" },
    { label: "Return TP", key: "returnTp" },
    { label: "Cash", key: "cash" },
    { label: "Card", key: "card" },
    { label: "MFS", key: "mfs" },
    { label: "Status", key: "status" },
  ];

  const materialReactTableContainer = {
    overflow: "visible",
    position: "relative", // If needed
  };
  return (
    <div>
      {/* <LoadingModal
        title={"Please Wait"}
        onShow={loading}
        // handleClose={handleLoaderClose}
      ></LoadingModal> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>PHARMACY-POS-SALE</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="All Sales"></Header>

            <div>
              <MaterialReactTable
                className={materialReactTableContainer}
                columns={columns}
                data={sales}
                state={{
                  isLoading: loading,
                }}
                // enableGrouping //Aggration
                initialState={{
                  density: "compact",
                  pagination: {
                    pageIndex: 0,
                    pageSize: 100, // Rows per page
                  },
                }}
                enableStickyHeader //Enable Sticky Header
                enableRowNumbers //Enable Row Numbers
                enableRowActions //Enable Row Actions
                positionActionsColumn="last" // Add action Column to the end
                renderRowActions={({ row }) => (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      gap: "0.5rem",
                    }}
                  >
                    {/* {console.log(row.original)} */}
                    <Link to={`/print/${row.original._id}`} target="_blank">
                      <Icons.EyeOutline
                        className="icon-eye"
                        size={22}
                      ></Icons.EyeOutline>
                    </Link>
                    <Icons.TrashOutline
                      onClick={() => handelDeleteSale(row.original._id)}
                      className="icon-trash"
                      size={22}
                    ></Icons.TrashOutline>
                  </div>
                )}
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      // p: "0.5rem",
                      flexWrap: "wrap",
                      zIndex: "999",
                    }}
                  >
                    <div className="date-picker row gap-1">
                      <div class="col">
                        {/* <b>Start:</b> */}
                        <input
                          type="date"
                          className="form-control"
                          // value={selectedDate}
                          value={startDate}
                          // onChange={handleDateChange}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setStartDate(e.target.value);
                          }}
                        />
                      </div>
                      <div class="col">
                        <input
                          type="date"
                          className="form-control"
                          // value={selectedDate}
                          value={endDate}
                          // onChange={handleDateChange}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                      <div class="col">
                        <CSVLink
                          className="btn btn-dark btn-block"
                          data={exportCSV}
                          asyncOnClick={true}
                          headers={headers}
                          filename={`Pharmacy Sales from [${startDate} to ${endDate}].csv`}
                        >
                          {exportCSV?.length === 0
                            ? `LoadingCsv...`
                            : "ExportSales"}
                        </CSVLink>
                      </div>
                      <div class="col">
                        <Link
                          to="/articleSaleExport"
                          className="btn btn-dark  btn-block"
                          // onClick={() => setShow(true)}
                        >
                          ArticleReport
                        </Link>
                      </div>
                      <div class="col">
                        <Link
                          to="/categorySaleExport"
                          className="btn btn-dark  btn-block"
                          // onClick={() => setShow(true)}
                        >
                          CategoryReport
                        </Link>
                      </div>
                    </div>
                  </Box>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
