import React from "react";
import { Container, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "./Header.css";
import * as Icons from "heroicons-react";
import { IoFastFoodOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../logo.png";
// import { handleLogout } from "../../Utility/Auth";
import userEvent from "@testing-library/user-event";

const Header = ({ title }) => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  // const user = JSON.parse(localStorage.getItem("user"));
  // console.log("user", user)

  const accessToken = localStorage.getItem("accessTokens");
  const navigate = useNavigate();
  // handleLogOut
  const handleLogout = (e) => {
    e.preventDefault();

    localStorage.clear();
    navigate("/login", { replace: true });
  };
  const handleUserProfile = (id) => {
    navigate(`/user/${id}`);
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#" className="logo me-5">
          <Link className="nav-link" to="/">
            {title ? title : <Image src={logo} height="50" />}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          {/* {loggedInUser?.type !== "ecom" ? (
            <Nav>
              <Link className="nav-link" to="/pos">
                <Icons.CalculatorOutline size={22}></Icons.CalculatorOutline>{" "}
              </Link>
            </Nav>
          ) : (
            ""
          )} */}

          <Nav>
            <Link className="nav-link" to="/pos">
              <Icons.CalculatorOutline size={22}></Icons.CalculatorOutline>
            </Link>
          </Nav>
          {loggedInUser?.type === "admin" ? (
            <Nav>
              <Link className="nav-link" to="/dashboard">
                <Icons.AdjustmentsOutline size={22}></Icons.AdjustmentsOutline>{" "}
              </Link>
            </Nav>
          ) : (
            ""
          )}
          {loggedInUser?.type === "manager" ? (
            <Nav>
              <Link className="nav-link" to="/dashboard">
                <Icons.AdjustmentsOutline size={22}></Icons.AdjustmentsOutline>{" "}
              </Link>
            </Nav>
          ) : (
            ""
          )}
          {loggedInUser?.type === "supervisor" ? (
            <Nav>
              <Link className="nav-link" to="/dashboard">
                <Icons.AdjustmentsOutline size={22}></Icons.AdjustmentsOutline>{" "}
              </Link>
            </Nav>
          ) : (
            ""
          )}
          {loggedInUser?.type === "ecom" ? (
            <Nav>
              <Link className="nav-link" to="/ecom">
                <Icons.AdjustmentsOutline size={22}></Icons.AdjustmentsOutline>{" "}
              </Link>
            </Nav>
          ) : (
            ""
          )}
          {accessToken ? (
            <NavDropdown
              title={loggedInUser?.username}
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  handleUserProfile(loggedInUser.id);
                }}
              >
                {" "}
                <Icons.UserOutline className="h-3 w-3" /> Profile
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout} href="/">
                {" "}
                <Icons.LockClosedOutline className="h-3 w-3" />
                Sign Out
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Nav>
              <Link className="nav-link" to="/login">
                <Icons.LoginOutline size={22}></Icons.LoginOutline> Login
              </Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
