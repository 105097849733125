import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Inventory } from "../models/inventory.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

console.log(BASE_URL);

export const InventoryApi = createApi({
  reducerPath: "InventoryApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Inventory"],
  endpoints: (builder) => ({
    Inventories: builder.query<Inventory[], any>({
      query: ({ pageNo, size, q }) => `/inventory/all/${pageNo}/${size}?q=${q}`,
      providesTags: ["Inventory"],
    }),
    InventoryExport: builder.query<Inventory[], any>({
      query: () => `/inventory/export`,
      providesTags: ["Inventory"],
    }),
    InventoriesAll: builder.query<Inventory[], any>({
      query: () => `/inventory`,
      providesTags: ["Inventory"],
    }),
    InventoriesCount: builder.query<Inventory[], any>({
      query: () => `/inventory/count`,
      providesTags: ["Inventory"],
    }),
    Inventory: builder.query<Inventory, string>({
      query: (_id) => `/inventory/${_id}`,
      providesTags: ["Inventory"],
    }),
    inventoryByArticle: builder.query<Inventory, string>({
      query: (article_code) => `/inventory/article_code/${article_code}`,
      providesTags: ["Inventory"],
    }),
    inventoryCount: builder.query<any, void>({
      query: () => "/inventory/count",
      providesTags: ["Inventory"],
    }),
    masterInventory: builder.query<Inventory[], void>({
      query: (_id) => `/inventory/master`, //  not found
      providesTags: ["Inventory"],
    }),
    addInventory: builder.mutation<{}, Inventory>({
      query: (Inventory) => ({
        url: "/inventory",
        method: "POST",
        body: Inventory,
      }),
      invalidatesTags: ["Inventory"],
    }),
    addInventoryPrice: builder.mutation<{}, Inventory>({
      query: (Inventory) => ({
        url: "/inventory/price", //  not found
        method: "POST",
        body: Inventory,
      }),
      invalidatesTags: ["Inventory"],
    }),
    updateInventory: builder.mutation<void, any>({
      query: ({ _id, ...rest }) => ({
        url: `/inventory/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Inventory"],
    }),
    adjustInventory: builder.mutation<void, any>({
      query: (Inventory) => ({
        url: `/inventory/adjust`,
        method: "PUT",
        body: Inventory,
      }),
      invalidatesTags: ["Inventory"],
    }),
    deleteInventory: builder.mutation<void, string>({
      query: (id) => ({
        url: `/inventory/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Inventory"],
    }),
  }),
});

export const {
  useInventoriesQuery,
  useInventoryExportQuery,
  useInventoriesAllQuery,
  useInventoriesCountQuery,
  useInventoryByArticleQuery,
  useInventoryCountQuery,
  useInventoryQuery,
  useMasterInventoryQuery,
  useAddInventoryMutation,
  useAddInventoryPriceMutation,
  useUpdateInventoryMutation,
  useAdjustInventoryMutation,
  useDeleteInventoryMutation,
} = InventoryApi;

export default InventoryApi;
