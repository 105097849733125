// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td svg {
    vertical-align: middle;
    cursor: pointer;
}

td svg.icon-trash:hover {
    fill: #1477BD;
}

td svg.icon-eye:hover {
    fill: greenyellow;
}

td svg.icon-edit:hover {
    fill: gold;
}

td a svg.icon-edit,
td a svg.icon-eye {
    color: #212529;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/CSS/Table.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;;IAEI,cAAc;AAClB","sourcesContent":["td svg {\n    vertical-align: middle;\n    cursor: pointer;\n}\n\ntd svg.icon-trash:hover {\n    fill: #1477BD;\n}\n\ntd svg.icon-eye:hover {\n    fill: greenyellow;\n}\n\ntd svg.icon-edit:hover {\n    fill: gold;\n}\n\ntd a svg.icon-edit,\ntd a svg.icon-eye {\n    color: #212529;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
